<template>
  <header class="header">
    <!-- Search -->
    <!-- <div class="header__search" v-if="params.search"> -->
    <!-- <img src="@/assets/img/icons/search-icon.svg" alt="Search icon" />
    <input type="search" placeholder="Search" />-->
    <!-- </div> -->
    <!--end Search -->
    <!-- Project info -->
    <div class="flex__sc header__project" v-if="$_project">
      <div class="mr__20">
        <img :src="$_projectAvatar" alt="Project avatar" />
      </div>
      <div class="header__project__text" :disabled="$_project.ReadOnly">
        <Input
          class="title__16 weight__700"
          :params="{label: '', target: 'currentProject.Name', placeholder: 'Project name'}"
        />
        <Input
          class="title__12 grey__210 mt__10"
          :params="{label: '', target: 'currentProject.Description', placeholder: 'description'}"
        />
      </div>
    </div>
    <!--end Project info -->
    <div class="flex__bc">
      <!-- Project links -->
      <div class="header__project__navigation mt__20__m" v-if="$_project">
        <router-link
          :to="'/project/'+ $route.params.projectid +'/set-up-project'"
          class="header__project__navigation title__16 weight__700 blue"
        >
          <div class="header__project__navigation__icon" v-if="!mobile">
            <IconSetup />
          </div>
          <div class="header__project__navigation__title">
            Set Up 
            <br />Project
          </div>
        </router-link>
        <template
          v-if="($_neuroQuestion && $_neuroQuestion.EyeTrackingWebSiteUrl && !$_defaultCollector) || ($_defaultCollector && $_defaultCollector.type !== 24) || ($_defaultCollector && $_defaultCollector.type == 24 && $_defaultCollector.status == 0)"
        >
          <div class="header__project__navigation__break" v-if="mobile">|</div>
          <router-link
            :to="'/project/'+ $_project.Id + $_collectorLink"
            class="header__project__navigation title__16 weight__700 green"
          >
            <div class="header__project__navigation__icon" v-if="!mobile">
              <IconTesters />
            </div>
            <div class="header__project__navigation__title">
              <span v-if="!mobile">Invite</span>
              <br />Testers
            </div>
          </router-link>
        </template>
        <template
          v-if="($_defaultCollector && $_defaultCollector.completes > 0) || ($_defaultCollector && $_defaultCollector.type == 24 && $_defaultCollector.status != 0)"
        >
          <div class="header__project__navigation__break" v-if="mobile">|</div>
          <router-link
            :to="'/project/'+ $route.params.projectid +'/collector/'+$_defaultCollector.Id+'/analyze-results'"
            class="header__project__navigation title__16 weight__700 orange"
          >
            <div class="header__project__navigation__icon" v-if="!mobile">
              <IconAnalyze />
            </div>
            <div class="header__project__navigation__title">
              Analyze
              <br />Results
            </div>
          </router-link>
        </template>
      </div>
      <!--end Project links -->
      <!-- Avatar -->
      <!-- <router-link v-if="!mobile && projectPage" class="header__right ml__40" to="/account">
        <div class="header__avatar" v-if="params.avatar && user">
          <img :src="user.User.Avatar.PreviewUrl" alt="avatar" />
        </div>
      </router-link>-->
      <!--end Avatar -->
    </div>
  </header>
</template>

<script>
import Bus from "../../modules/eventBus";

import CurrentProjectInfo from "../../mixins/CurrentProjectInfo";

export default {
  mixins: [CurrentProjectInfo],
  data() {
    return {
      mobile: this.ismobile,
      mobileMenu: false,
      testersLink: null
    };
  },
  props: {
    params: {
      default: function() {
        return {
          search: false,
          projectNav: false
        };
      }
    }
  },
  created() {
    this.setTestLink();
    Bus.$on("collector_created", () => {
      this.setTestLink();
    });
  },
  computed: {
    user() {
      return this.$store.state.userInfo;
    }
  },
  methods: {
    setTestLink() {
      if (this.$store.state.currentCollector) {
        if (this.$store.state.currentCollector.type == 24) {
          this.testersLink = "/invite-testers/remote";
        } else {
          this.testersLink = "/invite-testers/own";
        }
      } else {
        this.testersLink = "/invite-testers";
      }
    }
  }
};
</script>

<style lang="scss">
.header {
  background: $color-white;
  margin-bottom: 40px;
  padding: $padding-main;
  border-radius: 0 0 $border-radius $border-radius;
  @include for-mobile-up {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &__search {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    img {
      width: 24px;
      height: 24px;
      margin-right: 16px;
    }
    input::placeholder {
      font-size: 16px;
      font-weight: 500;
    }
  }
  &__project {
    &__text {
      @include for-mobile-only {
        max-width: calc(100% - 64px);
      }
      @media (min-width: 1000px) and (max-width: 1265px) {
        width: 230px;
        max-width: 230px;
      }
      @media (min-width: 1266px) {
        width: 350px;
        max-width: 350px;
      }
      .input__row:first-child {
        margin-top: -20px;
        input {
          padding: 0 0 1px 0;
          font-size: 16px;
          font-weight: 700;
        }
      }
      .input__row:last-child {
        margin-top: 0;
        height: 14px;
        input {
          margin-top: 10px;
          padding: 0 0 1px 0;
          color: $color-grey-210;
          font-size: 12px;
        }
      }
      .input__row input:not(:hover) {
        border-bottom-color: transparent;
      }
      .input__row input:focus {
        border-bottom-color: $color-grey-210;
      } 
      .input__row input::placeholder{
        color: $color-grey-210;
      }
    }
    img {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      object-fit: cover;
    }
    &__navigation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &__break {
        margin: 0 8px;
      }
      & > a {
        font-weight: 500;
        // text-shadow: 1px 1px 1px transparent;
        @include for-mobile-only {
          font-size: 12px;
          &:not(.router-link-active) {
            color: $color-mid-grey;
          }
          br {
            display: none;
          }
        }
        @include for-mobile-up {
          &:not(:first-child) {
            margin-left: 70px;
          }
          .header__project__navigation__icon {
            width: 32px;
            height: 32px;
            padding: 6px;
            border-radius: 50%;
            margin-right: 12px;
            @include transitionAll;
          }
          &.router-link-exact-active {
            path {
              fill: $color-white;
            }
            &.blue {
              // text-shadow: 1px 1px 1px $color-teal-blue;
              .header__project__navigation__icon {
                background: $color-teal-blue;
              }
            }
            &.green {
              // text-shadow: 1px 1px 1px $color-green;
              .header__project__navigation__icon {
                background: $color-green;
              }
            }
            &.orange {
              // text-shadow: 1px 1px 1px $color-orange;
              .header__project__navigation__icon {
                background: $color-orange;
              }
            }
          }
        }
      }
      .blue {
        color: $color-teal-blue;
        .header__project__navigation__icon {
          border: 1px solid $color-teal-blue;
        }
      }
      .green {
        color: $color-green;
        .header__project__navigation__icon {
          border: 1px solid $color-green;
        }
      }
      .orange {
        color: $color-orange;
        .header__project__navigation__icon {
          border: 1px solid $color-orange;
        }
      }
    }
  }
  // &__avatar {
  //   width: 40px;
  //   height: 40px;
  //   border-radius: 50%;
  //   overflow: hidden;
  // }
}
</style>