var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.subscription || _vm.appCall)?_c('div',{staticClass:"payment"},[(!_vm.successPay)?_c('div',{staticClass:"block flex__subscription mt__20"},[_c('div',{staticClass:"flex__sc"},[_vm._m(0),_c('Subscription')],1),(_vm.subscription.DowngradeRequested)?_c('div',{staticClass:"title__14 weight__500 grey__151 lh__20 flex__subscription__starter"},[_vm._v(" You'll be switched to "),_c('br'),_c('b',[_vm._v("'PAY AS YOU GO' ")]),_vm._v("plan ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"flex__bc mob__rev"},[_c('h1',{staticClass:"title__line__green",class:_vm.mobile ? 'mt__20' : 'mt__40'},[_vm._v(" Available subscriptions ")]),(!_vm.successPay)?_c('router-link',{staticClass:"btn__grey btn__120",class:_vm.mobile ? 'mt__40' : 'mt__10',attrs:{"to":"/account"}},[_vm._v("Back")]):_vm._e()],1),_c('div',{staticClass:"block subscription"},[(!_vm.successPay)?[_c('div',{staticClass:"title__24 weight__700 mt__20 text__center"},[_vm._v(" Subscription ")]),_c('div',{staticClass:"title__16 mt__10 text__center"},[_vm._v(" Choose the type of subscription that suits your needs. ")])]:[_c('div',{staticClass:"title__24 weight__700 mt__20 text__center"},[_vm._v(" Your subscription plan updated ")]),_c('div',{staticClass:"title__16 mt__10 text__center"},[_vm._v(" Your payment is processing. "),(_vm.promo.local)?[_vm._v(" Now you can fully use our platform with all its functions with "),_c('b',[_vm._v("1 extra month for FREE.")])]:_vm._e()],2)],_c('div',{class:['block__3', 'mt__100 center'],staticStyle:{"max-width":"860px"}},[(
          !_vm.successPay ||
          (_vm.successPay && _vm.subscription && _vm.subscription.Name == 'Pay as you go')
        )?_c('div',{class:_vm.subscription && _vm.subscription.Name == 'Pay as you go'
            ? 'block'
            : 'block__hover',on:{"click":function($event){_vm.popup = _vm.subscription.Name == 'Pay as you go' ? false : true}}},[_c('div',{staticClass:"image__cover"},[_c('img',{attrs:{"src":require("@/assets/img/payment/starter.jpg"),"alt":"Pay as you go"}}),(_vm.subscription && _vm.subscription.Name == 'Pay as you go')?_c('IconCurrentSubscription'):_vm._e()],1),_c('h2',{staticClass:"title__up17 mt__20 text__center"},[_vm._v(" PAY AS YOU GO "),_c('br'),(!_vm.successPay)?_c('span',[_vm._v("($0/month)")]):_c('span',[_vm._v("(Current)")])]),_vm._m(1)]):_vm._e(),(
          !_vm.successPay ||
          (_vm.successPay && _vm.subscription && _vm.subscription.Name == 'Enterprise')
        )?_c('router-link',{staticClass:"block__hover",attrs:{"to":"/contact"}},[_c('div',{staticClass:"image__cover"},[_c('img',{attrs:{"src":require("@/assets/img/payment/enterprise.jpg"),"alt":"enterprise"}}),(_vm.subscription && _vm.subscription.Name == 'Enterprise')?_c('IconCurrentSubscription'):_vm._e()],1),_c('h2',{staticClass:"title__up17 mt__20 text__center"},[_vm._v(" Enterprise "),_c('br'),(_vm.subscription && _vm.subscription.Name == 'Enterprise')?_c('span',[_vm._v("(Current)")]):_c('span',[_vm._v("(On demand)")])]),_c('div',{staticClass:"title__12 weight__500 grey__151 mt__20"},[_c('ul',[_c('li',[_vm._v("All PRO package features")]),_c('li',[_vm._v("Custom recordings time")]),_c('li',[_vm._v("Team access")]),_c('li',[_vm._v("Shareable projects")])])])]):_vm._e()],1),(!_vm.successPay)?_c('div',{staticClass:"title__12 text__center mt__40"},[_c('b',[_vm._v("*Price per project for built-in audience:")]),_vm._m(2)]):_vm._e()],2),(_vm.popup)?_c('Popup',{attrs:{"type":_vm.popupType,"close":() => {
        _vm.popup = false;
      }}}):_vm._e()],1):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("@/assets/img/subscription.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title__12 weight__500 grey__151 mt__20"},[_c('ul',[_c('li',[_vm._v("Unlimited projects")]),_c('li',[_vm._v("Built-in audience only*")]),_c('li',[_vm._v("Built-in methodologies only")]),_c('li',[_vm._v("Unmoderated tests only")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt__10"},[_vm._v(" 10 respondents = $400 (delivery up to 24 hours) "),_c('span',{staticClass:"mr__10 ml__11"},[_vm._v("|")]),_vm._v(" 20 respondents = $800 (delivery up to 48 hours) "),_c('span',{staticClass:"mr__10 ml__11"},[_vm._v("|")]),_vm._v(" 50 respondents = $1500 (delivery up to 48 hours) ")])
}]

export { render, staticRenderFns }