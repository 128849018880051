<template>
  <div class="flex__bs">
    <div class="flex__bs mr__40" style="width:320px;flex-wrap:wrap;">
      <!-- DIAGRAMM FLOWER  -->
      <ChartFlower :scenarios="scenarios" :selected="selected" @selectedChart="selectedChart" />

      <!-- end DIAGRAMM FLOWER  -->
      <!-- CUBES UNDER DIAGRAMM  -->
      <div
        :class="['cubes flex__cc text__center', {'active': index == selected }, {'mt__40': index == 0 || index == 1}]"
        :style="index == selected ? ('background: ' + scenario.Color) : ''"
        v-for="(scenario, index) in scenarios"
        :key="index"
        @click="selected = index"
      >
        <div
          class="title__24 weight__500 text__up"
          :style="index !== selected ? ('color: ' + scenario.Color) : ''"
        >{{scenario.Percentage}}%</div>
        <div>&mdash;</div>
        <div class="title__18 weight__500">{{scenario.Name}}</div>
      </div>
      <!-- end CUBES UNDER DIAGRAMM  -->
    </div>
    <div style="width: calc(100% - 320px - 40px);">
      <!-- LEGEND OWN  -->
      <div class="legend successful">
        <div class="legend__title green">successful scenarios</div>

        <div
          :class="['block flex__bs', {'legend__open' : index == selected}, {'mt__20': index !== 0}]"
          style="flex-wrap:wrap;"
          v-for="(scenario, index) in scenarios"
          :key="index"
          @click="selected = index"
        >
          <!-- ----- -->
          <div class="title__20 weight__500">{{scenario.Name}}</div>
          <!-- ----- -->
          <div class="flex__ec">
            <div class="legend__icon flex__sc mr__40">
              <img class="mr__10" src="@/assets/img/icons/testers-icon.svg" alt="icon" />
              <div>
                <div class="title__20 weight__500">
                  <b>{{scenario.testers}}</b>
                </div>
                <div class="title__8 grey__151">testers</div>
              </div>
            </div>
            <div class="legend__icon flex__sc mr__40">
              <img class="mr__10" src="@/assets/img/icons/timer-icon.svg" alt="icon" />
              <div>
                <div class="title__20 weight__500">
                  <b>{{scenario.timeSpent}}</b>
                </div>
                <div class="title__8 grey__151">time spent</div>
              </div>
            </div>
            <div class="legend__icon flex__sc">
              <IconClicks class="mr__10" />
              <div>
                <div class="title__20 weight__500">
                  <b>{{scenario.misclicks}}</b>
                </div>
                <div class="title__8 grey__151">misclicks</div>
              </div>
            </div>
          </div>
          <!-- ----- -->
          <ChartLineArea v-if="index == selected" :scenarios="scenarios" class="mt__10" />
          <!-- ----- -->
          <div class="mt__20" v-if="index == selected">
            <template v-if="scenario.Advantages">
              <div class="flex__sc">
                <img class="img__creterea" src="@/assets/img/icons/plus-green.svg" alt />
                <div class="green title__16">
                  <b>Advantages</b>
                </div>
              </div>
              <div v-for="(adw, ind) in scenario.Advantages" :key="ind + 'adw'" class="ml__40">
                <div v-for="(good, index) in adw" :key="index + 'good'" v-html="`- ${good}`"></div>
              </div>
            </template>
            <template v-if="scenario.Flaws">
              <div class="flex__sc mt__20">
                <img class="img__creterea" src="@/assets/img/icons/minus-red.svg" alt />
                <div class="red title__16">
                  <b>Flaws</b>
                </div>
              </div>
              <div v-for="(flaw, ind) in scenario.Flaws" :key="ind + 'flaw'" class="ml__40">
                <div v-for="(bad, index) in flaw" :key="index + 'bad'" v-html="`- ${bad}`"></div>
              </div>
            </template>
          </div>
          <!-- ----- -->
        </div>
      </div>
      <!-- end LEGEND OWN -->
      <!-- LEGEND USER -->
      <div class="legend unsuccessful mt__30">
        <div class="legend__title red">unsuccessful scenarios</div>
      </div>
      <!-- end LEGEND USER  -->
    </div>
  </div>
</template>

<script>
export default {
  props: ["scenarios"],
  data() {
    return {
      selected: 0
    };
  },
  methods: {
    selectedChart(index) {
      this.selected = index;
    }
  }
};
</script>

<style lang="scss" scoped>
.cubes {
  width: 120px;
  height: 120px;
  border-radius: 4px;
  flex-wrap: wrap;
  flex-direction: column;
  @include transitionAll;
  &:not(.active) {
    cursor: pointer;
  }
  &.active {
    color: $color-white;
  }
}
.legend {
  position: relative;
  padding: 25px 20px;
  border: 1px solid $color-grey-210;
  border-radius: $border-radius-sm;
  &.successful {
    border-color: $color-green;
  }
  &.unsuccessful {
    border-color: $color-red;
  }
  &__title {
    position: absolute;
    top: -8px;
    left: calc(65px - 17px);
    font-size: 12px;
    font-weight: bold;
    padding: 0 17px;
    // color: $color-grey-210;
    background: $color-white;
  }
  &__icon img,
  &__icon svg {
    width: 32px;
    height: 32px;
  }
  .img__creterea {
    width: 20px;
    height: 20px;
    margin-right: 15px;
  }
  .block:not(.legend__open) {
    cursor: pointer;
  }
}
</style>