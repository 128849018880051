import FD from "../modules/FData";
import Net from "../modules/net";
import router from '../router';
import store from '../store';
import bus from '../modules/eventBus';

class ProjectInfo {
    currentProjectId;
    route;
    constructor(){
        this.clk = setInterval(() => {
            if(this.route == 'collector-view' || this.route == 'analyze-results' ){
                Net.post("/GetLastDataUpdate.cmd", JSON.stringify({
                    projectId: this.currentProjectId
                })).then(e => {
                    if(store.state.currentProject && e.data.LastDataUpdate > store.state.currentProject.LastDataUpdate){
                        bus.$emit('currentProjectUpdated');
                        if(this.route == 'collector-view'){
                            store.dispatch("loadProjectById", this.currentProjectId);                                
                        }
                    }
                }).catch(e => { debugger })
            } else if(false){

            }
        }, 10000)
    }
    __initCurrentProject(route){
        this.route = route.name;
        if(route.params.projectid && (this.currentProjectId != route.params.projectid || !store.state.currentProject)){
            this.currentProjectId = route.params.projectid;
            this.__load()
        }
    }

    __load(){
        store.dispatch("setCurrentProjectById", this.currentProjectId);
        store.dispatch("setCurrentQuestById", this.currentProjectId);
    }
    __loadRecordings(){

    }
}

const projectInfo = new ProjectInfo;

export default {
    created() {
        projectInfo.__initCurrentProject(this.$route);
    },
    watch: {
        $route() {
            projectInfo.__initCurrentProject(this.$route);
        }
    },
    methods: {

        $_startCollector() {
            this.$store.commit("loaderAction", true);
            Net.post(
                "/ChangeCollectorStatusCommand.cmd",
                FD({
                collector: this.$_collector.Id,
                status: 1
                })
            )
                .then(e => {
                if (e.data.res == 1) {
                    this.$store.commit("update", {
                    target: this.$store.state.currentCollector,
                    field: "status",
                    value: 1
                    });
                }
                this.$store.commit("loaderAction", false);
                })
                .catch(error => {
                debugger;
                });
            },
        $_deleteCollector() {
            this.$store.commit("loaderAction", true);
            Net.post(
                "/DeleteCollector.cmd",
                JSON.stringify({
                    collectorId: this.$store.state.currentCollector.Id
                }),
                {
                    "Content-Type": "application/json"
                }
            ) 
                .then(e => {
                    if (e.data.res == 1) {
                        this.$store.commit('update', { target: this.$_project, field: 'Collectors', value: [] });
                        router.push({
                            path: '/project/'+ this.$_project.Id + '/invite-testers/'
                        });
                    }
                    this.$store.commit("loaderAction", false);
                })
                .catch(error => {
                    debugger;
                });
            }
    },
    computed: {
        $__projectRoute() {
            return !!this.$route.params.projectid;
        },
        $_project() {
            return this.$__projectRoute ? this.$store.state.currentProject || null : null;
        },
        $_quest() {
            return this.$__projectRoute ? this.$store.state.currentQuest || null : null;
        },
        $_projectAvatar() {
            return this.$_project ? this.$_project.AvatarData.PreviewUrl : '';
        },
        $_neuroQuestion() {
            try {
                return this.$_quest && this.$_quest.filter((e) => {
                    return [24, 30, 31].indexOf(e.QuestItem.QuestionType) != -1;
                })[0].QuestItem
            } catch (e) {
                throw new Error('There is no neuro-question in current project!')
            }
        },

        $_collectors() {
            return this.$_project ? this.$_project.Collectors : null
        },
        $_collector() {
            return this.$_project ? this.$_project.Collectors.filter(e => {
                return e.Id == this.$route.params.collectorid
            })[0] : null
        },
        $_defaultCollector() {
            return this.$_project ? this.$_project.Collectors[0] : null
        },
        $_collectorLink() {
                if (this.$_collectors.length) {
                    return '/collector/' + this.$_collectors[0].Id
                } else {
                    return '/invite-testers/';
                }
        },
        $_trial() {
            return this.$store.state.userInfo && this.$store.state.userInfo.Subscription.Name == "Pro Trial"
        }

    }
}

