<template>
  <div class="block mt__40">
    <div class="title__24 weight__700 mt__20 text__center">Project setup</div>
    <div
      class="title__16 text__center mt__10"
    >Which device is the {{questionInfo && questionInfo.title}} designed for?</div>
    <div :class="['mt__80', _chosenProjectType == 30 ? 'block__2': 'block__3']">
      <div class="block__hover" @click="$_setProjectTargetDevice(1);">
        <div class="image__cover">
          <img src="../../assets/img/new-project/android.jpg" alt="android" />
        </div>
        <h2 class="title__up17 mt__20 text__center">android</h2>
        <div
          class="title__12 weight__500 grey__151 mt__20 text__center"
        >Use Android devices (smartphones and tablets) with version 7.0 and upper with an aspect ratio from 16:9 to 21:9 in the vertical and horizontal orientation of the screen.</div>
      </div>
      <!-- ios -->
      <div
        :class="[_chosenProjectType == 31 ? 'block' : 'block']"
        @click="/*$_setProjectTargetDevice(2);*/"
      >
        <div class="image__cover">
          <img src="../../assets/img/new-project/ios.jpg" alt="ios" />
          <div class="coming__soon">
            <span class="title__up17">Coming soon</span>
          </div>
        </div>
        <h2 class="title__up17 mt__20 text__center">ios</h2>
        <div
          class="title__12 weight__500 grey__151 mt__20 text__center"
        >Use Apple devices (smartphones from 4.7 inches, tablets from 8 inches) with iOS version 10 and upper in vertical and horizontal screen orientation.</div>
      </div>
      <!-- desktop -->
      <div
        v-if="_chosenProjectType !== 30"
        class="block__hover"
        @click="$_setProjectTargetDevice(3)"
      >
        <!-- @click="() => {if(_chosenProjectType !== 31) {this.$_setProjectTargetDevice(3)}}" -->
        <div class="image__cover">
          <img src="../../assets/img/new-project/desktop.jpg" alt="desktop" />
          <!-- <div class="coming__soon" v-if="_chosenProjectType == 31">
            <span class="title__up17">Coming soon</span>
          </div> -->
        </div>
        <h2 class="title__up17 mt__20 text__center">desktop<span class="beta">beta</span></h2>
        <div
          class="title__12 weight__500 grey__151 mt__20 text__center"
        >Run testing via Chrome browser extension on your desktop devices with Windows, Linux, and MacOS operating systems. Any device resolution is supported.</div>
      </div>
    </div>
    <div class="text__center mt__40">
      <router-link to="/project-setup/step-1" class="btn__grey__full btn__200">Back</router-link>
    </div>
  </div>
</template> 

<script>
import config from "@/components/Questions/questionTypes.json";
import CreateProject from "@/mixins/CreateProject";

export default {
  mixins: [CreateProject],
  computed: {
    questionInfo() {
      return config[this._chosenProjectType];
    }
  }
};
</script>

<style scoped>
.beta{
  color: #00bceb;
  font-weight: bold;
  font-size: 10px;
  position: absolute;
  margin-top: -10px;
  margin-left: 3px;
}
</style>