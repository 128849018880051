import Vue from 'vue';
import Vuex from 'vuex';
import router from '../router';
import Net from "@/modules/net";
import FD from "@/modules/FData";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        projects: [],
        projectsMap: {},
        currentProject: null,
        allProjectsLoaded: false,
        authState: false,
        userInfo: null,
        loader: false,
        miniLoader: false,
        currentQuestItem: null,
        currentQuest: null,
        currentQuestId: null,
        questionsMap: {},
        currentCollector: null
    },
    mutations: {
        //Loader
        loaderAction(state, loader) {
            state.loader = loader
        },
        setAuthState(state, authState) {
            if (authState == false) {
                state.projects = [];
                state.projectsMap = {};
            }
            state.authState = authState;
        },
        //User info
        setUserInfo(state, data) {
            state.userInfo = data;
        },
        //Payments
        addCreditCard(state, data) {
            state.userInfo.Account.Cards = [data];
            // state.userInfo.Account.Cards.push(data);
        },


        //Projects
        allProjectsLoaded(state) {
            state.allProjectsLoaded = true;
        },
        addProjects(state, data) {
            for (var i = 0; i < data.length; i++) {
                state.projects.push(data[i]);
                state.projectsMap[data[i].Id] = data[i];
            }
        },
        addProject(state, data) {
            state.projects.unshift(data);
            state.projectsMap[data.Id] = data;
        },
        deleteProjectById(state, id) {
            state.projects.splice(state.projects.indexOf(state.projectsMap[id]), 1);
        },
        setCurrentProject(state, data) {
            state.currentProject = data;
            state.currentCollector = state.currentProject.Collectors[0]
        },
        setCurrentProjectById(state, data) {
            state.currentProject = state.projectsMap[data];
            state.currentCollector = state.currentProject.Collectors[0]
        },
        addCollector(state, data) {
            state.currentProject.Collectors.push(data);
            state.currentCollector = data;
        },

        //Questions
        setCurrentQuest(state, data) {
            state.currentQuest = data.Items.filter(e => { return e.QuestItem.ItemType == 0 });
            state.currentQuestId = data.Quest.Id;
            state.currentQuestItem = state.currentQuest[0];
        },
        setCurrentQuestItem(state, data) {
            state.currentQuestItem = data;
        },
        addQuestItem(state, data) {
            var index = state.currentQuest.indexOf(state.currentQuest.filter((e) => {
                return e.QuestItem.Id == data.after;
            })[0]);
            var currentQuest = state.currentQuest;
            state.currentQuest = null;
            currentQuest.splice(index + 1, 0, data.item);
            state.currentQuest = currentQuest;
        },
        deleteQuestItem(state, data) {
            var index = state.currentQuest.indexOf(state.currentQuest.filter((e) => {
                return e.QuestItem.Id == data;
            })[0]);
            state.currentQuest.splice(index, 1);
            if (state.currentQuest[index]) {
                state.currentQuestItem = state.currentQuest[index];
            } else if (state.currentQuest[index - 1]) {
                state.currentQuestItem = state.currentQuest[index - 1];
            }
        },


        //Alternatives
        addQuestItemAlternative(state, data) {
            state.currentQuestItem.Alternatives.push(data.alternative);
        },
        deleteQuestItemAlternative(state, data) {
            var index = state.currentQuestItem.Alternatives.indexOf(state.currentQuestItem.Alternatives.filter((e) => {
                return e.Id == data;
            })[0]);
            state.currentQuestItem.Alternatives.splice(index, 1);
        },
        reorderQuestions(state, data) {
            state.currentQuest.splice(
                data.index,
                0,
                state.currentQuest
                .splice(state.currentQuest.indexOf(data.item), 1)
                .pop()
            );
        },
        update(state, data) {
            if (data.target) {
                data.target[data.field] = data.value;
            }
        },
        cleanup(state) {
            state.currentProject = null;
            state.currentQuestItem = null;
            state.currentQuest = null;
            state.currentCollector = null;
        }
    },
    actions: {
        loadProjects({ commit, state }, firstLoad) {

            if (firstLoad === true) {
                commit('loaderAction', true);
            }

            var count = firstLoad === true ? 19 : 20;
            Net.post("/GetApiProjects.cmd", FD({
                count: count,
                after: state.projects.length ? state.projects[state.projects.length - 1].Id : undefined,
                order: 3
            })).then(response => {
                commit('loaderAction', false);
                if (response.data.length < count) {
                    commit('allProjectsLoaded');
                };
                if (firstLoad && firstLoad.constructor == Function) firstLoad();
                commit('addProjects', response.data);
            }).catch(e => {
                commit('loaderAction', false);
            })
        },
        loadProjectById({ commit, dispatch }, id) {
            Net.post("/GetProjectData.cmd", FD({ projectId: id })).then(response => {
                if (response.data && response.data.error) {
                    router.push({ path: "404" });
                } else {
                    commit("setCurrentProject", response.data);
                }
            });
        },
        setAuthState({ commit, dispatch }, state) {
            commit("setAuthState", state);
            if (state) {
                dispatch("loadUserData")
            }
        },
        loadUserData({ commit }) {
            Net.get("/GetUserProfile.cmd").then(e => {
                commit("setUserInfo", e.data)
            })
        },
        deleteProjectById({ commit }, id) {
            commit("deleteProjectById", id)
        },
        getProjectById({ state }, object) {
            object.callback({
                project: state.projectsMap[object.id]
            })
        },
        setCurrentProjectById({ commit, dispatch, state }, id) {
            if (!state.projectsMap[id]) {
                dispatch("loadProjectById", id);
            } else {
                commit("setCurrentProjectById", id);
            }
        },
        setCurrentQuestById({ commit, dispatch, state }, id) {
            Net.get("/GetApiQuest.cmd?projectId=" + id).then(response => {
                commit("setCurrentQuest", response.data);
            });
        }
    },
    modules: {}
})