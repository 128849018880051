<template>
  <div
    :class="['card__plastic', card[0] ? 'card__plastic__black flex__ec' : 'card__plastic__empty']"
    v-if="card"
  >
    <template v-if="card[0]">
      <div class="card__plastic__number">
        <b>••••<span></span>••••<span></span>••••<span></span>{{card[0].cardinfo}}</b>
      </div>
      <div class="flex__bc mt__10">
        <div class="card__plastic__date">
          <div>
            EXP:
            <b>{{card[0].exp}}</b>
          </div>
        </div>
        <img :src="require(`@/assets/img/card/${card[0].cardtype}.png`)" class="card__plastic__img" />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  computed: {
    card() {
      return (
        this.$store.state.userInfo && this.$store.state.userInfo.Account.Cards
      );
    }
  }
};
</script>


<style lang="scss">
.card__plastic {
  width: 340px;
  height: 220px;
  color: $color-white;
  border-radius: $border-radius;
  padding: 20px;
  &__empty {
    background: $color-grey-229;
  }
  &__black {
    background: $color-black;
    flex-direction: column;
    & > .flex__bc {
      width: 100%;
    }
  }
  &__number {
    width: 100%;
    white-space: nowrap;
    font-size: 22px;
    letter-spacing: 5px;
    margin-bottom: 7%;
    span {
        margin: 0 13px;
    }
  }
  &__date {
    font-size: 80%;
  }
  &__img {
    width: 17%;
  }
}
.card__mini {
  width: 160px;
  height: 100px;
  padding: 10px;
  .card__plastic__number {
    font-size: 16px;
    letter-spacing: 1px;
    span {
        margin: 0 3px;
    }
  }
}
</style>