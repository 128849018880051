import env from "./env";
class StripeHelper {
    constructor() {
        Stripe.setPublishableKey(env.STRIPE_KEY);
    }

    validateCard(data) {
        return new Promise(function (resolve, reject) {
            Stripe.card.createToken(
                data,
                (status, response) => {
                    resolve(response)
                }
            );
        })
    }
}

export default new StripeHelper;