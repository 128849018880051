<template>
  <div>
    <div class="title__line__green mt__20">Contact us</div>

    <div class="block block__minh text__center" v-if="user">
      <div class="title__24 weight__700 mt__20">Contact Our Team</div>
      <div class="title__16 text__center mt__10">
        Have a question or need a tip? We are here to assist you.
        <br />Just drop us a line and we'll answer you as quickly as possible.
      </div>
      <!--Form -->
      <ValidationObserver v-slot="{ invalid, validated, passes }">
        <form
          id="contact_in_form"
          class="contact__form block__maxw center mt__100"
          @submit.prevent="passes(contactSubmit)"
        >
          <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
            <div class="input__row" :class="{'error': errors[0], 'valid': !errors[0]}">
              <input
                v-model.lazy="user.Name"
                type="text"
                :class="{ 'focused': user.Name}"
                id="contact-name"
              />
              <label for="contact-name">Name{{ errors[0] }}</label>
            </div>
          </ValidationProvider>
          <ValidationProvider
            name="Email"
            rules="required|email"
            v-slot="{ errors }"
            ref="userNotFound"
          >
            <div class="input__row" :class="{'error': errors[0], 'valid': !errors[0]}">
              <input
                v-model.lazy="user.Email"
                type="email"
                class="focused"
                :class="{ 'focused': user.Email}"
                id="Email"
              />
              <label for="email">Email{{ errors[0] }}</label>
            </div>
          </ValidationProvider>
          <ValidationProvider name="Message" rules="required" v-slot="{ errors }">
            <div class="input__row" :class="{'error': errors[0], 'valid': !errors[0]}">
              <!-- TODO: change height another file -->

              <textarea
                id="contact-message"
                ref="textarea"
                autofocus="true"
                v-model="message"
                rows="1"
                type="text"
                :class="{ 'focused': message && message.length}"
                :style="inputStyle"
                @blur="resize"
              />
              <label for="contact-message">Message{{ errors[0] }}</label>
            </div>
          </ValidationProvider>
          <div class="flex__cc mt__60">
            <div class="btn__grey btn__200 mr__40" @click="$router.go(-1)">Back</div>
            <button class="btn__green__full btn__200">Send Message</button>
          </div>
        </form>
      </ValidationObserver>
      <!--end Form -->
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import FD from "@/modules/FData";
import Net from "@/modules/net";

export default {
  data: () => ({
    message: "",
    inputHeight: "26px"
  }),
  components: {
    ValidationObserver,
    ValidationProvider
  },
  computed: {
    user() {
      return this.$store.state.userInfo && this.$store.state.userInfo.User;
    },
    inputStyle() {
      return {
        "min-height": this.inputHeight
      };
    }
  },
  mounted() {
    if (this.$refs.textarea) this.resize();
  },
  watch: {
    message() {
      if (this.$refs.textarea) this.resize();
    }
  },
  methods: {
    resize() {
      this.inputHeight = 0;
      this.$nextTick(() => {
        this.inputHeight = `${this.$refs.textarea.scrollHeight + 2}px`;
      });
    },
    contactSubmit() {
      this.$store.commit("loaderAction", true);
      Net.post(
        "/ContactUsCommand.cmd",
        FD(
          {
            name: this.user.Name,
            email: this.user.Email,
            text: this.message,
            to: 1
          },
          true
        ),
        {
          "Content-type": "application/x-www-form-urlencoded"
        }
      )
        .then(res => {
          this.$router.go(-1);
          this.$store.commit("loaderAction", false);
        })
        .catch(error => {
          console.log(error.response);
        });
    }
  }
};
</script>