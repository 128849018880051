<template>
  <div class="block mt__100vh text__center">
    <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes }" class="sign center">
      <div class="sign__title">Reset Password</div>
      <div class="title__20 weight__500 grey__151">Enter your new password.</div>
      <form class="sign__form block__maxw center" @submit.prevent="passes(resetSubmit)">
        <ValidationProvider
          name="confirmation"
          rules="required"
          v-slot="{ errors, invalid, validated }"
        >
          <div class="input__row" :class="{'error': errors[0], 'valid': validated}">
            <input
              v-model="confirmation"
              :type="passwordFieldType"
              :class="{ 'focused': confirmation.length}"
              id="password"
            />
            <label for="password">New password {{ errors[0] }}</label>

            <IconLocked class="input__icon" />
          </div>
        </ValidationProvider>
        <ValidationProvider
          name="confirmed"
          rules="required|confirmed:confirmation"
          v-slot="{ errors, invalid, validated }"
        >
          <div class="input__row" :class="{'error': errors[0], 'valid': validated && !invalid}">
            <input
              v-model.lazy="password"
              :type="passwordFieldType"
              :class="{ 'focused': password.length}"
              id="confirm"
            />
            <label for="confirm">Password confirmation {{ errors[0] }}</label>
            <IconLocked class="input__icon" />
          </div>
        </ValidationProvider>
        <CheckRadio
          :change="switchType"
          :params="{checks: [{name: 'Show characters', value: 0}], type: 'checkbox'}"
        />
        <div class="mt__40">
          <button class="btn__blue__full btn__320">Reset</button>
        </div>
        <div class="mt__20">
          <router-link to="/signin" class="link__black">Cancel</router-link>
        </div>
      </form>
    </ValidationObserver>
    <div class="mt__60"></div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import FD from "../../modules/FData";
import Net from "../../modules/net";

export default {
  data: () => ({
    password: "",
    confirmation: "",
    passwordFieldType: "password"
  }),
  components: {
    ValidationObserver,
    ValidationProvider
  },
  methods: {
    switchType() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    resetSubmit() {
      this.$store.commit("loaderAction", true);
      Net.logOut();
      Net.post(
        "/ChangePassword.cmd",
        FD({
          hash: this.$route.query.hash,
          password: this.password
        })
      )
        .then(res => {
          this.$router.push({ path: "signin" });
          this.$store.commit("loaderAction", false);
        })
        .catch(() => {});
    }
  }
};
</script>