<template>
  <table class="progress__table">
    <tr v-for="(array, index) in arrays" :key="index">
      <td class="title__14 weight__500 grey__151 lh__20 text__overflow" style="max-width:300px;">{{array.Name}}</td>
      <td>
        <div class="progress__lines">
          <div
            class="progress__lines__value"
            :style="`width: ${Math.max(0.5, array.Percentage * grow)}%; background: ${array.Color}`"
          ></div>
        </div>
      </td>
      <td class="title__14 weight__500 grey__151 lh__20">{{array.Percentage}}%</td>
    </tr>
  </table>
</template>

<script>
export default {
  props: ["arrays"],
  data() {
    return {
      grow: 1
    }
  },
  created() { 
    var arr = [...this.arrays];
    this.grow = 100 / arr.sort((a, b) => {
      return b.Percentage - a.Percentage;
        })[0].Percentage;
  }
};
</script>

<style lang="scss" scoped>
.progress__table {
  td {
    padding: 2px 0;
    vertical-align: text-top;
    &:nth-child(1) {
      width: 1px;
      white-space: nowrap;
      padding-right: 20px;
    }
    &:nth-child(2) {
      width: calc(100% - 36px);
      padding-right: 20px;
    }
    &:nth-child(3) {
      width: 1px;
      white-space: nowrap;
      text-align: right;
    }
  }
}
.progress__lines {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: $border-radius;
  width: 100%;
  height: 7px;
  background: #fafafa;
  // background: $color-light-grey;
  &__value {
    border-radius: inherit;
    height: inherit;
    box-shadow: 0 0 40px -10px #fff;
  }
}
</style>