<template>
  <aside class="aside" :class="navAsideFull && !mobile ? 'aside__full' : 'aside__cut'">
    <div class="aside__wrap">
      <router-link class="logo" to="/projects">
        <img
          v-if="navAsideFull && !mobile"
          src="@/assets/img/logo_reality.svg"
          alt="Logo UXReality"
        />
        <img src="@/assets/img/logo.svg" alt="Logo UXReality" v-else />
      </router-link>
      <nav class="aside__nav">
        <div>
          <!-- <router-link class="aside__nav__link" to="/" title="Dashboard">
          <IconDashboard />
          <span v-if="navAsideFull && !mobile">Dashboard</span>
          </router-link>-->
          <router-link class="aside__nav__link" to="/projects" title="Projects">
            <IconProjects />
            <span v-if="navAsideFull && !mobile">Projects</span>
          </router-link>
          <router-link
            class="aside__nav__link"
            to="/payments"
            title="Payments"
          >
          <!--  router :class="{'aside__nav__sublink':paymentLink}" -->
            <IconPaymentHistory />
            <span v-if="navAsideFull && !mobile">Payments</span>
          </router-link>
          <!-- <router-link
            v-if="paymentLink"
            class="aside__nav__link aside__nav__sublink"
            to="/subscription"
            title="Payments"
          >
            <IconSubscription />
            <span v-if="navAsideFull && !mobile">Subscription</span>
          </router-link>
          <router-link
            v-if="paymentLink"
            class="aside__nav__link aside__nav__sublink"
            to="/payment-methods"
            title="Payments"
          >
            <IconPayments />
            <span v-if="navAsideFull && !mobile">Payment Methods</span>
          </router-link> -->
          <!--  <router-link class="aside__nav__link" to="/notifications" title="Notifications">
          <IconNotifications />
          <span v-if="navAsideFull && !mobile">Notifications</span>
          </router-link>-->
          <router-link class="aside__nav__link" to="/account" title="Account">
            <IconAccounts />
            <span v-if="navAsideFull && !mobile">Account</span>
          </router-link>
          <!-- <router-link class="aside__nav__link" to="/settings" title="Settings">
          <IconSettings />
          <span v-if="navAsideFull && !mobile">Settings</span>
          </router-link>-->
          <router-link class="aside__nav__link" to="/contact" title="Contact">
            <IconContact /> 
            <span v-if="navAsideFull && !mobile">Contact us</span>
          </router-link>
        </div>
        <div>
          <!-- <a class="aside__nav__link" href="https://oldapp.uxreality.com/" title="Old interface">
            <IconInterface />
            <span v-if="navAsideFull && !mobile">Old interface</span>
          </a>-->
          <a class="aside__nav__link" @click="logOut" title="Log Out">
            <IconLogOut />
            <span v-if="navAsideFull && !mobile">Log Out</span>
          </a>
        </div>
      </nav>
    </div>
  </aside>
</template>
<script>
import Net from "../../modules/net";

export default {
  data() {
    return {
      mobile: this.ismobile,
      navAsideFull: true
    };
  },
  computed: {
    // paymentLink() {
    //   if (
    //     this.$route.path == "/payments" ||
    //     this.$route.path == "/subscription" ||
    //     this.$route.path == "/payment-methods"
    //   ) {
    //     return true;
    //   }
    // }
  },
  watch: {
    $route(to, from) {
      this.navAsideFull = to.path.split("/").length < 3;
      if (
        this.$route.path == "/subscription" ||
        this.$route.path == "/payment-methods"
      ) {
        this.navAsideFull = false; 
      }
    }
  },
  methods: {
    logOut() {
      Net.logOut();
    }
  }
};
</script>
<style lang="scss">
.aside {
  &__full {
    width: 240px;
    .logo {
      display: block;
      width: 160px;
      transition: none;
    }
  }
  &__cut {
    width: 64px;
    .logo {
      display: block;
      width: 42px;
      transition: none;
    }
  }
  &__wrap {
    position: fixed;
    top: 0;
    height: 100%; // FIXME mob-desc 100vh
    background: $color-black-75;
    z-index: 96;
    left: 0;
    width: inherit;
  }
  .logo {
    margin: 40px auto 71px;
  }
  &__nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 170px);
    a {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 14px 20px;

      // &:not(.router-link-exact-active):hover,
      // &.aside__nav__sublink {
      //   background: rgba(255, 255, 255, 0.1);
      // }
      svg {
        display: block;
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        path {
          fill: #fff;
        }
      }
      span {
        color: $color-white;
        margin-left: 16px;
      }
      &.router-link-exact-active {
        background: #fff;
        svg path {
          fill: #4b4b4b;
        }
        span {
          color: $color-black-75;
        }
      }
    }
  }
}
</style>


