<template>
  <div>
    <div class="check__row" v-for="check in checks" :key="check.name">
      <input
        disabled
        :type="params.type"
        :id="check.name"
        :checked="isChecked(check.name) ? 'checked' : false"
        :value="check.name"
        @change="change && change(check.name)"
        :name="name"
      />
      <label @click="select(check.name)" :for="check.name">{{check.name}}</label>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: ((Math.random() * 1e6 * 1e6) ^ 0).toString(),
      selected: null
    };
  },
  props: {
    change: {
      default: function() {
        return () => {};
      }
    },
    params: {
      default: function() {
        return {
          checks,
          type: "checkbox",
          value: 0
        };
      }
    }
  },
  created() {
    this.selected = this.params.value;
  },
  computed: {
    checks() {
      var selected = this.params.checks.filter((e) => {
        return this.isChecked(e.name)
      });
      var notSelected = this.params.checks.filter((e) => {
        return !this.isChecked(e.name)
      });

      return selected.concat(notSelected);
    }
  },
  methods: {
    select(value) {
      if (this.selected.constructor == Array) {
        var index = this.selected.indexOf(value);
        if (!this.isChecked(value)) {
          if (index == -1) {
            this.selected.push(value);
          }
        } else {
          if (index != -1) {
            this.selected.splice(index, 1);
          }
        }
      }
    },
    isChecked(value) {
      var a =
        this.selected.constructor == Array
          ? this.selected.indexOf(value) !== -1
          : value == this.selected;
      return a;
    }
  }
};
</script>