<template>
  <div class="popup__delete">
    <div class="popup__title">Are you sure?</div>
    <div
      class="popup__description"
    >This process can not be reversed and will delete this files from UXReality permanently.</div>
    <div class="popup__delete__confirm  input__row">
     <input
        type="text"
        v-model="text"
        autofocus="true"
        :class="{ 'focused': text }"
        autocomplete="off"
      />
      <label>
        Type
        <span>DELETE</span> to confirm
      </label>
    </div>
    <div class="popup__btns">
      <div class="btn__black" :disabled="text.trim() != 'DELETE'" @click="deleteCard">Delete</div>
      <div class="btn__grey" @click="close">Cancel</div>
    </div>
  </div>
</template>

<script>
import FD from "../../modules/FData";
import Net from "../../modules/net";

export default {
  data() {
    return {
      text: ""
    };
  },
  props: ["close"],
  methods: {
    handleKeyDown(e) {
      //for enter
      if (e.code === "Enter" && this.text.trim() == "DELETE") {
        this.deleteProject();
      }
    },
    deleteCard() {
      // Net.post("/.cmd", FD({  }))
      //   .then(() => {
      //     this.$store.commit("deleteProjectById", this.projectId);
      //     this.close();
      //   })
      //   .catch(() => {
      //     debugger;
      //   });
    },
    created() {
      //for enter
      window.addEventListener("keydown", this.handleKeyDown);
    },
    destroyed() {
      //for enter
      window.removeEventListener("keydown", this.handleKeyDown);
    }
  }
};
</script>