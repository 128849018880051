var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block__maxw mt__80 center"},[_c('div',{staticClass:"title__16 weight__700 grey__121 mt__80 text__center"},[_vm._v(" Name and describe the project to make it unique ")]),_c('Input',{staticClass:"mt__0",attrs:{"params":{ value: _vm.title, label: 'Name*' }},on:{"input":(value) => {
        this.$emit('setProjectTitle', value);
      }}}),_c('Input',{attrs:{"params":{ value: _vm.description, label: 'Description' }},on:{"input":(value) => {
        this.$emit('setProjectDescription', value);
      }}}),_c('div',[_c('div',{staticClass:"title__16 weight__700 grey__121 mt__80 text__center"},[_vm._v(" Choose the language of this project ")]),_c('Select',{staticStyle:{"margin-top":"10px"},attrs:{"label":"Language","options":{
          ar: 'Arabic',
          cn: 'Chinese',
          en: 'English',
          fr: 'French',
          ge: 'German',
          hk: 'Cantonese',
          id: 'Indonesian',
          it: 'Italian',
          ru: 'Russian',
          sp: 'Spanish',
          th: 'Thai',
          vi: 'Vietnamese'
      },"params":{ valueField: _vm.defaultLang }},on:{"input":(value) => {
          this.$emit('setProjectLang', value);
        }}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }