<template>
  <div class="block__maxw mt__80 center">
    <div class="title__16 weight__700 grey__121 mt__80 text__center">
      Name and describe the project to make it unique
    </div>
    <Input
      class="mt__0"
      :params="{ value: title, label: 'Name*' }"
      @input="
        (value) => {
          this.$emit('setProjectTitle', value);
        }
      "
    />
    <!-- <div class="title__16 weight__700 grey__121 mt__80 text__center">Please add short description for this project</div> -->
    <Input
      :params="{ value: description, label: 'Description' }"
      @input="
        (value) => {
          this.$emit('setProjectDescription', value);
        }
      "
    />
    <!-- <div class="col__2">
      <div>
        <div class="title__16 weight__700 grey__121 mt__80">How do you want to provide project?</div>
        <Select
          style="margin-top:10px;"
          label="Project preset"
          :options="{
            '/CreateMethodology.cmd' : 'With UXReality Methodology', 
            '/CreateProject.cmd' : 'With My Own Methodology'}"
          @input="(value) => { this.$emit('setProjectMethodology', value) }"
          :params="{valueField: defaultMethodology}"
        />
      </div> -->
    <div>
      <div class="title__16 weight__700 grey__121 mt__80 text__center">
        Choose the language of this project
      </div>
      <Select
        style="margin-top: 10px"
        label="Language"
        :options="{
            ar: 'Arabic',
            cn: 'Chinese',
            en: 'English',
            fr: 'French',
            ge: 'German',
            hk: 'Cantonese',
            id: 'Indonesian',
            it: 'Italian',
            ru: 'Russian',
            sp: 'Spanish',
            th: 'Thai',
            vi: 'Vietnamese'
        }"
        @input="
          (value) => {
            this.$emit('setProjectLang', value);
          }
        "
        :params="{ valueField: defaultLang }"
      />
    </div>
  </div>
  <!-- </div> -->
</template>
<script>
export default {
  props: ["title", "description", "defaultLang"],
};
</script>