<template>
  <div class="collected__gag text__center" v-if="$_collector">
    <template v-if="!collected">
      <img src="@/assets/img/icons/dashboard.svg" alt="icon" />
      <div class="title__grey38 mt__80">
        <b v-if="$_collector.FinishDate - Date.now() > 0">Your data will be collected in:</b>
        <b v-else>Please wait until the required number of testers are recruited</b>
      </div>
    </template>
    <template v-else-if="$_collector.verified !== true">
      <img src="@/assets/img/icons/dashboard.svg" alt="icon" />
      <div class="title__grey38 mt__80">
        <b>Wait a second, we are uploading analytics...</b>
      </div>
    </template>
    <template v-else>
      <img src="@/assets/img/icons/startup.svg" alt="icon" />
      <div class="title__grey38 mt__80">
        <b>Whoa! We've completed your project faster than expected:</b>
      </div>
    </template>
    <Timer
      v-if="!collected && $_collector.FinishDate - Date.now() > 0"
      :timeLeft="$_collector.FinishDate"
      color="green"
    />
    <Timer
      v-else-if="collected && $_collector.verified == true"
      :timeLeft="$_collector.ActualFinishDate - $_collector.StartDate + Date.now()"
      :disable="collected"
      color="blue"
    />
    <div class="title__16 grey__151 mt__60">
      <template
        v-if="!collected && $_collector.FinishDate - Date.now() > 0"
      >We will notify you by email when your data is ready.</template>
      <template v-else-if="$_collector.FinishDate - Date.now() > 0"></template>
      <template v-else>We will notify you by email when the group of testers is completed.</template>
    </div>
    <div v-if="collected" class="mt__60">
      <div
        v-if="$_collector.verified == true"
        class="btn__blue__full btn__320"
        @click="() => {this.$emit('closeOverlay', true)}"
      >Show Results</div>
      <LoaderMini v-else />
    </div>
  </div>
</template>
<script>
import CurrentProjectInfo from "../../mixins/CurrentProjectInfo";
export default {
  mixins: [CurrentProjectInfo],
  props: ["collected"]
};
</script>
<style lang="scss">
.collected__gag {
  &__relative {
    position: relative;
    min-height: 780px;
    overflow: hidden;
    background: url("../../assets/img/analyze.png") no-repeat top center;
    > div:not(.collected__gag) {
      opacity: 0;
    }
  }
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 796px;
  padding: 40px 20px;
  z-index: 50;
  img {
    max-width: 240px;
  }
  .title__16 grey__151 {
    font-weight: 500;
  }
}
</style>