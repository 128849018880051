<template>
  <div>
    <div class="check__row" v-for="check in params.checks" :key="check.index">
      <input :type="params.type" 
      :id="check.name" 
      :name="name"
      :value="check.value" 
      @change="change && change(check.value)" 
      :checked="check.value == params.value ? 'checked' : false" 
      />
      <label :for="check.name" v-html="check.name"></label>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return {
      name: (Math.random() * 1e6).toString()
    }
  },
  props: {    
    change: {
      default: function() {
        return () => {};
      }
    },
    params: {
      default: function() {
        return {
          checks,
          type: "checkbox",
          value: 0
        };
      }
    }
  }
};
</script>