var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$_project)?_c('div',{staticClass:"block"},[_c('div',{staticClass:"title__24 weight__700 mt__20 text__center"},[_vm._v("Invite Testers")]),(_vm.$_project.ReadOnly)?_vm._t("default",function(){return [_vm._m(0)]}):_vm._t("default",function(){return [(_vm.$_project.TargetPlatform == 1)?[_vm._m(1),_c('CopyCode',{staticClass:"mt__20 center",staticStyle:{"width":"900px"},attrs:{"code":_vm.$_project.PreviewCode,"label":'Preview code'}})]:_vm._e(),_c('div',{staticClass:"title__20 weight__500 mt__20 text__center"},[_vm._v("How would you like to conduct research?")])]}),_c('div',{class:['block__3', 'mt__100', { block_actions: _vm.$_project.ReadOnly }]},[_c('div',{staticClass:"block__hover",on:{"click":() =>{
      if (this.subscription && this.subscription.Name == 'Pay as you go') {
        _vm.setLocalStorage(_vm.$route.path);
        _vm.$router.push({ name: 'payment-subscription' })
    } else {
      _vm.createCollector(1, 'own-testers')
    }}}},[_vm._m(2),_c('h2',{staticClass:"title__up17 mt__20 text__center"},[_vm._v("With my testers")]),_c('div',{staticClass:"title__12 weight__500 grey__151 mt__20 text__center"},[_vm._v(" Invite your testers to participate in the project by sending them a special link. "),(_vm.subscription && _vm.subscription.Name == 'Pay as you go')?_c('div',{staticClass:"black mt__5"},[_vm._v("Available by subscription only.")]):_vm._e()])]),_c('div',{staticClass:"block__hover",on:{"click":() => {
      this.createCollector(2, 'remote-testers');
    }}},[_vm._m(3),_c('h2',{staticClass:"title__up17 mt__20 text__center"},[_vm._v("With paid testers")]),_c('div',{staticClass:"title__12 weight__500 grey__151 mt__20 text__center"},[_vm._v("Use our built-in panels to find testers you need (up to 50 millions people around the world available).")])]),_c('router-link',{staticClass:"block__hover",attrs:{"to":"/contact"}},[_c('div',{staticClass:"image__cover"},[_c('img',{attrs:{"src":require("../../assets/img/testers/custom__testers.jpg"),"alt":"custom testers image"}})]),_c('h2',{staticClass:"title__up17 mt__20 text__center"},[_vm._v("custom solution")]),_c('div',{staticClass:"title__12 weight__500 grey__151 mt__20 text__center"},[_vm._v(" If you need a specific audience please "),_c('span',{staticClass:"link"},[_vm._v("сontact our team.")])])]),(_vm.$_project.ReadOnly)?_c('div',{staticClass:"actions_blocker"}):_vm._e()],1),_c('div',{staticClass:"text__center"},[_c('router-link',{staticClass:"btn__grey__full btn__160",attrs:{"to":'/project/'+ _vm.$_project.Id +'/set-up-project'}},[_vm._v("Back")])],1)],2):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title__20 weight__500 mt__10 text__center"},[_vm._v(" In a real project, you can choose what testers you would like to invite - your own ones "),_c('br'),_vm._v("or from the built-in panel. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt__20 text__center"},[_vm._v(" To see how testers will see your project, "),_c('b',[_vm._v("insert this code")]),_vm._v(" in "),_c('a',{staticClass:"link blue",attrs:{"href":"https://play.google.com/store/apps/details?id=com.uxreality&hl","target":"_blank"}},[_vm._v("UXReality App")]),_vm._v("   and "),_c('br'),_vm._v("go through the survey in a preview mode. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image__cover"},[_c('img',{attrs:{"src":require("../../assets/img/testers/own__testers.jpg"),"alt":"own testers image"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image__cover"},[_c('img',{attrs:{"src":require("../../assets/img/testers/remote__testers.jpg"),"alt":"remote testers image"}})])
}]

export { render, staticRenderFns }