<template>
  <table class="rating__table">
    <tr style="height: 70px;">
      <td colspan="3" style="vertical-align: top;">
        <div class="text__overflow3">
          {{label}}
        </div>
        </td>
      <td :style="`color: ${color};`" style="padding-left: 0px; vertical-align: top; line-height:1;">
        <div class="title__24 weight__700">{{average}}</div>
        <div class="title__10">
          <b>AVERAGE</b>
        </div>
      </td>
    </tr>
    <tr v-for="(array, index) in arrays" :key="index" class="mt__40">
      <td>
        <img :src="require(`@/assets/img/rating/${type}-${5 - index}.svg`)" />
      </td>
      <td class="title__14 weight__500 grey__151 lh__20">{{array.Name}}</td>
      <td>
        <div class="rating__lines">
          <div class="rating__lines__value" :style="`width: ${array.Percentage}%;`"></div>
        </div>
      </td>
      <td class="title__14 weight__500 grey__151 lh__20">{{array.Percentage}}%</td>
    </tr>
  </table>
</template>

<script>
export default {
  props: ["type", "arrays", "label", "average", "color"],
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.rating__table {
  td {
    font-size: 14px;
    padding: 2px 0;
    vertical-align: inherit;
    &:nth-child(1) {
      width: 32px;
      padding-right: 10px;
    }
    &:nth-child(2) {
      width: 1px;
      padding-right: 10px;
    }
    &:nth-child(3) {
      width: calc(100% - 36px);
      padding-right: 20px;
    }
    &:last-child {
      width: 1px;
      text-align: right;
      padding-right: 0;
    }
  }
  tr {
    &:first-child td {
      padding-top: 0;
      padding-bottom: 20px;
    }
    &:not(:first-child) {
      height: 30px;
    }
    &:nth-child(2) td .rating__lines__value {
      background: $color-green;
    }
    &:nth-child(3) td .rating__lines__value {
      background: $color-yellow;
    }
    &:nth-child(4) td .rating__lines__value {
      background: $color-orange;
    }
    &:nth-child(5) td .rating__lines__value {
      background: $color-red;
    }
    &:nth-child(6) td .rating__lines__value {
      background: $color-bloody;
    }
  }
}
.rating__lines {
  background: #fafafa;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: $border-radius;
  width: 100%;
  height: 10px;
  &__value {
    border-radius: inherit;
    height: inherit;
    box-shadow: 0 0 40px -10px #fff;
  }
}
</style>