<template>
  <div class="blue timer__title mr__10" :class="color" v-html="countdown"></div>
</template>

<script>
export default {
  data() {
    return { now: Math.trunc((new Date()).getTime() / 1000) };
  },
  props: ["color", "disable", "timeLeft"],
  computed: {
    endTime() {
      return (this.timeLeft / 1000) ^ 0;// from ms to seconds
    },
    seconds() {
      return (this.endTime - this.now) % 60;
    },
    minutes() {
      return Math.trunc((this.endTime - this.now) / 60) % 60;
    },
    hours() {
      return Math.trunc((this.endTime - this.now) / 60 / 60);// % 24;
    },
    // days() {
    //   return Math.trunc((this.endTime - this.now) / 60 / 60 / 24);
    // },
    countdown() {
      return (
        "<span>" +
        (this.hours < 10 ? "0" : "") +
        this.hours +
        "</span>hours <span>" +
        (this.minutes < 10 ? "0" : "") +
        this.minutes +
        "</span>minutes"
      );
    }
  },
  mounted() {
    if (!this.disable) {
      const intervalId = setInterval(() => {
        const diff = this.endTime - this.now;

        if (diff < 0) {
          this.now = this.endTime;
          clearInterval(intervalId);
        }

      }, 1000);
    }
  }
};
</script>

<style lang="scss">
.timer__title {
  font-size: 72px;
  white-space: nowrap;
  span {
    font-weight: 900;
    display: inline-block;
    &:nth-child(2n + 1) {
      width: 110px;
      text-align: right;
    }
    &:nth-child(2n) {
      // width: 30px;
      text-align: center;
    }
  }
}
</style>