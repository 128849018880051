<template>
  <div class="block mt__40">
    <div class="title__24 weight__700 mt__20 text__center">Project setup</div>
    <div v-if="questItem && subscription" class="block__maxw center">
      <div
        class="title__16 grey__151 text__center mt__80"
      >Insert a {{questItem.QuestionType == 30 ? "Google Play" : questionInfo.titleMini}} link for testing and describe a task for testers</div>
      <div class="tooltip__right">
        <TooltipLinkCopy :questionInfo="questionInfo" />
        <Input
          class="mt__0"
          :params="{label: questionInfo.linkLabel, placeholder: questionInfo.linkPlaceholder, autofocus: true, object: questItem, field: 'EyeTrackingWebSiteUrl', change: makeScreenShot, validate: 'link'}"
        />
      </div>
      <Input
        :params="{label: questionInfo.taskLabel, placeholder: questionInfo.taskPlaceholder, object: questItem, field: 'EyeTrackingWebSiteObjective', textarea: 'true'}"
      />
      <template v-if="questItem.QuestionType == 31">
        <div
          class="title__16 grey__151 text__center mt__80"
        >Setup additional options for your prototype</div>
        <div class="col__2__1">
          <div>
            <Select
              :label="questionInfo.deviceLabel"
              :options="questItem.Devices"
              :params="{object: questItem, field: 'PrototypeDevice' }"
            />
          </div>
          <div class="non__edit" v-if="_project.TargetPlatform == 3">
            <Select
              :label="questionInfo.deviceOrientationLabel"
              :options="{'1' : 'Horizontal', '1' : 'Horizontal'}"
              :params="{target: 'currentQuestItem.QuestItem.PrototypePosition' }"
            />
          </div>
          <div v-else>
            <Select
              :label="questionInfo.deviceOrientationLabel"
              :options="{'0' : 'Vertical', '1' : 'Horizontal'}"
              :params="{object: questItem, field: 'PrototypePosition' }"
            />
          </div>
        </div>
      </template>
      <div class="title__16 grey__151 text__center mt__80">{{questionInfo.sliderLabel}}</div>
      <BlockRange
        :rangeArray="['1 min', '2 min', '3 min']"
        :valuesArray="[60, 120, 180]"
        :object="questItem"
        field="LimitTimeout"
      />
    </div>
    <div class="text__center mt__40">
      <router-link
        :to="'/project/' + _project.Id + '/set-up-project'"
        class="btn__grey__full btn__200"
        @click="next()"
      >Skip</router-link>
      <button
        class="btn__green__full btn__200 ml__40"
        @click="next()"
        :disabled="!questItem.EyeTrackingWebSiteUrl"
      >Finish</button>
    </div>
  </div>
</template> 

<script>
import CreateProject from "../../mixins/CreateProject";
import config from "@/components/Questions/questionTypes.json";
import Net from "../../modules/net";
import FD from "../../modules/FData";

export default {
  mixins: [CreateProject],
  computed: {
    questItem() {
      return this._project.QuestData.Items.filter((e) => {
        return [24, 30, 31].indexOf(e.QuestItem.QuestionType) != -1;
      })[0].QuestItem;
    },
    questionInfo() {
      return config[this.questItem.QuestionType];
    },
    subscription() {
      return (
        this.$store.state.userInfo && this.$store.state.userInfo.Subscription
      );
    },
  },
  methods: {
    makeScreenShot(value) {
      Net.post(
        "/MakeSiteScreenshot.cmd",
        FD({
          siteUrl: value,
          pageWidth: 1600,
          questionId: this.questItem.Id,
        })
      )
        .then((e) => {
          this.$store.commit("update", {
            target: this.questItem,
            field: "MediaFilePath",
            value: e.data.MediaFilePath,
          });

          this.$store.commit("update", {
            target: this.questItem,
            field: "PreviewFilePath",
            value: e.data.PreviewFilePath,
          });

          this.$store.commit("update", {
            target: this.project,
            field: "ReadyForStart",
            value: true,
          });
        })
        .catch((error) => {
          debugger;
        });
    },
    next() {
      if (this._projectMethodology == "/CreateMethodology.cmd") {
        this.$router.push({
          path: "/project/" + this._project.Id + "/invite-testers/",
        });
      } else {
        this.$router.push({
          path: "/project/" + this._project.Id + "/set-up-project",
        });
      }
    },
  },
};
</script>
