<template>
  <div class="block mt__100vh text__center">
    <router-link to="/signin" class="title__16 grey__151 flex__ec icon-arr-long">
      <img src="@/assets/img/icons/arr_long.svg" />Sign In
    </router-link>
    <div class="sign__title">Sign Up</div>
    <div class="title__14 weight__500 grey__151 lh__20">Hello there! Sign Up and join to revolution researcher’s tool</div>
    <!-- FORM -->
    <form class="sign__form block__maxw center" @submit.prevent="sendForm">
      <!-- Name -->
      <div class="input__row" :class="{'error': error.require, 'valid': !error.require}">
        <input v-model="name" type="text" :class="{ 'focused': name}" id="name" ref="Name" />
        <label for="name">
          Name
          <span v-if="error.require">{{ error.require }}</span>
        </label>
        <IconUser class="input__icon" />
      </div>
      <!-- Job -->
      <div class="input__row hide__select__arr" :class="{'error': error.require, 'valid': !error.require}">
        <Select
        :label="'Job Title'"
        :options="['Dev', 'Support', 'Marketing', 'CEO', 'Manager', 'Sale']"
      />
        <IconJob class="input__icon" />
      </div>

     
      <!-- Button -->
      <CheckRadio
        :change="switchType"
        :params="{checks: [{name: 'Show characters', value: 0}], type: 'checkbox'}"
      />
      <button
        class="btn__blue__full btn__320 mt__40"
        :class="{'disable': !disableButton}"
        type="submit"
      >Sign Up</button>
    </form>
    <!-- end FORM -->
  </div>
</template>

<script>
export default {
  data: () => ({
    name: null,
    job: null,
    email: null,
    password: null,
    passwordFieldType: "password",
    error: {
      require: ""
    }
  }),
  computed: {
    disableButton() {
      if (this.name && this.job) return true;
    }
  },
  methods: {
    switchType() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    sendForm() {
      if (!this.name) {
        this.$refs.Name.validationMessage(
          'Is required',
        );
      }
      if (!this.error) {
        console.log("send");
      }
    }
  }
};
</script>

<style lang="scss">
.disable {
  opacity: 0.5;
}
.hide__select__arr {
  label {
    transition: none;
  }
  .custom__select .selected .icon-arr-right {
    display: none;
  }
}
</style>
<!--
  <form @submit.prevent="someAction()">
    <input v-model="email" type="email" required>
    <input v-model="password" type="password" required>
    <input v-model="repeatedPassword" type="password" required ref="repeatedPasswordEl">

    <button type="submit">
      Отправить форму
    </button>
  </form>

export default {
let validEmailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  data() {
    return {
      email: null,
      password: null,
      repeatedPassword: null,
    };
  },

  watch: {
    repeatedPassword: 'checkPasswordsEquality',
    password: 'checkPasswordsEquality',
  },

  methods: {
    checkPasswordsEquality() {
      const { password, repeatedPassword } = this;
      const { repeatedPasswordEl } = this.$refs;

      if (password !== repeatedPassword) {
        repeatedPasswordEl.setCustomValidity(
          'Пароли должны совпадать',
        );
      } else {
        repeatedPasswordEl.setCustomValidity('');
      }
    },
  },

};

-->