<template>
  <div class="tab__content">
    <!-- SCENARIO TABS -->
    <!-- <div class="flex__sc tabs__overflow">
      <div
        :class="['tabs__overflow__img', {'active': scenarioPage == 'All'}]"
        @click="scenarioPage = 'All'"
      >
        <IconPie />
      </div>
      <div
        :class="['scentab title__24  weight__700 text__up grey__121 ml__30', {'active': scenarioPage == 'All'}]"
        @click="scenarioPage = 'All'"
      >all scenarios</div>
      <div
        :class="['scentab title__24  weight__700 text__up grey__121 ml__30', {'active': scenarioPage == scenario.Name}]"
        v-for="(scenario, index) in scenarios"
        :key="index"
        @click="scenarioPage = scenario.Name"
      >{{scenario.Name}}</div>
    </div> -->
    <!-- end SCENARIO TABS -->
    <!-- <PieChartAll v-if="scenarioPage == 'All'" class="mt__60" :scenarios="scenarios" /> 
    <Scenario v-else class="mt__20" /> -->
    <Scenario class="mt__20" />
  </div>
</template>

<script>
export default {
  // props:["scenarios"],
  data() {
    return {
      scenarios,
      scenarioPage: "All"
    };
  }
};

const scenarios = [
  {
    Name: "Scenario 1",
    Color: "#FB497C",
    Percentage: 42,
    testers: 1260,
    timeSpent: "1:32m",
    misclicks: 33,
    Advantages: [
      {
        0: "passing speed on last 4 screens <b>50% faster</b>",
        1: "average duration is <b>12% lower</b>",
        2: "average emotions is <b>positive</b>"
      }
    ],
    Flaws: [
      {
        0: "women were <b>2 times slower</b> than men",
        1: "people with emotion <b>negative was 2% more</b>"
      }
    ]
  },
  {
    Name: "Scenario 2",
    Color: "#FEA604",
    Percentage: 34,
    testers: 240,
    timeSpent: "0:15m",
    misclicks: 4,
    Advantages: [
      {
        0: "passing speed on last 4 screens <b>50% faster</b>",
        1: "average duration is <b>12% lower</b>",
        2: "average emotions is <b>positive</b>"
      }
    ]
  },
  {
    Name: "Scenario 3",
    Color: "#6DE320",
    Percentage: 12,
    testers: 44,
    timeSpent: "11:08m",
    misclicks: 113,
    Advantages: [
      {
        0: "average emotions is <b>positive</b>",
        1: "passing speed on last 4 screens <b>50% faster</b>"
      }
    ],
    Flaws: [
      {
        0: "women were <b>2 times slower</b> than men",
        1: "people with emotion <b>negative was 2% more</b>",
        2: "average duration is <b>12% lower</b>"
      }
    ]
  },
  {
    Name: "Scenario 4",
    Color: "#4FCCFF",
    Percentage: 7,
    testers: 16,
    timeSpent: "1:08m",
    misclicks: 13,
    Advantages: [
      {
        0: "average emotions is <b>positive</b>",
        1: "passing speed on last 4 screens <b>50% faster</b>"
      }
    ],
    Flaws: [
      {
        0: "women were <b>2 times slower</b> than men",
        1: "people with emotion <b>negative was 2% more</b>"
      }
    ]
  },
  {
    Name: "Scenario 5",
    Color: "#4D7BF3",
    Percentage: 5,
    testers: 6,
    timeSpent: "0:18m",
    misclicks: 3,
    Advantages: [
      {
        0: "average emotions is <b>positive</b>",
        1: "passing speed on last 4 screens <b>50% faster</b>"
      }
    ],
    Flaws: [
      {
        0: "women were <b>2 times slower</b> than men",
        1: "people with emotion <b>negative was 2% more</b>"
      }
    ]
  }
];
</script>

<style lang="scss">
.tabs__overflow {
  white-space: nowrap;
  width: 100%;
  overflow-x: auto;
  padding-bottom: 20px;
  &__img {
    width: 29px;
    height: 29px;
    svg {
      width: 29px;
    }
  }
}
.scentab.active {
  color: $color-teal-blue;
}
.scentab:not(.active) {
  cursor: pointer;
  opacity: 0.5;
}
</style>