<template>
  <div class="account" v-if="user">
    <!-- Subscription  -->
    <div class="block flex__subscription mt__20">
      <div class="flex__sc">
        <div>
          <img src="@/assets/img/subscription.svg" alt />
        </div>
        <Subscription />
      </div>
      <router-link class="btn__blue btn__200" to="/subscription">CHANGE</router-link>
    </div>
    <!-- end Subscription  -->
    <div class="title__line__blue mt__40">My account</div>

    <div class="block block__minh flex__bs mob__rev">
      <div>
        <div class="account__img">
          <img
            v-if="user.User.Avatar.PreviewUrl"
            :src="user.User.Avatar.PreviewUrl"
            alt="User avatar"
          />
          <img v-else src="@/assets/img/account-defult.jpg" alt="default account" />
          <!-- <img :src="image" alt="User avatar" /> -->
        </div>

        <!-- <div class="flex__bc mt__20" v-if="edit">
          <div class="btn__grey btn__160">Delete photo</div>
          <div class="btn__green btn__160" @click="$refs.file.click()">
            Upload photo
            <input type="file" ref="file" style="display: none" />
          </div>
        </div>-->
      </div>
      <div class="account__info">
        <!-- <div class="flex__account"> -->
          <!-- <Subscription /> -->
          <!-- <div class="btn__blue btn__120" @click="edit = true" v-if="!edit">Edit</div> -->
          <!-- <div class="btn__green btn__120" @click="edit = false" v-else>Save</div> -->
        <!-- </div> -->

        <div class="col__2">
          <div>
            <Input :params="{label: 'Name', target: 'userInfo.User.Name'}" class="mt__0"/>
            <Input
              :params="{label: 'Email', type: 'email', target: 'userInfo.User.Email', validate: 'email' }"
            />
            <Select
              :label="'Country'"
              :params="{target: 'userInfo.UserInfo.Country' }"
              :options="countries"
            />
            <!--
            <Input :params="{label: 'Old Password'}" v-if="changePass && edit" />
            <div class="input__row" v-if="!changePass && edit"> 
              <input
                value="change password"
                class="focused change__pass"
                @click="changePass = true"
              />
              <label>Password</label>
            </div>
            -->
          </div>
          <div>
            <Input :params="{label: 'Job', target: 'userInfo.Company.Job'}" class="mt__0" />
            <Input
              :params="{label: 'Phone', target: 'userInfo.Company.Phone', validate: 'phone' }"
            />
            <Input :params="{label: 'Company', target: 'userInfo.Company.Name'}" />
            <Input :params="{label: 'New Password'}" v-if="changePass && edit" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Net from "../modules/net";
import countriesList from "./countriesList.json";

export default {
  data() {
    return {
      edit: false,
      // image: "/img/account-defult.jpg",
      changePass: false,
      countries: countriesList
    };
  },
  computed: {
    user() {
      return this.$store.state.userInfo;
    }
  }
};
</script>
 
<style lang="scss">
.btn__log {
  padding: 5px 14px;
  &:hover .icon-log-out {
    path,
    rect {
      fill: $color-white;
      stroke: $color-white;
    }
  }
}
.account {
  &__img {
    width: 350px;
    height: 350px;
    border-radius: $border-radius-sm;
    overflow: hidden;
    img {
      display: block;
      object-fit: cover;
    }
  }
  &__info {
    width: calc(100% - 350px - 40px);
  }
  .change__pass {
    color: $color-teal-blue;
    cursor: pointer;
    border-bottom: 0;
  }
}
@include for-mobile-only {
  .account {
    &__img,
    &__info {
      width: 100%;
    }
    &__img {
      height: 100%;
      margin: 20px auto 0;
    }
  }
}
</style>