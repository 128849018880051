<template>
  <div class="question__list">
    <div class="title__14 weight__500 grey__151 lh__20">Add Question</div>
      <div
        class="block__hover mt__20"
        v-for="(question, index) in questions"
        :key="index"
        @click="create(question.typeQ)"
      >
        <div class="question__list__text">
          <div class="title__16 weight__700">{{question.title}}</div>
          <div class="title__14 mt__10" style="line-height: 16px;">{{question.description}}</div>
        </div>
        <div class="question__list__example">
          <img :src="require(`@/assets/img/questions/${question.typeQ}.png`)" :alt="question.title" />
        </div>
      </div>
  </div>
</template>

<script>
import Net from "../../modules/net";

export default {
  data() {
    return {
      questions
    };
  },
  props: ["after", "quest"],
  methods: {
    create(qType) {
      var after = this.after == -1 ? -1 : this.after.QuestItem.Id;
      Net.post(
        "/AddQuestItem.cmd",
        JSON.stringify({
          quest: this.$store.state.currentQuestId,
          type: 0,
          after: after == -1 ? undefined : after,
          questionType: qType
        }),
        {
          "Content-Type": "application/json"
        }
      ).then(e => {
          this.$store.commit("addQuestItem", {
            item: e.data.apiItem,
            after
          });
          this.$parent.$emit('selectQuestion', e.data.apiItem)
        })
        .catch(error => {
          debugger;
        });
    }
  }
};

const questions = [
  {
    title: "Instruction",
    description:
      "Question allows a researcher to make some remarks for testers or  instruct them on what they should do during the test.",
    typeQ: 18
  },
  {
    title: "Single answer",
    description:
      "Question allows testers to choose one the most appropriate answer from a list of given options.",
    typeQ: 3
  },
  {
    title: "Single answer dropdown",
    description:
      "Question allows testers to choose one the most appropriate answer from a dropdown list.",
    typeQ: 5
  },
  {
    title: "Multiple answers",
    description:
      "Question allows testers to choose one or more of the most appropriate answers from a list of given options.",
    typeQ: 4
  },
  {
    title: "Open-ended question",
    description:
      "Question allows testers to answer in open text format  (in the form of a word, phrase, essay).",
    typeQ: 1
  },
  {
    title: "Smiley Rating",
    description:
      "A smiley rating question (or emoji survey) allows a researcher to measure the likeability of the product by a 5-point rating scale and also evaluate how testers feel using the product.",
    typeQ: 9
  }
];
</script>

<style lang="scss">
.question__list {
  &__text {
    @include for-mobile-up {
      width: calc(100% - 20px - 120px);
    }
  }
  &__example {
    max-width: 150px;
    @include for-mobile-up {
      width: 120px;
      margin-left: 20px;
    }
    @include for-mobile-only {
      margin-top: 20px;
    }
  }
  .block__hover {
    padding-bottom: 5px;
    @include for-mobile-only {
    }
    @include for-mobile-up {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
    .title__16 weight__700 {
      color: $color-teal-blue;
    }
    // img {
    //   margin-top: 5px;
    //   height: 40px;
    // }
  }
}
</style>