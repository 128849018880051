<template>
  <div v-if="questItem && subscription" class="block__maxw center">
    <div class="title__16 grey__151 text__center mt__80">Insert a link to the {{questionInfo.title}} for testing</div>
    <Input
      class="mt__0"
      :params="{label: questionInfo.linkLabel, placeholder: questionInfo.linkPlaceholder, autofocus: true, object: questItem, field: 'EyeTrackingWebSiteUrl', change: makeScreenShot, validate: 'link'}"
    />
    <Input
      :params="{label: questionInfo.taskLabel, placeholder: questionInfo.taskPlaceholder, object: questItem, field: 'EyeTrackingWebSiteObjective', textarea: 'true'}"
    />
    <template v-if="questItem.QuestionType == 31">
      <div class="title__16 grey__151 text__center mt__80">Setup additional options for your prototype</div>
      <div class="col__2__1">
        <div>
          <Select
            :label="questionInfo.deviceLabel"
            :options="questItem.Devices"
            :params="{object: questItem, field: 'PrototypeDevice' }"
          />
        </div>
        <div>
          <Select
            :label="questionInfo.deviceOrientationLabel"
            :options="{'0' : 'Vertical', '1' : 'Horizontal'}"
            :params="{object: questItem, field: 'PrototypePosition' }"
          />
        </div>
      </div>
    </template>

    <div class="title__16 grey__151 text__center mt__80">{{questionInfo.sliderLabel}}</div>
    <BlockRange
      :rangeArray="['1 min', '2 min', '3 min']"
      :valuesArray="[60, 120, 180]"
      :object="questItem"
      field="LimitTimeout"
    />
  </div>
</template>

<script>
import Net from "../../modules/net";
import FD from "../../modules/FData";
import config from "../Questions/questionTypes.json";

export default {
  data() {
    return {
      mobile: this.ismobile
    };
  },
  props: ["project"],
  watch: {},
  created() {},
  computed: {
    questItem() {
      return this.project.QuestData.Items.filter(e => {
        return [24, 30, 31].indexOf(e.QuestItem.QuestionType) != -1;
      })[0].QuestItem;
    },

    questionInfo() {
      return config[this.questItem.QuestionType];
    },
    subscription() {
      return (
        this.$store.state.userInfo && this.$store.state.userInfo.Subscription
      );
    },
    deviseList() {
      return this.project.Devices;
    }
  },
  methods: {
    makeScreenShot(value) {
      // if (!value) {
      //   return;
      // }
      this.fillLink = false;
      Net.post(
        "/MakeSiteScreenshot.cmd",
        FD({
          siteUrl: value,
          pageWidth: 1600,
          questionId: this.questItem.Id
        })
      )
        .then(e => {
          this.$store.commit("update", {
            target: this.questItem,
            field: "MediaFilePath",
            value: e.data.MediaFilePath
          });

          this.$store.commit("update", {
            target: this.questItem,
            field: "PreviewFilePath",
            value: e.data.PreviewFilePath
          });

          this.$store.commit("update", {
            target: this.project,
            field: "ReadyForStart",
            value: true
          });
        })
        .catch(error => {
          debugger;
        });
    }
  }
};
</script>
