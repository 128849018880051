<template>
  <div class="input__row">
    <el-tiptap
      class="editor"
      :class="{
        'editor--focused': isFocused || isNonEmpty,
      }"
      :tooltip="false"
      :showMenubar="false"
      :charCounterCount="false"
      :extensions="extensions" 
      :content="currentContent" 
      :placeholder="placeholder"
      @onFocus="onFocus"
      @onBlur="onBlur"
      @onUpdate="onEditorUpdate">
    </el-tiptap>
    <label>
      {{ label }}
    </label>
  </div>
</template>

<script>
import {
  Doc,
  Text,
  Paragraph,
  Heading,
  Bold,
  Italic,
  Underline,
  TextColor,
  FontSize
} from "element-tiptap";
import Net from "../../modules/net";
export default {
  props: ["placeholder", "label", "target"],
  data() {
    return {
      extensions: [
        new Doc(),
        new Text(),
        new Paragraph(),
        new Heading({ level: 5 }),
        new Bold({ bubble: true }),
        new Italic({ bubble: true }),
        new Underline({ bubble: true }),
        new TextColor({ bubble: true }),
        new FontSize({ bubble: true })
      ],
      isFocused: false,
      path: [],
      content: ""
    }
  },
  created() {
    this.path = this.target.split(".");
    var element = this.$store.state;

    for (var p = 0; p < this.path.length; p++) {
      element = element[this.path[p]];
    }

    this.content = element;
  },
  computed: {
    isNonEmpty() {
      return this.hasParagraph ? this.currentContent.length > 7 : this.currentContent.length > 0;
    },
    hasParagraph() {
      return !!this.currentContent.match('<p>')
    },
    currentContent() {
      return this.content ? this.content : "";
    }
  },
  methods: {
    onFocus({editor}) {
      this.isFocused = true;
    },
    onBlur({editor}) {
      this.isFocused = false;
      this.updateField();
    },
    onEditorUpdate(data) {
      this.content = data;
    },
    updateField() {
      Net.update(this.path, this.content);
    }
  }
}
</script>

<style lang="scss">
.editor {
  padding: 16px 0 5px;
  &>.el-tiptap-editor__content {
    font-size: 17px;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
  }
  .el-tiptap-editor__placeholder.el-tiptap-editor--empty:first-child:before {
    font-style: italic;
    font-size: 17px;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
    color: transparent;
  }
  +label {
    pointer-events: none; // fix
  }
  &--focused {
    .el-tiptap-editor__placeholder.el-tiptap-editor--empty:first-child:before {
      color: #979797;
    }
    +label {
      top: 1px;
      font-size: 12px;
    }
  }
}
</style>