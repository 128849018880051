<template>
  <div>
    <div class="title__14 weight__500 grey__151 lh__20">Single Answer</div>
      <Input
        :params="{label: 'Question', target: 'currentQuestItem.QuestItem.Name', textarea: 'true'}"
      />  
      <PseudoCheckRadio
        class="mt__40"
        :params="{options: alternatives, type: 'radio', addOption: true}"
      /> 
  </div>
</template>

<script>
import ReloadProxy from "../../mixins/ReloadProxy"

export default {
  props: ["alternatives"],
  mixins: [ReloadProxy]
};
</script>