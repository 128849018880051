<template>
  <div v-if="subscription">
    <div class="title__24  weight__500 text__up">
      <b>{{subscription.Name}}</b> subscription
    </div>
    <div v-if="subscription.Name !== 'Pay as you go'" class="title__14 weight__500 grey__151 lh__20 mt__10">
      <div>Till current subscription expires:</div>
      <ul style="padding-top: 0;">
        <li>
          <template v-if="left < 2">
            <b>Last day</b>
          </template>
          <template v-else>
            <b>{{ left }} days</b> left
          </template>
        </li>
        <li>
          <b>{{subscription.ResponseCollected}} out of {{subscription.SubscriptionBasicResponseNumber}}</b> recordings collected
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    subscription() {
      return (
        this.$store.state.userInfo && this.$store.state.userInfo.Subscription
      );
    },
    left() {
      return this.subscription && this.subscription.DaysToExpiration;
    }
  }
};
</script>
<style lang="scss">
.flex__subscription,
.flex__account {
  display: flex;
  @include for-mobile-only {
    flex-direction: column;
    .btn__blue,
    .btn__green {
      margin-top: 20px;
    }
  }
  @include for-mobile-up {
    justify-content: space-between;
    align-items: center;
  }
}
.flex__subscription {
  @include for-mobile-only {
    img {
      display: none;
    }
  }
  @include for-mobile-up {
    img {
      width: 132px;
      margin-right: 40px;
    }
  }
  &__starter {
    @include for-mobile-only {
      margin-top: 10px;
    }
    @include for-mobile-up {
      text-align: right;
    }
  }
}
.flex__account {
  @include for-mobile-up {
    align-items: flex-start;
  }
}
</style>