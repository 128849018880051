<template>
  <div id="app" class="wrap__site">
    <NavAside v-if="hideNav" />
    <main class="wrapper">
      <router-view />
    </main>
    <Loader v-if="loader" />
    <AttentionMessage />
    <MobileNotOptimizedMessage v-if="smallScreen" />
    <CookiesMessage />
  </div>
</template> 
 
<script>
import Net from "./modules/net";
export default {
  data() {
    return {
      mobile: this.ismobile,
      smallScreen: this.isSmallScreen,
      hideNav: false,
      status: false
    };
  },
  created() {
    if (
      document.location.search &&
      document.location.search.indexOf("?token=") == 0
    ) {
      Net.access_token = document.location.search.replace("?token=", "");
      localStorage.setItem("access_token", Net.access_token);
      Net.token_type = "Bearer";
    }
    this.runCheckAuth();
  },
  computed: {
    loader() {
      return this.$store.state.loader;
    }
  },
  watch: {
    $route() {
      this.hideNav =
        this.$route.path === "/signin" ||
        this.$route.path === "/forgotpass" ||
        this.$route.path === "/resetpass" ||
        this.$route.path === "/registration"
          ? false
          : true;
    }
  },
  methods: {
    runCheckAuth(e) {
      Net.checkAuth()
        .then(() => {
          this.$store.dispatch("loadProjects", true);
          this.$store.commit("loaderAction", false);
        })
        .catch(() => {
          this.$store.commit("loaderAction", false);
        })
    }
  }
};
</script>

<style lang="scss">
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-vue-inputs/styles/material.css";
@import "assets/css/main.scss";

.wrap__site {
  display: flex;
}
</style>