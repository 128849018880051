<template>
  <div class="block" v-if="$_project">
    <div class="title__24 weight__700 mt__20 text__center">Invite Testers</div>
    <slot v-if="$_project.ReadOnly">
      <div class="title__20 weight__500 mt__10 text__center">
        In a real project, you can choose what testers you would like to invite - your own ones
        <br />or from the built-in panel.
      </div>
    </slot>
    <slot v-else>
      <template v-if="$_project.TargetPlatform == 1">
        <div class="mt__20 text__center">
          To see how testers will see your project,
          <b>insert this code</b> in
          <a
            href="https://play.google.com/store/apps/details?id=com.uxreality&hl"
            target="_blank"
            class="link blue"
          >UXReality App</a> &nbsp; and
          <br />go through the survey in a preview mode.
        </div>
        <CopyCode
          class="mt__20 center"
          style="width:900px;"
          :code="$_project.PreviewCode"
          :label="'Preview code'"
        />
      </template>
      <div class="title__20 weight__500 mt__20 text__center">How would you like to conduct research?</div>
    </slot>

    <div :class="['block__3', 'mt__100', { block_actions: $_project.ReadOnly }]">
      <div
        class="block__hover"
        @click="() =>{
        if (this.subscription && this.subscription.Name == 'Pay as you go') {
          setLocalStorage($route.path);
          $router.push({ name: 'payment-subscription' })
      } else {
        createCollector(1, 'own-testers')
      }}"
      >
        <div class="image__cover">
          <img src="../../assets/img/testers/own__testers.jpg" alt="own testers image" />
        </div>
        <h2 class="title__up17 mt__20 text__center">With my testers</h2>
        <div class="title__12 weight__500 grey__151 mt__20 text__center">
          Invite your testers to participate in the project by sending them a special link.
          <div
            class="black mt__5"
            v-if="subscription && subscription.Name == 'Pay as you go'"
          >Available by subscription only.</div>
        </div>
      </div>
      <div
        class="block__hover"
        @click="() => {
        this.createCollector(2, 'remote-testers');
      }"
      >
        <div class="image__cover">
          <img src="../../assets/img/testers/remote__testers.jpg" alt="remote testers image" />
        </div>
        <h2 class="title__up17 mt__20 text__center">With paid testers</h2>
        <div
          class="title__12 weight__500 grey__151 mt__20 text__center"
        >Use our built-in panels to find testers you need (up to 50 millions people around the world available).</div>
      </div>
      <router-link class="block__hover" to="/contact">
        <div class="image__cover">
          <img src="../../assets/img/testers/custom__testers.jpg" alt="custom testers image" />
          <!-- <div class="coming__soon">
            <span class="title__up17">Coming soon</span>
          </div>-->
        </div>
        <h2 class="title__up17 mt__20 text__center">custom solution</h2>
        <div class="title__12 weight__500 grey__151 mt__20 text__center">
          If you need a specific audience please
          <span class="link">сontact our team.</span>
        </div>
      </router-link>
      <div class="actions_blocker" v-if="$_project.ReadOnly"></div>
    </div>
    <div class="text__center">
      <router-link
          :to="'/project/'+ $_project.Id +'/set-up-project'" class="btn__grey__full btn__160">Back</router-link>
    </div>
  </div>
</template>

<script>
import Net from "../../modules/net";
import FD from "../../modules/FData";
import Bus from "../../modules/eventBus";
import CurrentProjectInfo from "../../mixins/CurrentProjectInfo";

export default {
  mixins: [CurrentProjectInfo],
  data() {
    return {
      testersLink: null
    };
  },
  computed: {
    subscription() {
      return (
        this.$store.state.userInfo && this.$store.state.userInfo.Subscription
      );
    }
  },
  watch: {
    $_collectors() {
      if (
        this.$_collectors &&
        this.$_collectors[0] &&
        !this.$_project.ReadOnly
      ) {
        this.$router.push({
          name: "collector-view",
          params: {
            collectorid: this.$_collectors[0].Id
          }
        });
      }
    }
  },
  created() {
    if (this.$_collectors && this.$_collectors[0] && !this.$_project.ReadOnly) {
      this.$router.push({
        name: "collector-view",
        params: {
          collectorid: this.$_collectors[0].Id
        }
      });
    }
  },
  methods: {
    createCollector(type) {
      this.$store.commit("loaderAction", true);
      Net.post(
        "/AddCollector.cmd",
        JSON.stringify({
          project: this.$_project.Id,
          collectortype: type
        }),
        {
          "Content-Type": "application/json"
        }
      )
        .then(e => {
          this.$store.commit("addCollector", e.data);
          this.$router.push({
            name: "collector-view",
            params: {
              collectorid: e.data.Id
            }
          });
          this.$store.commit("loaderAction", false);
        })
        .catch(error => {
          debugger;
        });
    },
    setLocalStorage(link) {
      localStorage.setItem("backlink_collector", link);
    }
  }
};
</script>

<style scoped>
.actions_blocker {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.block_actions {
  position: relative;
}
</style>