<template>
  <div class="progress__circle__wrap">
    <div
      :class="['progress__circle text__center',{'progress__circle__pointer':type == 'CircleClicked'}]"
      @click="checkCircle()"
    >
      <div class="progress__circle__persent">
        <svg viewBox="0 0 36 36" class="circle__chart">
          <path
            class="circle__chart__bg"
            d="M18 2
          a 16 16 0 0 1 0 32
          a 16 16 0 0 1 0 -32"
          />
          <g
            v-for="(segment, index) in graphSegments"
            :transform="'rotate(' + segment.angle + ' 18 18)'"
            :key="index"
            @mouseenter="selectedSegment = graphSegments.length > 1 ? segment : null"
            @mouseleave="selectedSegment = null"
          >
            <path
              class="circle__chart__value"
              :stroke-dasharray="segment.value + ' ' + 100"
              :style="{stroke: segment.color}"
              d="M18 2
            a 16 16 0 0 1 0 32
            a 16 16 0 0 1 0 -32"
            />

            <circle
              :cx="pointOnCircle(18, 18, 16, 270).x"
              :cy="pointOnCircle(18, 18, 16, 270).y"
              r="1.1"
              stroke-width="0"
              :fill="segment.color"
            />

            <circle
              :cx="pointOnCircle(18, 18, 16, segment.value * 3.6 - 90).x"
              :cy="pointOnCircle(18, 18, 16, segment.value * 3.6 - 90).y"
              r="1.1"
              stroke-width="0"
              :fill="segment.color"
            />
          </g>
        </svg>
      </div>
      <div class="progress__circle__abs">
        <slot v-if="type == 'CircleClicked'">
          <div
            v-if="!showVal"
            class="progress__circle__title"
          >{{selectedSegment ? selectedSegment.value : value}}{{numeric ? '' : '%'}}</div>
          <LoaderMini v-else-if="!callbackloader && showVal" />
          <div v-else class="progress__circle__description">{{description}}</div>
        </slot>
        <slot v-else-if="graphSegments.length == 2">
          <div
            class="progress__circle__title"
            :style="`color:${graphSegments[0].color == '#00000000' ? '#979797' : graphSegments[0].color}`"
          >
            <small>{{graphSegments[0].value}}{{numeric ? '' : '%'}}</small>
          </div>
          <div
            class="progress__circle__description text__overflow"
            :style="`color:${graphSegments[0].color == '#00000000' ? '#979797' : graphSegments[0].color}`"
          >{{graphSegments[0].label}}</div>
          <div
            class="progress__circle__description text__overflow mt__20"
            :style="`color:${graphSegments[1].color == '#00000000' ? '#979797' : graphSegments[1].color}`"
          >{{graphSegments[1].label}}</div>
          <div
            class="progress__circle__title"
            :style="`color:${graphSegments[1].color == '#00000000' ? '#979797' : graphSegments[1].color}`"
          >
            <small>{{graphSegments[1].value}}{{numeric ? '' : '%'}}</small>
          </div>
        </slot>
        <slot v-else>
          <div
            class="progress__circle__title"
          >{{selectedSegment ? selectedSegment.value : value}}{{numeric ? '' : '%'}}</div>
          <div
            class="progress__circle__description text__overflow2"
          >{{selectedSegment ? selectedSegment.label : description}}</div>
        </slot>
      </div>
    </div>
    <div class="progress__circle__legend mt__10" v-if="legends">
      <div class="flex__ss mt__10" v-for="(legend, index) in segmentsLegend" :key="index">
        <div class="legend__circle mt__5 mr__10" :style="`background: ${legend.color};`"></div>
        <div class="title__14 grey__151 lh__20">{{`${legend.label} (${legend.value}%)`}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      selectedSegment: null,
      callbackloader: true,
    };
  },
  props: {
    values: {
      default: () => {
        return [];
      },
    },
    type: "",
    value: 0,
    description: 0,
    showVal: false,
    name: 0,
    numeric: false,
    legends: false,
  },
  computed: {
    segments() {
      var result = [];
      var sum = 0;
      for (var v = 0; v < this.values.length; v++) {
        var vl = this.values[v];
        result.push({
          ...vl,
          angle: sum * 3.6,
        });
        sum += vl.value;
      }
      return result;
    },
    graphSegments() {
      if (false && this.segments.length == 2) {
        return [
          this.segments.sort((a, b) => {
            return b.value - a.value;
          })[0],
        ];
      } else {
        return this.segments;
      }
    },
    segmentsLegend() {
      return this.segments
        .filter((i) => {
          return i.value > 0;
        })
        .sort((a, b) => {
          return b.value - a.value;
        })
        .splice(0, 3);
    },
  },
  methods: {
    pointOnCircle(x, y, radius, angle) {
      return {
        x: x + radius * Math.cos(angle * (Math.PI / 180)),
        y: y + radius * Math.sin(angle * (Math.PI / 180)),
      };
    },
    checkCircle() {
      if (this.type == "CircleClicked") {
        this.callbackloader = false;
        this.showVal = !this.showVal;
        this.$bus.$emit("saveShowValue", this.name);
      }
    },
  },
  created() {
    this.$bus.$on("switchOfMiniLoader", (status) => {
      this.callbackloader = status;
    });
  },
};
</script>
<style lang="scss" scoped>
.circle__chart {
  display: block;
  &__bg {
    stroke: #e8edf2;
    stroke-width: 2.4px;
    fill: none;
  }
  &__value {
    stroke: $color-teal-blue;
    fill: none;
    stroke-width: 2.4px;
  }
}
$titleSize: 24px;
.progress__circle {
  position: relative;
  width: inherit;
  &__abs {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 75%;
    transform: translate(-50%, -50%);
  }
  &__title {
    font-weight: 700;
    font-size: $titleSize;
    line-height: 1;
    color: $color-black-75;
  }
  &__description {
    font-size: 14px;
    letter-spacing: -0.8px;
    color: $color-mid-grey;
    white-space: inherit;
    // text-transform: lowercase;
  }
  &__pointer {
    cursor: pointer;
  }
}
.legend__circle {
  width: 12px;
  min-width: 12px;
  height: 12px;
  border-radius: 50%;
}
</style>