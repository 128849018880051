<template>
  <div v-if="questItem && subscription">
    <div
      class="title__14 weight__500 grey__151 lh__20"
      v-if="!mobile"
    >{{questionInfo.title}} testing</div>
    <div class="mt__30">
      <div class="tooltip__right">
        <TooltipLinkCopy :questionInfo="questionInfo" />
        <Input
          :class="{'error':fillLink}"
          :params="{label: questionInfo.linkLabel, placeholder:questionInfo.linkPlaceholder, autofocus: !collectorStart, target: 'currentQuestItem.QuestItem.EyeTrackingWebSiteUrl', change: makeScreenShot, validate: 'link'}"
        />
      </div>
      <div v-if="$_project.TargetPlatform == 3">
        <!-- EDITOR -->
        <Editor
          :label='questionInfo.taskLabel'
          target='currentQuestItem.QuestItem.EyeTrackingWebSiteObjective'
          :placeholder='questionInfo.taskPlaceholder'>
        </Editor>
      </div>
      <div v-else>
        <Input
          :params="{label: questionInfo.taskLabel, placeholder: questionInfo.taskPlaceholder, target: 'currentQuestItem.QuestItem.EyeTrackingWebSiteObjective', textarea: 'true'}"
        />
      </div>
      <template v-if="question.QuestItem.QuestionType == 31">
        <Select
          :label="questionInfo.deviceLabel"
          :options="question.QuestItem.Devices"
          :params="{target: 'currentQuestItem.QuestItem.PrototypeDevice' }"
        />
        <div class="non__edit" v-if="$_project.TargetPlatform == 3">
          <Select
            :label="questionInfo.deviceOrientationLabel"
            :options="{'1' : 'Horizontal', '1' : 'Horizontal'}"
            :params="{target: 'currentQuestItem.QuestItem.PrototypePosition' }"
          />
        </div>

        <Select
          v-else
          :label="questionInfo.deviceOrientationLabel"
          :options="{'0' : 'Vertical', '1' : 'Horizontal'}"
          :params="{target: 'currentQuestItem.QuestItem.PrototypePosition' }"
        />
      </template>

      <div class="mt__40">{{questionInfo.sliderLabel}}</div>
      <BlockRange
        :rangeArray="['1 min', '2 min', '3 min']"
        :valuesArray="[60, 120, 180]"
        target="currentQuestItem.QuestItem.LimitTimeout"
        :disabled="collectorStart"
      />

      <div class="mt__40">Task page: show the button “Next” after</div>
      <BlockRange
        :rangeArray="['0 seconds', '5 seconds', '10 seconds']"
        :valuesArray="[0, 5, 10]"
        target="currentQuestItem.QuestItem.ShowNextButtonInSec"
        :disabled="collectorStart"
      />
    </div>
  </div>
</template>

<script>
import Net from "../../modules/net";
import FD from "../../modules/FData";
import config from "./questionTypes.json";
import CurrentProjectInfo from "@/mixins/CurrentProjectInfo";

export default {
  mixins: [CurrentProjectInfo],
  data() {
    return {
      mobile: this.ismobile,
      freeLimitMessage: false,
      fillLink: false,
      devicePlatform: "",
    };
  },
  props: ["question"],
  created() {
    if (this.$route.query.invalid) {
      this.fillLink = true;
    }

    this.$bus.$on("selectDevice", (data) => {
      this.devicePlatform = data;
    });
  },
  computed: {
    questItem() {
      return this.question != null;
    },
    questionInfo() {
      return config[this.question.QuestItem.QuestionType];
    },
    subscription() {
      return (
        this.$store.state.userInfo && this.$store.state.userInfo.Subscription
      );
    },
    collector() {
      return this.$store.state.currentCollector;
    },
    deviseList() {
      return this.$store.state.currentProject.Devices;
    },
    collectorStart() {
      return (
        this.$store.state.currentCollector &&
        this.$store.state.currentCollector.status !== 0
      );
    },
  },
  methods: {
    makeScreenShot(value) {
      // if (!value) {
      //   return;
      // }
      this.fillLink = false;
      Net.post(
        "/MakeSiteScreenshot.cmd",
        FD({
          siteUrl: value,
          pageWidth: 1600,
          questionId: this.$store.state.currentQuestItem.QuestItem.Id,
        })
      )
        .then((e) => {
          this.$store.commit("update", {
            target: this.question,
            field: "MediaFilePath",
            value: e.data.MediaFilePath,
          });

          this.$store.commit("update", {
            target: this.question,
            field: "PreviewFilePath",
            value: e.data.PreviewFilePath,
          });

          this.$store.commit("update", {
            target: this.$store.state.currentProject,
            field: "ReadyForStart",
            value: true,
          });
        })
        .catch((error) => {
          debugger;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
b {
  font-weight: 700;
}
</style>