<template>
  <div class="block mt__40">
    <div class="title__24 weight__700 mt__20 text__center">Project setup</div>
    <div class="title__16 text__center mt__10">Choose the type of methodology</div>
    <div class="block__2 mt__80">
      <!-- ux -->
      <div class="block__hover" style="width:320px;">
        <div @click="$_setProjectMethodology('/CreateMethodology.cmd');">
          <div class="image__cover">
            <img src="../../assets/img/new-project/UXmetodology.jpg" alt="UXReality methodology" />
          </div>
          <h2 class="title__up17 mt__20 text__center">
            UXReality
            <br />methodology
          </h2>
          <div
            class="title__12 weight__500 grey__151 mt__20 text__center"
          >Analyze engagement, usability and perception scores automatically with our ready-to-use methodology. Questions are already built in; results are well-ordered on a dashboard. Conduct multiple tests; benchmark your results across iterations of product or other projects. Get insights quickly and easily.</div>
          <div class="met-icon flex__bc mt__20 text__center">
            <div class="center">
              <img src="@/assets/img/icons/met-1.svg" alt="Methodology" />
              <div class="title__10 weight__500 grey__210">Methodology</div>
            </div>
            <div class="center">
              <img src="@/assets/img/icons/met-2.svg" alt="Benchmarks" />
              <div class="title__10 weight__500 grey__210">Benchmarks</div>
            </div>
            <div class="center">
              <img src="@/assets/img/icons/met-3.svg" alt="Built-in quiz" />
              <div class="title__10 weight__500 grey__210">Built-in quiz</div>
            </div>
          </div>
        </div>

        <div class="title__12 weight__500 grey__151 text__center mt__20">
          <router-link to="/methodology" class="blue link">Read about Methodlogy</router-link>
        </div>
      </div>
      <!-- own -->
      <div
        class="block__hover"
        @click="$_setProjectMethodology('/CreateProject.cmd');"
        style="width:320px;"
      >
        <div class="image__cover">
          <img src="../../assets/img/new-project/OWNmetodology.jpg" alt="own methodology" />
        </div>
        <h2 class="title__up17 mt__20 text__center">
          own
          <br />methodology
        </h2>
        <div
          class="title__12 weight__500 grey__151 mt__20 text__center"
        >Create your own methodology to ask testers specific questions. Analyze answers and recordings manually to get the most out of project analytics.</div>
        <div class="met-icon flex__bc mt__80 text__center">
          <div class="center">
            <img src="@/assets/img/icons/met-1-1.svg" alt="Custom questions" />
            <div class="title__10 weight__500 grey__210">Custom questions</div>
          </div>
        </div>
      </div>
    </div>
    <div class="text__center mt__40">
      <router-link to="/project-setup/step-2" class="btn__grey__full btn__200">Back</router-link>
    </div>
  </div>
</template> 

<script>
import CreateProject from "../../mixins/CreateProject";
export default {
  mixins: [CreateProject]
};
</script>

<style lang="scss" scoped>
.met-icon img {
  width: 40px;
  height: 40px;
}
</style>