<template>
  <div class="pseudo__input__wrap">
    <div class="input__row pseudo__row" v-for="(option, index) in params.options" :key="index">
      <IconMove />
      <span :class="params.type"></span>
      <Input
        ref="input"
        class="pseudo__row__input"
        :params="{label:'Option ' + (index + 1), target: 'currentQuestItem.Alternatives.' + index + '.Name'}"
      />
      <div
        class="icons__bg__grey input__icon"
        v-if="$store.state.currentQuestItem.Alternatives.length > 1"
        @click="deleteOption(option.Id)"
      >
        <IconBin />
      </div>
    </div>
    <div class="pseudo__row pseudo__row__add" v-if="params.addOption">
      <span :class="params.type"></span>
      <span class="add__option" @click="addOption">Add new option</span>
    </div>
  </div>
</template>

<script>
import AlternativeActions from "../../mixins/AlternativeActions";
import ReloadProxy from "../../mixins/ReloadProxy";

export default {
  props: {
    params: {
      default: function() {
        return {
          options,
          type: "checkbox",
          addOption: false
        };
      }
    }
  },
  mixins: [AlternativeActions, ReloadProxy]
};
</script>

<style lang="scss">
.pseudo__row {
  position: relative;
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  .icon-move {
    position: absolute;
    bottom: 6px;
    left: -24px;
    opacity: 0;
    display: none;
  }
  span.none + .pseudo__row__input.input__row {
    width: 100%;
  }
  label {
    font-size: inherit;
  }
  &__input {
    border-bottom-color: transparent;
    &.input__row {
      margin: 0;
      width: calc(100% - 16px - 8px);
      input {
        font-size: inherit;
        border-bottom-color: transparent;
        &:hover {
          border-bottom-color: $color-mid-grey;
        }
        &:focus {
          border-bottom-color: $color-teal-blue;
        }
      }
    }
  }
  .icons__bg__grey {
    opacity: 0;
  }

  &:focus,
  &:hover {
    .icon-move {
      opacity: 1;
    }
    input {
      border-bottom-color: $color-mid-grey;
    }
    .icons__bg__grey {
      opacity: 1;
    }
  }

  &__add {
    margin-top: 36px;
    .add__option {
      position: relative;
      @include transitionAll;
      cursor: pointer;
      &::after {
        @include pseudo;
        left: 0;
        bottom: -2px;
        width: 100%;
        height: 1px;
        background: $color-dark-grey;
        @include transitionAll;
      }
      &:hover {
        color: $color-teal-blue;
        &::after {
          background: $color-teal-blue;
        }
      }
    }
  }
}
</style>