<template>
  <div class="attention__block flex__ec" :class="{'active': active, 'pointer': click}" @click="() => { click && click() }">
    <IconAttention class="mr__20" />
    <div class="attention__block__text">{{message}}</div>
  </div>
</template>

<script>
export default {
  data() {
    return { active: false, message: "", click: null};
  },
  created() {
    var timeLimit;
    this.$bus.$on("attentionMessage", data => {
      this.message = data.text;
      this.click = data.click;
      window.clearTimeout(timeLimit);
      this.active = true;
      timeLimit = setTimeout(() => {
        this.active = false;
      }, 5000);
    });
  }
};
</script>

<style lang="scss" scoped>
.attention__block {
  position: fixed;
  z-index: 90;
  bottom: 50px;
  right: -100%;
  background: $color-white;
  box-shadow: $shadow;
  border: 1px solid transparent;
  border-radius: $border-radius 0 0 $border-radius;
  padding: 13px 20px;
  @include transitionAll;
  &__text {
    max-width: 180px;
    font-size: 12px;
    line-height: 20px;
  }
  &.active {
    right: 0;
  }
  &.pointer {
    cursor: pointer;
  }
}
</style>