<template>
  <div class="block__padding" v-if="selectOptions && $_collector">
    <div class="col__2">
      <div class="fake__timer flex__sc">
        <div
          class="fake__timer__title blue"
          v-html="'<span>' + $_collector.panelInfo.TimeToCollect + '</span>h<span> : </span><span>00</span>m<span> : </span><span>00</span>s'"
        ></div>
        <div class="title__14 weight__500 grey__151 lh__20 ml__11">
          estimated
          <br />time
        </div>
      </div>
      <div class="flex__ec" v-if="$_collector && $_collector.status == 0">
        <div class="title__20 weight__500 mr__20">Price:</div>
        <div class="title__28 weight__700">{{$_collector.panelInfo.Price}}$</div>
      </div>
    </div>
    <div class="title__18 weight__500 mt__40">
      <b>Sample Size</b>
    </div>
    <div class="title mt__5">Please select how many testers you need</div>
    <BlockRange
      :rangeArray="[10, 20, 50]"
      :value="{10: 0, 20: 1, 50: 2}[$_collector.SelectedNumberOfResponses]"
      :disabled="$_collector.status !== 0"
      @change="(plan) => { this.currentPlan = plan; }"
    />
    <div class="title__18 weight__500 mt__30">
      <b>Target Audience</b>
    </div>
    <div class="title mt__5">Set targeting criteria for your testers</div>
    <div :class="{non__edit: $_collector.status !== 0}">
      <div class="col__3">
        <div>
          <Select
            :label="'Country'"
            :options="countries"
            :params="{object: $_collector.panelInfo, field: 'SelectedCountry', useKey: true, useIndex: true }"
            @input="countrySelected"
          />
          <InputMultyRange
            style="margin-top: 48px;"
            :params="{multy:true, label: 'Age'}"
            :v1="$_collector.panelInfo.SelectedMinAge"
            :v2="$_collector.panelInfo.SelectedMaxAge"
            @change="ageChange"
          />
        </div>
        <div>
          <MultipleSelect
            :label="'Gender'"
            :options="selectOptions.genders"
            :params="{object: $_collector.panelInfo, field: 'SelectedGender' }"
          />
        </div>
        <div>
          <MultipleSelect
            :label="'Interests'"
            :options="selectOptions.categories"
            :params="{object: $_collector.panelInfo, field: 'SelectedCategory' }"
          />
        </div>
      </div>
    </div>
    <!-- Start buttons -->
    <div class="text__center mt__50" v-if="$_collector.status == 0">
      <div>
        Press the
        <b>Pay & Start Now</b> button to invite testers!
      </div>
      <div class="flex__cc mt__40">
        <div class="btn__grey btn__160 mr__40" @click="$_deleteCollector">Back</div>
        <div
          class="btn__green__full btn__160"
          @click="popupType = 'PopupPayRemote'; popup = !popup;"
        >Pay & Start now</div>
      </div>
    </div>
    <!-- end Start buttons -->
    <div class="text__center mt__50">
      <a
        class="link"
        href="https://uxreality.com/terms-of-use"
        target="_blank"
      >Service Terms and Conditions</a>
    </div>
    <Popup
      v-if="popup"
      :type="'PopupPayRemote'"
      :payAmount="$_collector.panelInfo.Price"
      :collector="$_collector"
      :close="onPay"
    />
  </div>
</template>

<script>
import Net from "../../modules/net";
import CurrentProjectInfo from "../../mixins/CurrentProjectInfo";
export default {
  mixins: [CurrentProjectInfo],
  data() {
    return {
      popup: false,
      selectOptions: null,
      currentPlan: -1
    };
  },
  watch: {
    currentPlan() {
      var data = this.selectOptions.availableSampleSizes[this.currentPlan];
      Net.update(
        ["TimeToCollect"],
        data.timeToCollect,
        this.$_collector.panelInfo
      );
      Net.update(["Price"], data.price, this.$_collector.panelInfo);
      Net.update(
        ["SelectedNumberOfResponses"],
        data.numberOfResponses,
        this.$_collector
      );
    }
  },
  created() {
    this.loadSelectOptions();
  },
  computed: {
    collector() {
      return this.$store.state.currentCollector;
    },

    countries() {
      return this.selectOptions.countries.map(e => {
        return e.name;
      });
    }
  },
  methods: {
    ageChange(values) {
      Net.update(["SelectedMinAge"], values[0], this.$_collector.panelInfo);
      Net.update(["SelectedMaxAge"], values[1], this.$_collector.panelInfo);
    },
    onPay(result) {
      this.popup = false;
      if (result == true) {
        this.$router.push({
          path:
            "/project/" +
            this.$_project.Id +
            this.$_collectorLink +
            "/analyze-results"
        });
      }
    },
    countrySelected(value) {},
    loadSelectOptions() {
      Net.post("/domain/GetPanelSettings.cmd")
        .then(e => {
          this.selectOptions = e.data;
        })
        .catch(error => {
          debugger;
        });
    }
  }
};
</script>

<style lang="scss">
.fake__timer {
  font-size: 20px;
  font-weight: 600;
  white-space: nowrap;
  &__title span {
    font-size: 42px;
    font-weight: 700;
    display: inline-block;
    &:nth-child(2n + 1) {
      width: 58px;
      text-align: right;
    }
    &:nth-child(2n) {
      width: 30px;
      text-align: center;
    }
  }
}
.age {
  &__block .flex__be .custom__select:last-child {
    margin-right: -10px;
  }
  &__label {
    margin: 0 30px 4px 20px;
  }
}
</style>