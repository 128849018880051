<template>
  <div class="block">
    <div class="title__24 weight__700 mt__20 text__center">Project setup</div>
    <TestTarget v-if="step == 0" @setProjectType="setProjectType" />
    <!-- <TargetDevices
      v-if="step == 1"
      :chosenProjectType="chosenProjectType"
      @setProjectTargetDevice="setProjectTargetDevice"
    />-->
    <Metodology v-if="step == 2" @setProjectMethodology="setProjectMethodology" />
    <ProjectSettings
      v-if="step == 3"
      :title="projectTitle"
      :defaultLang="projectLang"
      :defaultMethodology="projectMethodology"
      :description="projectDescription"
      @setProjectDescription="setProjectDescription"
      @setProjectTitle="setProjectTitle"
      @setProjectLang="setProjectLang"
    />
    <QuestionSettings v-if="project" :project="project" />
    <div :class="[step == 3 || step == 4 ? 'mt__80' : 'mt__40', 'text__center']">
      <button
        v-if="step == 0"
        class="btn__grey__full btn__200"
        @click="$emit('statusNewProject', false);"
      >Cancel</button>
      <button
        v-if="step !== 0"
        class="btn__grey__full btn__200"
        @click="back"
      >{{step == 4 ? 'Skip' : 'Back'}}</button>
      <button
        v-if="step !== 0 && step !== 1 && step !== 2"
        class="btn__green__full btn__200 ml__40"
        :disabled="step < 3 || (step == 3 && !projectTitle) || step == 4 && !questItem.EyeTrackingWebSiteUrl"
        @click="next"
      >{{step == 3 ? 'Create project' : step == 4 ? 'Finish' : 'Next'}}</button>
    </div>
  </div>
</template>
<script>
import Net from "../../modules/net";
import FD from "../../modules/FData";

export default {
  data() {
    return {
      step: 0,
      blockPrototype: false,
      chosenProjectType: null,
      chosenProjectMetodology: null,
      chosenProjectTargetDevice: 1,
      projectTitle: "",
      projectDescription: "",
      projectMethodology: "/CreateMethodology.cmd",
      projectLang: "en",
      project: null
    };
  },
  computed: {
    questItem() {
      return (
        this.project &&
        this.project.QuestData.Items.filter(e => {
          return [24, 30, 31].indexOf(e.QuestItem.QuestionType) != -1;
        })[0].QuestItem
      );
    }
  },
  methods: {
    back() {
      if (this.step == 4) {
        this.$router.push({
          path: "/project/" + this.project.Id + "/set-up-project"
        });
      } else {
        if (this.step == 2) {
          //del when add targetDevice
          this.step = 0;
        } else {
          this.step--;
        }
      }
    },
    next() {
      if (this.step == 3) {
        this.createNewProject();
      } else if (this.step == 4) {
        if (this.projectMethodology == "/CreateMethodology.cmd") {
          this.$router.push({
            path: "/project/" + this.project.Id + "/invite-testers/"
          }); 
        } else {
          this.$router.push({
            path: "/project/" + this.project.Id + "/set-up-project"
          });
        }
      }
    },
    setProjectType(type) {
      this.chosenProjectType = type;
      this.step = 2;
    },
    setProjectTargetDevice(type) {
      this.chosenProjectTargetDevice = type;
      this.step++;
      //this.createNewProject();
    },
    setProjectDescription(value) {
      this.projectDescription = value;
    },
    setProjectTitle(value) {
      this.projectTitle = value;
    },
    setProjectMethodology(value) {
      this.projectMethodology = value;
      this.step++;
    },
    setProjectLang(value) {
      this.projectLang = value;
    },
    createNewProject() {
      this.$store.commit("loaderAction", true);
      Net.post(
        this.projectMethodology,
        FD({
          projectType: 4,
          projectName: this.projectTitle,
          projectDesc: this.projectDescription,
          questionType: this.chosenProjectType,
          targetPlatform: this.chosenProjectTargetDevice,
          deviceType: this.chosenProjectTargetDevice, //это нужно для прототипа, дубляж
          language: this.projectLang
        })
      )
        .then(e => {
          this.$store.commit("addProject", e.data.ProjectData);
          this.project = e.data.ProjectData;
          // if (this.projectMethodology == "/CreateMethodology.cmd") {
          this.step++;
          // } else {
          // this.$router.push({
          // path: "/project/" + e.data.project + "/set-up-project"
          // });
          // }

          this.$store.commit("loaderAction", false);
        })
        .catch(error => {
          debugger;
        });
    }
  }
};
</script>