<template>
  <div
    :class="['project__preview', {no__select: dragTargetItem}]"
    ref="projectPreview"
    @mousemove="containerMouseMove"
    @mouseup="containerMouseUp"
    @mouseleave="containerMouseLeave"
  >
    <template v-for="(questItem, index) in (reorderedArray || questItems)">
      <div
        v-if="canAddQuest"
        :class="['block__hover flex__sc', 
        {selected: selectedQuestion == questItem, 
        drag__item: questItem == dragTargetItem, 
        can__drag: !collectorStart && !canAddQuest.CreatedFromMethodology}]"
        @mouseup="() => { itemMouseUp(questItem)}"
        @mousedown="() => { if(!collectorStart) {setClickTarget(questItem)}}"
        @mousemove="(e) => {if(!collectorStart && !canAddQuest.CreatedFromMethodology) {clickTargetItem && setDragItem(questItem, e) }}"
        :key="index"
      >
        <!-- preview  -->
        <div
          :class="['icon__quest', {'icon__quest__hover': !canAddQuest.CreatedFromMethodology && questItem.QuestItem.QuestionType !== 24 && questItem.QuestItem.QuestionType !== 30 && questItem.QuestItem.QuestionType !== 31 && !questItem.QuestItem.ReadOnly && !collectorStart}]"
        >
          <img
            v-if="questItem.QuestItem.QuestionType == 24 || questItem.QuestItem.QuestionType == 30 || questItem.QuestItem.QuestionType == 31"
            src="@/assets/img/icons/IconFlag.svg"
            alt
          />
          <img v-else src="@/assets/img/icons/IconQuestion.svg" alt />

          <!-- delete  -->
          <div
            class="icon__quest__del"
            v-if="canAddQuest && !canAddQuest.CreatedFromMethodology 
            && questItem.QuestItem.QuestionType !== 24 
            && questItem.QuestItem.QuestionType !== 30 
            && questItem.QuestItem.QuestionType !== 31 
            && !questItem.QuestItem.ReadOnly
            && !collectorStart"
            @click="deleteQuest(questItem.QuestItem.Id)"
            @mousedown.stop
            @mouseup.stop
            @mousemove.stop
            title="Delete Question"
          >
            <IconBin />
          </div>
        </div>
        <!-- title  -->
        <div class="quest__maxW">
          <div class="title__16 text__overflow2">
            <template v-if="questItem.QuestItem.Name">{{questItem.QuestItem.Name}}</template>
            <template
              v-else-if="questItem.QuestItem.QuestionType == 24 || questItem.QuestItem.QuestionType == 30 || questItem.QuestItem.QuestionType == 31"
            >Test name</template>
            <template v-else>Question name</template>
          </div>
          <div class="title__12 grey__210 mt__10">
            <template
              v-if="questItem.QuestItem.QuestionType == 24 || questItem.QuestItem.QuestionType == 30 || questItem.QuestItem.QuestionType == 31"
            >{{getQuestionInfo(questItem).title}} testing</template>
            <template
              v-else
            >q{{ nonNeuroQuestItems.indexOf(questItem) + 1 }}. {{getQuestionInfo(questItem).title}}</template>
          </div>
        </div>
      </div>
    </template>
    <div
      v-if="!collectorStart && canAddQuest && !canAddQuest.CreatedFromMethodology"
      :class="[
        'mr__20 project__preview__add block__hover flex__sc', 
        {selected: addAfter == questItems[questItems.length - 1]}, 
        ]"
      @click="addQuest(questItems[questItems.length - 1])"
    >
      <Plus class="mr__20" />
      <div class="title__16">Add question</div>
    </div>
  </div>
</template>

<script>
import config from "./questionTypes.json";
import Net from "../../modules/net";
import FD from "../../modules/FData";

export default {
  data() {
    return {
      mobile: this.ismobile,
      addAfter: null,
      qindex: 0,
      clickTargetItem: false,
      dragTargetItem: false,
      reorderedArray: false,
      scroll: false
    };
  },
  props: ["selectedQuestion"],
  computed: {
    questItems() {
      return this.$store.state.currentQuest;
    },
    nonNeuroQuestItems() {
      return this.questItems.filter(e => {
        return [24, 30, 31].indexOf(e.QuestItem.QuestionType) == -1;
      });
    },
    collectorStart() {
      return (
        this.$store.state.currentCollector &&
        this.$store.state.currentCollector.status == 1
      );
    },
    canAddQuest() {
      return this.$store.state.currentProject;
    }
  },
  methods: {
    selectQuestion(index) {
      this.$parent.$emit("selectQuestion", index);
    },
    addQuest(after) {
      this.addAfter = after;
      this.$parent.$emit("addQuest", after);
    },
    deleteQuest(index) {
      this.$parent.$emit("deleteQuest", index);
    },
    getQuestionInfo(qItem) {
      return config[qItem.QuestItem.QuestionType];
    },
    // Drag&Drop
    containerMouseMove(e) {
      if (!e && this.lastEvent) {
        e = this.lastEvent;
      }
      this.lastEvent = {
        y: e.y,
        currentTarget: e.currentTarget
      };

      if (this.dragTargetItem) {
        var originalElHeight = this.dragItemClone.offsetHeight;
        var elHeight =
          e.currentTarget.scrollHeight / (this.reorderedArray.length + 1);
        var topPosition =
          e.y -
          e.currentTarget.offsetTop -
          e.currentTarget.offsetParent.offsetTop +
          e.currentTarget.scrollTop +
          window.scrollY;
        var newIndex = (topPosition / elHeight) ^ 0;
        if (this.reorderedArray.indexOf(this.dragTargetItem) !== newIndex) {
          this.reorderedArray.splice(
            newIndex,
            0,
            this.reorderedArray
              .splice(this.reorderedArray.indexOf(this.dragTargetItem), 1)
              .pop()
          );
        }
        var minimalTop = elHeight - originalElHeight;
        var maximalTop = e.currentTarget.clientHeight - originalElHeight;
        var elementTopPosition = Math.min(
          maximalTop,
          Math.max(
            topPosition - originalElHeight / 2 - e.currentTarget.scrollTop,
            minimalTop
          )
        );
        if (elementTopPosition - minimalTop < 20) {
          this.scroll = -15;
        } else if (maximalTop - elementTopPosition < 20) {
          this.scroll = 15;
        } else {
          this.scroll = false;
        }

        if (this.scroll !== false && !this.stm) {
          this.stm = window.setInterval(() => {
            if (!this.scroll) {
              window.clearInterval(this.stm);
              this.stm = false;
              return;
            }
            this.$refs.projectPreview.scrollTop += this.scroll;
            this.containerMouseMove();
          }, 50);
        }
        this.dragItemClone.style.top = elementTopPosition + "px";
      }
    },
    resetSelection() {
      if (window.getSelection) {
        if (window.getSelection().empty) {
          // Chrome
          window.getSelection().empty();
        } else if (window.getSelection().removeAllRanges) {
          // Firefox
          window.getSelection().removeAllRanges();
        }
      } else if (document.selection) {
        // IE?
        document.selection.empty();
      }
    },
    containerMouseUp() {
      this.resetSelection();
      if (this.dragItemClone && this.dragTargetItem) {
        this.dragItemClone.parentElement.removeChild(this.dragItemClone);
        var newIndex = this.reorderedArray.indexOf(this.dragTargetItem);
        Net.post(
          "/UpdateQuestItemsOrder.cmd",
          FD({
            qid: this.dragTargetItem.QuestItem.Id,
            after:
              newIndex == 0
                ? newIndex
                : this.reorderedArray[newIndex - 1].QuestItem.Id
          })
        );

        this.$store.commit("reorderQuestions", {
          item: this.dragTargetItem,
          index: newIndex
        });
        this.scroll = false;
        this.reorderedArray = false;
        this.dragItemClone = false;
        this.dragTargetItem = false;
        this.clickTargetItem = false;
      }
    },
    containerMouseLeave() {
      if (this.dragItemClone) {
        this.dragItemClone.parentElement.removeChild(this.dragItemClone);
        this.reorderedArray = false;
        this.dragItemClone = false;
        this.dragTargetItem = false;
        this.clickTargetItem = false;
        this.scroll = false;
      }
    },
    itemMouseUp(item) {
      if (!this.dragTargetItem) {
        this.selectQuestion(item);
        this.addAfter = null;
        this.clickTargetItem = false;
      }
    },
    setClickTarget(item) {
      this.clickTargetItem = item;
    },
    setDragItem(item, event) {
      this.resetSelection();
      if (!this.dragTargetItem && !this.dragItemClone) {
        this.reorderedArray = [];
        for (var el = 0; el < this.questItems.length; el++) {
          this.reorderedArray.push(this.questItems[el]);
        }
        this.dragTargetItem = item;
        this.dragItemClone = event.currentTarget.cloneNode(true);
        this.$refs.projectPreview.appendChild(this.dragItemClone);
        this.dragItemClone.classList.add("question__list__drag__clone");
      }
    }
    // end Drag&Drop
  }
};
</script>

<style lang="scss">
.project__preview {
  @include for-mobile-up {
    max-height: $blockMinHeight + $shadow-width;
    overflow-y: auto;
    margin: -#{$shadow-width/2} -$shadow-width;
    padding: $shadow-width/2 40-$shadow-width $shadow-width/2 $shadow-width/2;
  }
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    background: transparent;
  }
  .block,
  .block__hover {
    position: relative;
    padding: 11px 20px;
    height: 85px;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    &.can__drag {
      cursor: grab;
    }

    .quest__maxW {
      width: calc(100% - 40px - 20px);
    }
    .icon__quest {
      width: 40px;
      height: 40px;
      border: 1px solid $color-teal-blue;
      border-radius: 50%;
      background: $color-teal-blue;
      padding: 10px;
      margin-right: 20px;
      &__hover {
        .icon__quest__del {
          opacity: 0;
          width: inherit;
          height: inherit;
          position: absolute;
          top: 22px;
          left: 20px;
          padding: 10px;
          z-index: -1;
          #icon-bin path {
            fill: $color-white;
          }
        }
      }
    }

    &:hover {
      box-shadow: none;
      .icon__quest__hover {
        @include transitionAll;
        & > img {
          opacity: 0;
        }
        .icon__quest__del {
          opacity: 1;
          z-index: 5;
        }
        &:hover {
          background: $color-red;
          border-color: $color-red;
          border-radius: 50%;
          cursor: pointer;
        }
      }
    }
  }
  .selected {
    border-color: $color-teal-blue;
  }

  &.no__select > * {
    user-select: none;
    transition: none;
  }
  .drag__item {
    opacity: 0.5;
    filter: grayscale(1);
    background: $color-custom-grey;
    border: 1px dashed $color-mid-grey;
    > * {
      opacity: 0;
    }
    .icon__quest__del {
      display: none;
    }
  }

  .question__list__drag__clone {
    position: absolute;
    max-width: 320px;
    transform: translate(20px, -20px);
    &.can__drag {
      cursor: grabbing;
    }
    .icon__quest__del {
      display: none;
    }
  }
}
</style>