var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block mt__40"},[_c('div',{staticClass:"title__24 weight__700 mt__20 text__center"},[_vm._v("Project setup")]),_c('div',{staticClass:"block__maxw mt__80 center"},[_c('div',{staticClass:"title__16 grey__151 mt__80 text__center"},[_vm._v(" Name and describe the project to make it unique ")]),_c('Input',{staticClass:"mt__0",attrs:{"params":{ value: _vm._projectTitle, label: 'Name*' }},on:{"input":(value) => {
          this.$_setProjectTitle(value);
        }}}),_c('Input',{attrs:{"params":{ value: _vm._projectDescription, label: 'Description' }},on:{"input":(value) => {
          this.$_setProjectDescription(value);
        }}}),_c('div',[_c('div',{staticClass:"title__16 grey__151 mt__80 text__center"},[_vm._v(" Choose the language of this project ")]),_c('Select',{staticStyle:{"margin-top":"10px"},attrs:{"label":"Language","options":{
          ar: 'Arabic',
          cn: 'Chinese',
          en: 'English',
          fr: 'French',
          ge: 'German',
          hk: 'Cantonese',
          id: 'Indonesian',
          it: 'Italian',
          ru: 'Russian',
          sp: 'Spanish',
          th: 'Thai',
          vi: 'Vietnamese'
        },"params":{ valueField: _vm._projectLang }},on:{"input":(value) => {
            this.$_setProjectLang(value);
          }}})],1)],1),_c('div',{staticClass:"flex__cc text__center mt__40"},[_c('router-link',{staticClass:"btn__grey__full btn__200 mr__40",attrs:{"to":"/project-setup/step-3"}},[_vm._v("Back")]),_c('button',{staticClass:"btn__green__full btn__200 ml__40",attrs:{"disabled":!_vm.tempProjectTitle},on:{"click":function($event){return _vm.$_createNewProject()}}},[_vm._v(" Create project ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }