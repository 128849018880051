<template>
  <div class="payment" v-if="subscription || appCall">
    <div v-if="!successPay" class="block flex__subscription mt__20">
      <div class="flex__sc">
        <div>
          <img src="@/assets/img/subscription.svg" alt />
        </div>
        <Subscription />
      </div>
      <div
        v-if="subscription.DowngradeRequested"
        class="title__14 weight__500 grey__151 lh__20 flex__subscription__starter"
      >
        You'll be switched to
        <br />
        <b>'PAY AS YOU GO' </b>plan
      </div>
    </div>

    <!-- available subscriptions -->
    <div class="flex__bc mob__rev">
      <h1 class="title__line__green" :class="mobile ? 'mt__20' : 'mt__40'">
        Available subscriptions
      </h1>
      <router-link
        v-if="!successPay"
        class="btn__grey btn__120"
        :class="mobile ? 'mt__40' : 'mt__10'"
        to="/account"
        >Back</router-link
      >
    </div>
    <div class="block subscription">
      <template v-if="!successPay">
        <div class="title__24 weight__700 mt__20 text__center">
          Subscription
        </div>
        <div class="title__16 mt__10 text__center">
          Choose the type of subscription that suits your needs.
        </div>
      </template>
      <template v-else>
        <div class="title__24 weight__700 mt__20 text__center">
          Your subscription plan updated
        </div>
        <div class="title__16 mt__10 text__center">
          Your payment is processing.
          <template v-if="promo.local">
            Now you can fully use our platform with all its functions with
            <b>1 extra month for FREE.</b>
          </template>
        </div>
      </template>
      <!-- PROMO -->
      <!-- <div v-if="promo.show && !successPay">
        <div class="flex__ce center promo" style="max-width:500px;">
          <div
            :class="['input__row mt__70 mr__40', {'error': promo.error, 'valid': promo.valid}]"
            style="width:100%;"
          >
            <input
              type="text"
              :class="{ 'focused': promo.code}"
              id="promo"
              ref="promoRef"
              v-model="promo.code"
            />
            <label for="promo">
              <template v-if="promo.error">{{ promo.error }}</template>
              <template v-else>Promo code</template>
            </label>
            <IconPromo class="input__icon" />
          </div>
          <div
            class="btn__green__full btn__120 mt__70"
            @click="checkPromo"
          >Apply</div> -->
      <!-- :disabled="!promo.code || promo.await" -->
      <!--</div>
        <div v-if="promo.valid" class="title__16 mt__20 text__center">
          The promo code has been applied. Purchase PRO subscription till May 29 and get
          <b>1 extra month for FREE.</b>
        </div>
      </div> -->
      <!-- end PROMO -->
      <div :class="['block__3', 'mt__100 center']" style="max-width: 860px">
        <!-- STARTER -->
        <div
          v-if="
            !successPay ||
            (successPay && subscription && subscription.Name == 'Pay as you go')
          "
          :class="
            subscription && subscription.Name == 'Pay as you go'
              ? 'block'
              : 'block__hover'
          "
          @click="popup = subscription.Name == 'Pay as you go' ? false : true"
        >
          <div class="image__cover">
            <img src="@/assets/img/payment/starter.jpg" alt="Pay as you go" />
            <IconCurrentSubscription
              v-if="subscription && subscription.Name == 'Pay as you go'"
            />
          </div>

          <h2 class="title__up17 mt__20 text__center">
            PAY AS YOU GO
            <br />
            <span v-if="!successPay">($0/month)</span>
            <span v-else>(Current)</span>
          </h2>
          <div class="title__12 weight__500 grey__151 mt__20">
            <ul>
              <li>Unlimited projects</li>
              <li>Built-in audience only*</li>
              <li>Built-in methodologies only</li>
              <li>Unmoderated tests only</li>
            </ul>
          </div>
        </div>
        <!-- end STARTER -->
        <!-- PRO -->
        <!-- <div
          v-if="
            !successPay ||
            (successPay && subscription && subscription.Name == 'Pro')
          "
          :class="
            subscription && subscription.Name == 'Pro'
              ? 'block'
              : 'block__hover'
          "
          @click="
            () => {
              if (successPay) {
                gonext();
              } else {
                if (subscription && subscription.Name != 'Pro') {
                  $router.push('/payment-methods/pro');
                }
              }
            }
          "
        >
          <div class="image__cover">
            <img src="@/assets/img/payment/pro.jpg" alt="pro" />
            <IconCurrentSubscription
              v-if="subscription && subscription.Name == 'Pro'"
            />
          </div>

          <h2 class="title__up17 mt__20 text__center">
            PRO
            <br />
            <span v-if="!successPay">($249/month)</span>
            <span v-else>(Current)</span>
          </h2>
          <div class="title__12 weight__500 grey__151 mt__20">
            <ul>
              <li>Unlimited projects</li>
              <li>Projects with built-in audience*</li>
              <li>Projects with own audience</li>
              <li>Built-in & own methodologies</li>
            </ul>
          </div>
          <div v-if="successPay" class="btn__green__full btn__max mt__30">
            Continue
          </div>
        </div> -->
        <!-- end PRO -->
        <!-- ENTERPRISE -->
        <router-link
          class="block__hover"
          to="/contact"
          v-if="
            !successPay ||
            (successPay && subscription && subscription.Name == 'Enterprise')
          "
        >
          <div class="image__cover">
            <img src="@/assets/img/payment/enterprise.jpg" alt="enterprise" />
            <IconCurrentSubscription
              v-if="subscription && subscription.Name == 'Enterprise'"
            />
          </div>
          <h2 class="title__up17 mt__20 text__center">
            Enterprise
            <br />
            <span v-if="subscription && subscription.Name == 'Enterprise'"
              >(Current)</span
            >
            <span v-else>(On demand)</span>
          </h2>
          <div class="title__12 weight__500 grey__151 mt__20">
            <ul>
              <li>All PRO package features</li>
              <li>Custom recordings time</li>
              <li>Team access</li>
              <li>Shareable projects</li>
            </ul>
          </div>
        </router-link>
        <!-- end ENTERPRISE -->
      </div>
      <div class="title__12 text__center mt__40" v-if="!successPay">
        <b>*Price per project for built-in audience:</b>
        <div class="mt__10">
          10 respondents = $400 (delivery up to 24 hours)
          <span class="mr__10 ml__11">|</span> 20 respondents = $800 (delivery
          up to 48 hours) <span class="mr__10 ml__11">|</span> 50 respondents =
          $1500 (delivery up to 48 hours)
        </div>
      </div>
    </div>
    <Popup
      v-if="popup"
      :type="popupType"
      :close="
        () => {
          popup = false;
        }
      "
    />
  </div>
</template>

<script>
import Net from "../../modules/net";

export default {
  data() {
    return {
      mobile: this.ismobile,
      popup: false,
      popupType: "downToStarter",
      successPay: false,
      // promo: {
      //   show: true,
      //   local: localStorage.getItem("promo_code"),
      //   code: null,
      //   valid: null,
      //   error: null,
      //   await: false,
      // },
    };
  },
  created() {
    if (this.$route.params.subscription == "success") {
      this.successPay = true;
    }
  },
  computed: {
    appCall() {
      return this.$route.query.token;
    },
    subscription() {
      return (
        this.$store.state.userInfo && this.$store.state.userInfo.Subscription
      );
    },
  },
  methods: {
    gonext() {
      if (window.wtSendPaymentStatus) {
        window.wtSendPaymentStatus(true);
      } else {
        if (localStorage.getItem("backlink_collector")) {
          this.$router.push({
            path: localStorage.getItem("backlink_collector"),
          });
          localStorage.removeItem("backlink_collector");
        } else {
          this.$router.push({ path: "/payments" });
        }
      }
      this.successPay = false;
      // localStorage.removeItem("promo_code");
    },
    // checkPromo() {
    //   this.promo.await = true;
    //   Net.post(
    //     "/CheckPromoCode.cmd",
    //     JSON.stringify({
    //       promocode: this.promo.code,
    //     }),
    //     {
    //       "Content-Type": "application/json",
    //     }
    //   )
    //     .then((e) => {
    //       if (e.data.res == 1) {
    //         this.promo.error = null;
    //         this.promo.valid = true;
    //         localStorage.setItem("promo_code", this.promo.code);
    //       } else {
    //         this.promo.valid = null;
    //         this.promo.error = e.data.errormessage;
    //       }
    //       this.promo.await = false;
    //     })
    //     .catch((error) => {
    //       debugger;
    //     });
    // },
  },
};
</script>

<style lang="scss">
.subscription {
  ul {
    list-style-image: url("../../assets/img/icons/check.svg");
    padding-left: 10px;
    letter-spacing: -0.2px;
  }
}
</style> 