<template>
  <div class="range__block__wrap flex__sc mt__10">
    <div
      v-for="(rangeBlock, index) in rangeArray"
      :class="['range__block', {'locked': locked >= index, 'active': selected >= index, 'active__main': selected == index}, {'disable': disabled}]"
      :key="index"
      @click="selectedBlock(index)"
    >
      <div class="range__block__line"></div>
      <div class="range__block__title title__16 weight__700 mt__15">{{rangeBlock}}</div>
    </div>
  </div>
</template>
<script>
import Net from "../../modules/net";

export default {
  data() {
    return {
      selected: 0
    };
  },
  props: [
    "rangeArray",
    "valuesArray",
    "value",
    "disabled",
    "target",
    "locked",
    "object",
    "field",
    "change"
  ],
  created() {
    if (this.target) {
      this.path = this.target.split(".");
      var element = this.$store.state;
      for (var p = 0; p < this.path.length; p++) {
        element = element[this.path[p]];
      }
      this.selected = this.valuesArray
        ? this.valuesArray.indexOf(element)
        : element;
    } else if (this.object && this.field) {
      this.selected = this.valuesArray
        ? this.valuesArray.indexOf(this.object[this.field])
        : this.object[this.field];
    } else {
      this.selected = this.value
        ? this.valuesArray
          ? this.valuesArray[this.value]
          : this.value
        : 0;
    }
  },
  methods: {
    selectedBlock(selected) {
      if (this.locked !== undefined && selected <= this.locked) {
        return;
      }

      this.selected = selected;
      this.$emit(
        "change",
        this.valuesArray ? this.valuesArray[selected] : selected
      );
      if (this.path) {
        Net.update(
          this.path,
          this.valuesArray ? this.valuesArray[selected] : selected
        );
      } else if (this.object && this.field) {
        Net.update(
          [this.field],
          this.valuesArray ? this.valuesArray[selected] : selected,
          this.object
        );
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.range__block {
  padding-top: 10px;
  flex: 0 1 330px;
  &__wrap {
    position: relative;
  }
  &__line {
    position: relative;
    height: 4px;
    border-radius: $border-radius;
    background: $color-grey-210;
    @include transitionAll;
  }
  &__title { 
    text-align: center;
    color: $color-grey-210;
    @include transitionAll;
  }
  &:not(:last-child) {
    margin-right: 10px;
  }
  &:hover:not(.locked) {
    cursor: pointer;
    .range__block__title {
      color: $color-teal-blue;
    }
  }
  // ----------- Active ----------- //
  &.active {
    .range__block__line {
      background: $color-teal-blue;
    }
    &__main {
      cursor: default;
      .range__block__line {
        background: $color-teal-blue;
      }
      .range__block__line {
        &::after {
          @include pseudo;
          $triangleSize: 10px;
          bottom: -$triangleSize;
          left: calc(50% - #{$triangleSize});
          width: 0;
          height: 0;
          border-left: $triangleSize solid transparent;
          border-right: $triangleSize solid transparent;
          border-top: $triangleSize solid $color-teal-blue;
        }
      }
      .range__block__title {
        color: $color-teal-blue;
      }
    }
  }

  // ----------- Locked ----------- //
  &.locked {
    .range__block__line {
    background: $color-blue;
      &::after {
        border-top-color: $color-blue;
      }
    }
    .range__block__title {
      color: $color-blue;
    }
  }
  &.disable {
    pointer-events: none;
  }
}
.trial__version {
  .range__block:not(:nth-child(1)):not(:nth-child(2)) {
    opacity: .5;
    pointer-events: none;
  }
}
</style>