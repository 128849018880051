<template>
  <div class="popup__pay">
    <div class="popup__title">Pay & Start</div>
    <div v-if="card.length">
      <div class="popup__description">Your default card will be charged</div>
      <div class="title__24 weight__700 mt__10 text__center red"><b>$ {{payAmount}}</b></div>
      <CardPlastic class="mt__20 center" />
    </div> 
    <div v-else>
      <div class="popup__description">You have not added any cards yet</div>
      <Card :cancelBtn="false" />
    </div>
    <div v-if="textError.length" class="red text__center mt__30">
      {{textError}}
    </div>
    <div class="popup__btns flex__cc" v-if="card.length">
      <div class="btn__black" @click="startCollector">Pay & Start</div>
      <div class="btn__grey mt__30" @click="close">Cancel</div>
    </div>
  </div>
</template>

<script>
import FD from "../../modules/FData";
import Net from "../../modules/net";

export default {
  data() {
    return {
      textError: ""
    };
  },
  props: ["close", "payAmount", "collector"],
  computed: {
    card() {
      return this.$store.state.userInfo.Account.Cards;
    }
  },
  methods: {
    startCollector() {
      this.$store.commit("loaderAction", true);
      Net.post(
        "/ChangeCollectorStatusCommand.cmd",
        FD({
          collector: this.collector.Id,
          status: 1
        })
      ).then(res => {
        if (res.data.res == 0) {
          this.$store.commit("loaderAction", false);
          this.textError = res.data.errormessage;
        } else {
          this.$store.commit("update", {
            target: this.collector,
            field: "status",
            value: 6
          });
          
          this.$store.commit("update", {
            target: this.collector,
            field: "FinishDate",
            value: Date.now() + this.collector.panelInfo.TimeToCollect * 60 * 60 * 1000
          });

          this.close(true);
          this.$store.commit("loaderAction", false);
        }
      });
    }
  }
};
</script>

<style lang="scss">
.popup {
  .card-form {
    .card-list.mr__60 {
      margin-right: 20px;
    }
    .card-form__inner {
      min-width: 300px;
    }
  }
  &__pay {
    .btn__black {
      padding: 11px 18px;
    }
  }
}
</style>