<template>
  <div
    v-if="$_collector"
    :class="[{'collected__gag__relative block__padding': $_collector && $_collector.type == 24 && [6, 1, 2].indexOf($_collector.status) != -1 && $_collector.Viewed == false}]"
  >
    <DataCollected
      v-if="$_collector.type == 24 && [6, 1, 2].indexOf($_collector.status) != -1 && $_collector.Viewed == false"
      :collected="$_collector.status == 2"
      @closeOverlay="closeOverlay"
    />
    <div class="tab">
      <!-- TOP nav  -->
      <div class="flex__sstr tab__3" style="height:86px;">
        <div
          :class="['tab__nav blue', {tab__nav__active: pageAnalyze == 1}]"
          @click="pageAnalyze = 1"
        >
          <div class="flex__bs">
            <div>
              <div class="title__16 weight__700 grey__121b">Overall analytics</div>
              <!-- <div class="title__12 weight__500 grey__151 mt__5">General data for the entire project</div> -->
            </div>
            <div class="title__32 weight__700"></div>
          </div>
        </div>
        <!-- scenarios.AllScenaries.length !== 0  -->
        <div
          v-if="viewScen && scenarios && scenarios.AllScenaries"
          :class="['tab__nav green', {tab__nav__active: pageAnalyze == 2}]"
          @click="pageAnalyze = 2"
        >
          <div class="flex__bs">
            <div>
              <div class="title__16 weight__700 grey__121b">User scenarios</div>
              <!-- <div class="title__12 weight__500 grey__151 mt__5">Routes by which users were able to achieve the goal</div> -->
            </div>
            <!-- <div class="title__32 weight__700">{{scenarios.AllScenaries.length}}</div> -->
          </div>
        </div>

        <div
          :class="['tab__nav orange', {tab__nav__active: pageAnalyze == 3}]"
          @click="pageAnalyze = 3"
        >
          <div class="flex__bs"> 
            <div>
              <div class="title__16 weight__700 grey__121b">Individual recordings</div>
              <!-- <div class="title__12 weight__500 grey__151 mt__5">Individual screen recordings of all respondents</div> -->
            </div>
            <div class="title__32 weight__700" v-if="pageAnalyze == 3">
              <!-- {{recordings.length}} -->
              </div>
          </div>
        </div>
      </div>
      <!-- end TOP nav  -->

      <!-- OverallAnalytics  -->
      <OverallAnalytics v-if="report" v-show="pageAnalyze == 1" :report="report" />
      <!-- end OverallAnalytics -->

      <!-- UserScenarios  -->
      <UserScenarios
        v-if="viewScen && scenarios && scenarios.AllScenaries"
        v-show="pageAnalyze == 2"
        :scenarios="scenarios"
      />
      <!-- end UserScenarios  -->
      <!-- Recordings -->
      <div class="tab__content" v-show="pageAnalyze == 3">
        <LoaderMini v-if="miniLoader" class="mt__20" />
        <Recordings :recordings="recordings" />
      </div>
      <!-- end Recordings -->
    </div>
  </div>
</template>

<script>
import Net from "../../modules/net";
import FD from "../../modules/FData";
import CurrentProjectInfo from "../../mixins/CurrentProjectInfo";
import bus from '../../modules/eventBus';

export default {
  mixins: [CurrentProjectInfo],
  data() {
    return {
      mobile: this.ismobile,
      miniLoader: false,
      pageAnalyze: 1,
      report: null,
      scenarios: null,
      recordings: false,
      viewScen: false,
      loopCreated: false
    };
  },
  computed: {
    showOverlay() {
      if (this.$_collector) {
        if (this.$_collector.type == 24) {
          if (this.$_collector.status == 6 || this.$_collector.status == 1) {
            return true;
          }
          if (this.$_collector.status == 2 && !this.$_collector.Viewed) {
            return true;
          }
        }
      }
      return false;
    }
  },
  watch: {
    $_collector() {
      if (this.$_collector.completes > 0) {
        this.loadReport();
        this.loadRecordings();
        this.recordings = false;
        if (this.$_project.MainQuestionType == 31) {
          this.loadScenarios();
        }
      }
    }
  },
  created() {
    bus.$on('currentProjectUpdated', this.onProjectUpdated)
    if (this.$_collector && this.$_collector.completes > 0) {
      this.loadReport();
      this.loadRecordings();
      this.recordings = false;
      if (this.$_project.MainQuestionType == 31) {
        this.loadScenarios();
      }
    }
  },
  destroyed(){
    bus.$off('currentProjectUpdated', this.onProjectUpdated)
    clearInterval(this.loopInterval);
  },
  methods: {
    onProjectUpdated(){
      this.loadRecordings(false);
      this.loadReport(false);
    },
    closeOverlay(value) {
      Net.update(["Viewed"], value, this.$_collector);
    },
    loadReport( showLoading ) {
      if(showLoading !== false){
        this.$store.commit("loaderAction", true);
      }

      Net.post(
        "/GetSummary.cmd",
        FD({
          project: this.$store.state.currentProject.Id
        })
      ).then(e => {
        this.report = e.data;
        this.$store.commit("loaderAction", false);
      });
    },
    loadScenarios() {
      this.$store.commit("loaderAction", true);
      Net.post(
        "/GetPrototypeProjectScenaries.cmd",
        FD({
          project: this.$store.state.currentProject.Id
          // project: 239628486
        })
      ).then(e => {
        this.scenarios = e.data;
        this.$store.commit("loaderAction", false);
      });
    },
    loadRecordings( showLoading ) {
      if(showLoading !== false){
        this.miniLoader = true;
      }

      Net.post(
        "/GetRecordings.cmd",
        FD({
          project: this.$store.state.currentProject.Id,
          existingIds: this.recordings ? this.recordings.map(e => { if(e.Url){ return e.ProjectContact } }).filter(e => !!e).join(',') : ''
        })
      ).then(e => {
        if(this.recordings == false){
          this.recordings = e.data;
        } else {
          for(var i = 0; i < e.data.length; i++){
            var ext = this.recordings.filter(w => w.ProjectContact == e.data[i].ProjectContact)[0];
            if(ext){
              for(var r in e.data[i]){
                ext[r] = e.data[i][r]
              }
            } else {
              this.recordings.unshift(e.data[i]);
            }
          }
        }
        this.miniLoader = false;
      });
    }
  }
};
</script>
