<template>
  <div v-show="$_project">
    <NavTop :params="{search: false, projectNav: true}" />
    <router-view />
  </div>
</template>

<script>
import CurrentProjectInfo from '../../mixins/CurrentProjectInfo';
export default {
  mixins: [CurrentProjectInfo]
};
</script>