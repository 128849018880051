<template>
    <div>
        <router-link class="block" :to="'collector/'+collector.Id.toString()" v-for="collector in $_collectors" :key="collector.Id">{{collector.Name}}</router-link>
    </div>
</template>
<script>
import CurrentProjectInfo from "../../mixins/CurrentProjectInfo";
export default {
    mixins: [CurrentProjectInfo]    
}
</script>
<style scoped>
    .block{
        display: block;
        margin-bottom: 10px;
    }
</style>