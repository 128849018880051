<template>
  <div class="block mt__40">
    <div class="title__24 weight__700 mt__20 text__center">Project setup</div>
    <div class="block__maxw mt__80 center">
      <div class="title__16 grey__151 mt__80 text__center">
        Name and describe the project to make it unique
      </div>
      <Input
        class="mt__0"
        :params="{ value: _projectTitle, label: 'Name*' }"
        @input="
          (value) => {
            this.$_setProjectTitle(value);
          }
        "
      />
      <Input
        :params="{ value: _projectDescription, label: 'Description' }"
        @input="
          (value) => {
            this.$_setProjectDescription(value);
          }
        "
      />
      <div>
        <div class="title__16 grey__151 mt__80 text__center">
          Choose the language of this project
        </div>
        <Select
          style="margin-top: 10px"
          label="Language"
          :options="{
            ar: 'Arabic',
            cn: 'Chinese',
            en: 'English',
            fr: 'French',
            ge: 'German',
            hk: 'Cantonese',
            id: 'Indonesian',
            it: 'Italian',
            ru: 'Russian',
            sp: 'Spanish',
            th: 'Thai',
            vi: 'Vietnamese'
          }"
          @input="
            (value) => {
              this.$_setProjectLang(value);
            }
          "
          :params="{ valueField: _projectLang }"
        />
      </div>
    </div>
    <div class="flex__cc text__center mt__40">
      <router-link
        to="/project-setup/step-3"
        class="btn__grey__full btn__200 mr__40"
        >Back</router-link
      >
      <button
        class="btn__green__full btn__200 ml__40"
        @click="$_createNewProject()"
        :disabled="!tempProjectTitle"
      >
        Create project
      </button>
    </div>
  </div>
</template> 

<script>
import CreateProject from "../../mixins/CreateProject";
export default {
  mixins: [CreateProject],
};
</script>