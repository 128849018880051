<template>
  <div
    class="project"
    :class="{'non__edit': collectorStart || (project && project.CreatedFromMethodology && currentQuestionType != 24 && currentQuestionType != 30 && currentQuestionType != 31 && currentQuestionType != 18) || selectedQuestion && selectedQuestion.QuestItem.ReadOnly}"
    v-if="questItems"
  >
    <QuestionBlocks :selectedQuestion="selectedQuestion" />
    <!-- <div class="more__here title__14 weight__500 grey__151 lh__20 text__center" v-if="questItems.length > 5">{{questItems.length - 6}} more questions here</div> -->

    <div
      class="block__padding block__minh project__info"
      @click="() => {
          if(collectorStart || (project && project.CreatedFromMethodology && currentQuestionType != 24 && currentQuestionType != 30 && currentQuestionType != 31 && currentQuestionType != 18) || selectedQuestion && selectedQuestion.QuestItem.ReadOnly){
            showAttentionMessage()
            }}"
    >
      <!-- <Dropdown /> -->
      <TestingQuestions
        v-if="currentQuestionType == 24 || currentQuestionType == 30 || currentQuestionType == 31"
        :question="selectedQuestion"
      />
      <Instruction v-else-if="currentQuestionType == 18" :alternatives="questAlternetives" />
      <SingleAnswer v-else-if="currentQuestionType == 3" :alternatives="questAlternetives" />
      <SingleAnswerDropdown v-else-if="currentQuestionType == 5" :alternatives="questAlternetives" />
      <MultipleAnswer v-else-if="currentQuestionType == 4" :alternatives="questAlternetives" />
      <OpenEnded v-else-if="currentQuestionType == 1" :alternatives="questAlternetives" />
      <SmileRating v-else-if="currentQuestionType == 9" :alternatives="questAlternetives" />
      <QuestionList v-else-if="selectedQuestion == null" :after="addQuestionAfter" :quest="quest" />
      <div v-else>Invalid question</div>
    </div>
  </div>
</template>
 
<script>
import Net from "../../modules/net";

export default {
  data() {
    return {
      localSelectedQuestion: null,
      addQuest: false,
      quest: null,
      addQuestionAfter: null,
      project: {}
    };
  },
  created() {
    this.loadQuest();
    this.$on("selectQuestion", questItem => {
      this.localSelectedQuestion = questItem;
      this.addQuest = false;
      this.$store.commit("setCurrentQuestItem", questItem);
      this.$nextTick(() => {
        for (var ch = 0; ch < this.$children.length; ch++) {
          this.$children[ch].$emit("reload");
        }
      });
    });

    this.$on("addQuest", after => {
      this.addQuestionAfter = after;
      this.localSelectedQuestion = null;
      this.addQuest = true;
    });

    this.$on("deleteQuest", index => {
      this.$store.commit("loaderAction", true);
      Net.post(
        "/RemoveQuestItem.cmd",
        JSON.stringify({
          questionId: index
        }),
        {
          "Content-Type": "application/json"
        }
      )
        .then(e => {
          this.$store.commit("deleteQuestItem", index);
          this.localSelectedQuestion = null;
          this.$store.commit("loaderAction", false);
        })
        .catch(error => {
          debugger;
        });
    });
  },
  methods: {
    showAttentionMessage() {
      this.$bus.$emit("attentionMessage", {
        text: this.collectorStart
          ? "Project started, editing this field is not available!"
          : "You cannot change or delete this question. It's a default question needed to build analytics."
      });
    },
    loadQuest() {
      this.$store.dispatch("getProjectById", {
        callback: ({ project }) => {
          if (project) {
            this.project = project;
          }
          this.$store.dispatch(
            "setCurrentQuestById",
            this.$route.params.projectid
          );
        },
        id: this.$route.params.projectid
      });
    }
  },

  computed: {
    selectedQuestion() {
      if (this.addQuest || this.localSelectedQuestion) {
        return this.localSelectedQuestion;
      } else {
        return this.$store.state.currentQuestItem;
      }
      return null;
    },
    questItems() {
      return this.$store.state.currentQuest;
    },
    currentQuestionType() {
      return (
        this.selectedQuestion && this.selectedQuestion.QuestItem.QuestionType
      );
    },
    questAlternetives() {
      return this.selectedQuestion && this.selectedQuestion.Alternatives;
    },
    collectorStart() {
      return (
        this.selectedQuestion &&
        this.$store.state.currentCollector &&
        this.$store.state.currentCollector.status !== 0
        // Design = 0,
        // Run = 1,
        // Finished = 2,
        // Pause = 3,
        // Cancel = 4,
        // WaitingForApproval = 5,
        // WaitingForLaunch = 6
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.project {
  position: relative;
  display: flex;
  @include for-mobile-only {
    flex-direction: column;
    align-items: center;
  }
  @include for-mobile-up {
    justify-content: space-between;
    align-items: flex-start;
  }
  &__preview {
    max-width: calc(320px + 40px);
    width: 100%;
  }
  &__info {
    position: relative;
    @include for-mobile-only {
      width: 100%;
    }

    .dropdown {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
    }
  }
}

.more__here {
  position: absolute;
  left: 0;
  bottom: -30px;
  width: 320px;
}
</style>