<template>
  <div class="code flex__bc">
    <div class="code__info">
      <div class="title__12 weight__500 grey__151">{{label}}</div>
      <input
        class="code__input mt__5 input__hidden"
        ref="copyThis"
        :value="code"
        readonly
        @focus="selectCode"
      />
    </div>
    <div class="flex__ec">
      <div v-show="copied" class="code__copied mr__10">link copied</div>
      <div @click="copyText" class="code__icon">
        <IconCopy />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      copied: false
    };
  },
  props: ["code", "label"],
  methods: {
    selectCode() {
      this.$refs.copyThis.select();
    },
    copyText() {
      this.$refs.copyThis.select();
      document.execCommand("copy");
      this.copied = !this.copied;
      this.$refs.copyThis.blur();
    }
  }
};
</script>
<style lang="scss" scoped>
.code {
  background: $color-custom-grey;
  border-radius: $border-radius-sm;
  padding: 9px 12px;
  &__info {
    width: calc(100% - 150px);
  }
  &__input {
    color: $color-teal-blue;
    opacity: 1;
  }
  &__icon {
    cursor: pointer;
  }
  &__copied {
    color: $color-teal-blue;
  }
}
</style>