<template>
  <div class="dashboard">
  <NavTop />
    <h1 class="title__line__orange">dashboard</h1>
  </div>
</template>


<script>
export default {
  
};
</script>
