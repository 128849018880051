<template>
  <div class="custom__select" :tabindex="tabindex" @blur="open = false">
    <div class="selected" :class="{open: open}" @click="open = !open">
      <label :class="{'label__up': options[selected]}">{{label}}</label>
      {{ options[selected] }}
      <IconArrowRight />
    </div>
    <div class="items__wrap" :class="{selectHide: !open}">
      <div class="items">
        <div
          class="item"
          v-for="(option, i) of options"
          :key="i"
          @click="selected=i; open=false; $emit('input', i)"
        >{{ option }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Net from "../../modules/net";

export default {
  data() {
    return {
      selected: null,
      open: false,
      path: []
    };
  },
  props: {
    label: { type: String, required: true },
    options: {
      required: true
    },
    params: {
      default: function() {
        return {
          target: null,
          useKey: false
        };
      }
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0
    }
  },
  mounted() {
    this.$emit("input", this.selected);
  },
  watch: {
    selected() {
      if (this.params.target) {
        Net.update(
          this.path,
          this.params.useKey ? this.options[this.selected] : this.selected
        );
      } else if (this.params.object && this.params.field) {
        Net.update(
          [this.params.field],
          this.params.useKey ? this.options[this.selected] : this.selected,
          this.params.object
        );
      }
    }
  },
  created() {
    var element = null;
    if (this.params.target) {
      this.path = this.params.target.split(".");
      element = this.$store.state;
      for (var p = 0; p < this.path.length; p++) {
        element = element[this.path[p]];
      }
    } else if (this.params.object && this.params.field) {
      element = this.params.object[this.params.field];
    }
    if (this.params.valueField) { // set default value
      this.selected = this.params.valueField;
    } else if (this.params.useIndex) {
      this.selected = this.options.indexOf(element);
    } else {
      this.selected = element;
    }
  }
};
</script>

<style lang="scss">
.custom__select {
  position: relative;
  width: calc(100% + 20px);
  height: 38px;
  margin-top: 43px;
  margin-left: -10px;
  text-align: left;

  .selected {
    background-color: $color-white;
    border: 1px solid $color-white;
    border-radius: $border-radius-sm $border-radius-sm 0 0;
    padding: 17px 10px 3px 10px;
    cursor: pointer;
    user-select: none;
    position: relative;
    height: 100%;
    &.open {
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
      label {
        z-index: -1;
      }
      .icon-arr-right {
        transform: rotate(90deg);
      }
    }
    label {
      position: absolute;
      top: 14px;
      left: 10px;
      // font-size: 17px;
      // color: $color-mid-grey;
      z-index: 1;
      &.label__up {
        top: 0;
        font-size: 12px;
      }
    }
    &:after {
      @include pseudo;
      bottom: -2px;
      left: 10px;
      width: calc(100% - 20px);
      height: 1px;
      background: $color-mid-grey;
      @include transitionAll;
    }
    &.open::after {
      z-index: 13;
    }
    .icon-arr-right {
      position: absolute;
      right: 10px;
      bottom: 5px;
      @include transitionAll;
    }
  }

  .item {
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    user-select: none;
    @include transitionAll;
    &s {
      max-height: 100px;
      overflow-y: auto;
      &__wrap {
        padding: 10px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
        border-radius: 0px 0px 6px 6px;
        overflow: hidden;
        position: absolute;
        background-color: $color-white;
        top: 38px;
        left: 0;
        right: 0;
        z-index: 12;
      }
    }
    &:hover {
      color: $color-teal-blue;
    }
  }

  .selectHide {
    display: none;
  }
}
</style>