<template>
  <div class="block__padding mt__40">
    <div class="title__24 weight__700 mt__20 text__center">Automated behavioral insights for UX/UI testing on mobile devices</div>
    <p class="mt__20">
      Fast, easy, insightful – just three of the things we know you'll love about the
      <b>automated UXReality methodology.</b>
    </p>
    <p>This methodology is a unique combination of standard User Experience (UX) / User Interface (UI) studies and nonconscious measurements obtained through online neuro-tools.</p>
    <p>At the heart of the UXReality methodology is a task-based approach for testing digital products (mobile websites, apps, prototypes). Testers are given a task e.g., to order product X or reserve a flight ticket for a family of 3 to the city X on specific dates. Their experience is then evaluated using a short preset survey. While performing the task, the user’s mobile device camera tracks visual attention through eye-tracking and emotional response through facial coding. the user’s visual attention You’ll also be able to see all the scrolls and clicks, and how the user interacted with the content.</p>
    <div class="title__24 weight__700 text__center mt__40">The main advantage is an automated analysis</div>
    <p class="mt__20">UXReality methodology is completely automated so everything is done for you, from set up to processing, analysis, and results. Behavioral and survey data is processed and displayed automatically on the dashboard using charts, video recordings, basic analytics, and answers to open-ended questions.</p>
    <p>Just run the project and get fast, automated insights on how awesome your product is. The analysis will even show you where improvements can be made.</p>
    <div class="title__24 weight__700 text__center mt__40">Insights and metrics of user behavior you get</div>
    <img class="mt__20" src="@/assets/img/methodology/methodology-1.jpg" alt="image" />
    <p class="mt__20">
      <b>Success rate</b> - this reveals the number of users who completed the task with ease.
    </p>
    <img class="mt__20" src="@/assets/img/methodology/methodology-2.jpg" alt="image" />
    <p class="mt__20">
      <b>Quantitative indicators</b> - task completion rate, task clarity, and time/number of clicks to complete the task - reveal how your target user interacts with your design.
    </p>
    <img class="mt__20" src="@/assets/img/methodology/methodology-3.jpg" alt="image" />
    <p class="mt__20"><b>Engagement</b> - the metrics reveal how a user behaves while completing a task:</p>
    <ul>
      <li>Visual attention (based on eye-tracking data) shows how attentively or superficially the content is consumed,</li>
      <li>Emotional arousal (based on facial coding analytics) shows a user's emotional response in general, ratio of positive vs. negative emotional responses, and split of emotions (delight, surprise, sadness, etc.).</li>
    </ul>
    <img class="mt__20" src="@/assets/img/methodology/methodology-4.jpg" alt="image" />
    <p class="mt__20"><b>Usability</b> - the metrics show how easy-to-use the product is in terms of:</p>
    <ul>
      <li>Navigation (the convenience of navigation and search system),</li>
      <li>Naming (the clarity of naming sections, headers, buttons),</li>
      <li>User flow efficiency (the number of steps a user needs to take in order to complete the task),</li>
      <li>Confidence (the level of confidence while using this product),</li>
      <li>Design (how the design is intuitive/user-friendly).</li>
    </ul>
    <img class="mt__20" src="@/assets/img/methodology/methodology-5.jpg" alt="image" />
    <p class="mt__20"><b>Perception</b> - the metrics show the user’s overall attitude towards the product:</p>
    <ul>
      <li>Overall likability,</li>
      <li>Relevance,</li>
      <li>Intention to recommend,</li>
      <li>Intention to use.</li>
    </ul>
    <p class="mt__20"><b>Feedback section</b> – in this section you can analyze the answers to the open-ended questions to understand the difficulties users faced, identify unexpected issues, uncover any missing elements, etc.</p>
    <p><b>Individual recordings</b> – these are invaluable for qualitative analysis or when you need to get visual proof on where and why your users got stuck, what distracted their attention, what irritated or delighted them.</p>
    <p class="mt__60 text__center">
      Run <b>UXReality methodology</b> to get automated insights
      <br />on how users see/use/perceive your mobile site, app, or prototype
    </p>
    <div class="flex__cc mt__20">
      <router-link to="/project-setup/step-3" class="btn__grey btn__280 mr__40" @click="$router.back()">Back</router-link>
      <div class="btn__green__full btn__280" @click="$_setProjectMethodology('/CreateMethodology.cmd')">Apply this methodology</div>
    </div>
  </div>
</template>

<script>
import CreateProject from "@/mixins/CreateProject";
export default {
  mixins: [CreateProject]
};
</script>