<template>
  <div v-if="!params.editInput" class="input__row" :class="{'error': error}">
    <!-- TODO: http://prntscr.com/sxp6z5 -->
    <input
      v-if="!params.textarea"
      :type="params.type"
      :id="uid"
      v-model="value"
      :placeholder="params.placeholder"
      :autofocus="params.autofocus"
      :class="{ 'focused': value }"
      @blur="save"
      autocomplete="off"
    />
    <textarea
      v-else
      rows="1"
      :type="params.type"
      :id="uid"
      v-model="value"
      ref="textarea"
      :placeholder="params.placeholder"
      :style="inputStyle"
      :class="{ 'focused': value && value.length}"
      @blur="save"
    />
    <label :for="uid">
      {{params.label}}
      <span v-if="error">{{error}}</span>
    </label>
  </div>
  <div v-else class="flex__se text__overflow">
    <input
      v-if="editTitle"
      :type="params.type"
      v-model="value"
      @blur="()=>{editTitle = false; save()}"
      class="input__edit"
      autocomplete="off"
    />
    <div v-if="!editTitle" class="mr__10 title__edit">{{value}}</div>
    <span
      @click="editTitle = !editTitle"
      class="icon__edit"
      v-if="!editTitle && !$_project.ReadOnly"
    >
      <IconEditLink class="flex__se" />
    </span>
  </div>
</template>

<script>
import Net from "../../modules/net";
import validation from "../../modules/validation";
import CurrentProjectInfo from "../../mixins/CurrentProjectInfo";
export default {
  mixins: [CurrentProjectInfo],
  data() {
    return {
      value: "",
      oldvalue: "",
      path: [],
      error: null,
      inputHeight: "0",
      editTitle: false,
      editTitleName: null,
      uid: null
    };
  },
  props: {
    params: {
      default: function() {
        return {
          type: "text",
          label: "Enter text here",
          placeholder: "",
          target: null,
          change: false,
          autofocus: false,
          validate: false,
          textarea: false,
          editInput: false,
          min: false,
          max: false
        };
      }
    }
  },
  watch: {
    value() {
      if (this.$refs.textarea) this.resize();
      this.$emit("input", this.value);
    }
  },
  computed: {
    inputStyle() {
      return {
        "min-height": this.inputHeight
      };
    }
  },
  mounted() {
    if (this.$refs.textarea) this.resize();
  },
  methods: {
    resize() {
      this.inputHeight = 0;
      this.$nextTick(() => {
        this.inputHeight = `${this.$refs.textarea.scrollHeight + 2}px`;
      });
    },
    save() {
      if (this.params.min && this.value < this.params.min) {
        this.value = this.params.min;
      }

      if (this.params.max && this.value > this.params.max) {
        this.value = this.params.max;
      }
      if(this.value !== null) {
        this.value = this.value.trim(); 
      }
      var valid =
        !this.params.validate ||
        (this.params.validate.constructor == String
          ? validation[this.params.validate](this.value)
          : this.params.validate(this.value));
      if (valid == true) {
        this.error = false;
        if (this.params.change) {
          this.params.change(this.value);
        }

        if (this.value != this.oldvalue) {
          if (this.params.target) {
            Net.update(this.path, this.value);
          } else if (this.params.object && this.params.field) {
            Net.update([this.params.field], this.value, this.params.object);
          }
          this.oldvalue = this.value;
        }
      } else {
        this.error = valid;
      }
    },
    readValue() {
      if (this.params.target) {
        this.path = this.params.target.split(".");
        var element = this.$store.state;
        for (var p = 0; p < this.path.length; p++) {
          element = element[this.path[p]];
        }
        this.oldvalue = this.value = element;
      } else if (this.params.object && this.params.field) {
        this.oldvalue = this.value = this.params.object[this.params.field];
      } else if (this.params.value) {
        this.oldvalue = this.value = this.params.value;
      }
    }
  },
  created() {
    this.uid = (Math.random() * Date.now() * Math.random()) ^ 0;
    this.readValue();
    this.$on("reload", () => {
      this.readValue();
    });
  }
};
</script>
<style lang="scss" scoped>
.input__edit {
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  text-transform: inherit;
  padding: 0;
}
.title__edit {
  border: 1px solid rgba($color-white, 0.01);
}
.icon__edit {
  margin-bottom: 3px;
}
</style>