<template>
  <div>
   <!--  <div v-if="collector">
      <div class="block block__minh testers__grid">
        <div class="col__2">
          <div class="testers__grid__1">
            <Input
              class="title__20 weight__500 text__up"
              :params="{label: '', target: 'currentCollector.Name', editInput: 'true'}"
            />
          </div>

          <div>
            <div class="flex__ec testers__grid__2" v-if="startNow">
              <div class="icons__bg__grey mr__20">
              <IconPause />
            </div>
            <div class="icons__bg__grey mr__20" @click="startNow = false, disableSample = false">
              <IconStop />
            </div>
            <div class="icons__bg__grey mr__20" @click="popup = !popup">
              <IconBin />
            </div>
            <router-link class="icons__bg__grey" to="/project/:id/invite-testers">
              <IconClose />
              </router-link>
            </div>
            <div
              v-else
              class="testers__grid__2 btn__green__full btn__200 btn__right"
              @click="() => {if(canStartNow) {
                startCollector()
              } else {showAttentionMessage()}}"
            >Start now</div>
          </div>
        </div>
        <div class="testers__grid__3">
          <div class="col__2 mt__20" :class="{'mob__rev': !startNow}">
            <div class="flex__progress" v-if="startNow">
              <NOTUseProgressCircle
                :params="{persent: true,
              persentTitle: collector.completePercentage,
              persentDescription: 'Completed',
              color: finished ? 'rgb(246, 97, 197)' : 'rgb(0, 188, 235)'}"
              />
              <NOTUseProgressCircle
                :params="{
              persentTitle: collector.completes,
              persentDescription: 'finished',
              color: finished ? 'rgb(151, 151, 151)' : 'rgb(0, 188, 235)'}"
              />
              <NOTUseProgressCircle
                :params="{
              persentTitle: collector.partial,
              persentDescription: 'now testing',
              color: finished ? 'rgb(151, 151, 151)' : 'rgb(110, 199, 0)'}"
              />
            </div>
            <div>
              <div class="title__18 weight__500">Sample Size</div>
              <div class="title mt__5">Please select how many respondents you need</div>
              <InputRange
                :max="1000"
                :min="1"
                :xx="((collector.completes + collector.partial + 1) / 1000) * 100"
                :params="{single: true, initialValue: sampleSize, change: changeSampleRate,
                disable: finished}"
              />
            </div>
            <div v-if="!startNow" class="attention__start flex__ec mt__25">
              <div class="mr__20">
                <IconAttention />
              </div>
              <div>
                After clicking on this button you can only
                <br />change the sample size and the task text
              </div>
            </div>
          </div>
          <CopyCode :class="startNow ? 'mt__10' : 'mt__40 non__active'" :code="code" :label="'Invitation code'"/>
          <div class="mt__40 col__2" :class="{'non__active': !startNow}">
            <div>
              <div class="title__18 weight__500">How to conduct local testing:</div>
              <ol>
                <li>
                  Download
                  <a
                    class="link"
                    href="https://play.google.com/store/apps/details?id=com.uxreality"
                    target="_blank"
                  >UXReality app</a>&nbsp;
                  <span class="title__12 grey__210">(runs on Android version 7.0 and higher)</span>
                </li>
                <li>Run the app on the smartphone</li>
                <li>Follow the instruction in the app</li>
              </ol>
            </div>
            <div>
              <div class="title__18 weight__500">How to conduct remote testing:</div>
              <ol>
                <li>
                  Send respondents the link to download
                  <a
                    class="link"
                    href="https://play.google.com/store/apps/details?id=com.uxreality"
                    target="_blank"
                  >UXReality app</a>
                </li>
                <li>Ask respondents to run the app</li>
                <li>
                  Ask them to enter invitation code
                  <b v-if="startNow">{{code}}</b> inside the app
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="block text__center">Collector is empty</div>-->
  </div>
</template>

<script>
import Net from "../../modules/net";
import FD from "../../modules/FData";
export default {
  name: "collect-responses",
  data() {
    return {
      disableSample: false
    };
  },
  created() {},
  computed: {
    collector() {
      return this.$store.state.currentCollector;
    },
    code() {
      return this.collector && this.collector.invitationCode;
    },
    sampleSize() {
      return this.collector && this.collector.CollectorSample.SelectedNumberOfResponses;
    },
    canStartNow() {
      return this.$store.state.currentProject.ReadyForStart;
    },
    startNow() {
      return this.collector && this.collector.status !== 0;
    },
    finished() {
      return this.collector && this.collector.status == 2;
    }
  },
  methods: {
    showAttentionMessage() {
      this.$bus.$emit("attentionMessage", {
        text:
          "In order to start the project, please, fill in all the required boxes in the Set Up Project.",
        click: () => {
          this.$router.push({
            path:
              "/project/" +
              this.$route.params.projectid +
              "/set-up-project?invalid=true"
          });
        }
      });
    },
    changeSampleRate(value) {
      Net.update(
        ["currentCollector", "CollectorSample", "SelectedNumberOfResponses"],
        value ^ 0
      );
    },
    startCollector() {
      Net.post(
        "/ChangeCollectorStatusCommand.cmd",
        FD({
          collector: this.collector.Id,
          status: 1
        })
      ).then(() => {
        this.disableSample = true;
        this.$store.commit("update", {
          target: this.collector,
          field: "status",
          value: 1
        });
      });
    }
  }
};
</script>

<style lang="scss">
.testers__grid {
  @include for-mobile-only {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    &__1 {
      order: 1;
    }
    &__2 {
      order: 3;
      margin-top: 40px;
    }
    &__3 {
      order: 2;
    }
  }
  &__3 {
    width: 100%;
  }
}
.flex__progress {
  display: flex;
  flex-wrap: wrap;
  @include for-mobile-only {
    justify-content: space-around;
    align-items: center;
    .progress__circle {
      margin: 0 20px 10px;
    }
  }
  @include for-mobile-up {
    justify-content: flex-start;
    align-items: flex-end;
    .progress__circle {
      margin-bottom: 30px;
    }
    .progress__circle:not(:last-child) {
      margin-right: 30px;
    }
  }
}
@include for-mobile-only {
  .code {
    margin-top: 60px;
  }
}
.non__active {
  pointer-events: none;

  &.code {
    .code__input,
    .code__icon {
      opacity: 0;
    }
  }
  &.col__2 {
    opacity: 0.2;
  }
}
.attention__start {
  @include for-mobile-only {
    margin-bottom: 40px;
  }
  @include for-mobile-up {
    text-align: right;
  }
  svg.icon-attention {
    path {
      fill: $color-teal-blue;
    }
  }
}
@include for-mobile-only {
  .testers__grid__3 .col__2.mob__rev {
    display: flex;
  }
}
</style>