import Net from "../modules/net";
import Store from "../store/index";
export default {
    methods: {
        addOption() {
            var currentQuestId = Store.state.currentQuestItem.QuestItem.Id;
            Net.post(
                "/AddApiAlternative.cmd",
                JSON.stringify({
                    questionId: currentQuestId
                }),
                {
                    "Content-Type": "application/json"
                }
            )
                .then(e => {
                    Store.commit("addQuestItemAlternative", {
                        after: currentQuestId,
                        alternative: e.data
                    });
                })
                .catch(error => {
                    debugger;
                });
        },
        deleteOption(id) {
            Store.commit("loaderAction", true);
            Net.post(
                "/DeleteAlternative.cmd",
                JSON.stringify({
                    alternativeId: id
                }),
                {
                    "Content-Type": "application/json"
                }
            )
                .then(e => {
                    Store.commit("deleteQuestItemAlternative", id);
                    this.$nextTick(() => {
                        for (var ch = 0; ch < this.$refs.input.length; ch++) {
                            this.$refs.input[ch].$emit("reload");
                        }
                    });
                    Store.commit("loaderAction", false);
                })
                .catch(error => {
                    debugger;
                });
        }
    }
}