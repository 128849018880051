export default {
    email(value) {
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (value && !value.match(mailformat)) {
            return 'is not valid'
        }
        return true
    },
    phone(value) {
        var phoneformat = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
        if (value && !value.match(phoneformat)) {
            return 'number is not valid'
        }
        return true
    },
    int(value){
        return value * 1 == value ? true : 'Numbers only';
    },
    link(value){
        var linkFormat = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g;
        if (value && !value.match(linkFormat)) {
            return 'is not valid'
        } else if(value && value.indexOf('://') == -1) {
            return 'is not valid. Please, include protocol http://, https://, etc.'
        }
        return true
    }
}