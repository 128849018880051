import Vue from 'vue';
import Hotjar from 'vue-hotjar'
import { influTracker } from 'vue-influ2'
import App from './App.vue';
import Axios from 'axios';
import router from './router';

import store from './store';
import FD from './modules/FData';
import Net from './modules/net';
import { SliderPlugin } from '@syncfusion/ej2-vue-inputs';

import Popup from './components/Popups/Popup';
import eventBus from './modules/eventBus';

import { ElementTiptapPlugin } from 'element-tiptap';
import 'element-tiptap/lib/index.css';
import 'element-ui/lib/theme-chalk/index.css';

import './vee-validate.js';
// Vue.use(FD, Net, SliderPlugin);

Vue.use(Hotjar, {
  id: '1821683' // Hotjar Site ID
})

Vue.use(influTracker, {
  id: '77bce229-43f8-4a63-b940-37f607981d47' // influe ID
})
Vue.use(ElementTiptapPlugin, {});
Vue.prototype.$axios = Axios;
Vue.prototype.$bus = eventBus;
Vue.config.productionTip = false;
Vue.component('Popup', Popup);

Net.setupHTTPInterceptors(store)
router.setupRouterInterceptors(store)

const requireComponent = require.context(
  './', true, /[A-Z]\w+\.(vue)$/
)

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)
  Vue.component(
    fileName
      .split('/')
      .pop()
      .replace(/\.\w+$/, ''),
    componentConfig.default || componentConfig
  )
})



Net.checkAuth() // delay load
.catch((e) => {})
.finally(() => {
  new Vue({
    /* Global resize*/
    data() {
      return {
        _mobile: window.innerWidth <= 999
      }
    },
    created() {
      // window.addEventListener('resize', this.handleResize)
      // this.handleResize();
      var self = this;
  
      Object.defineProperties(Vue.prototype, {
        ismobile: {
          get() {
            return false; // _self._mobile
          }
        },
        isSmallScreen: {
          get() {
            return screen.width <= 1000;
          }
        }
      });
    },
    methods: {
      searchToObject() {
        if (!window.location.search) {
          return null;
        }
  
        var pairs = window.location.search.substring(1).split("&"),
          obj = {},
          pair,
          i;
  
        for (i in pairs) {
          if (pairs[i] === "") continue;
          pair = pairs[i].split("=");
          obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
        }
  
        return obj;
      },
      setMobileField(element) {
        if (element.mobile !== undefined) {
          element.mobile = this.ismobile
        }
        if (element.$children) {
          for (var c = 0; c < element.$children.length; c++) {
            this.setMobileField(element.$children[c])
          }
        }
      },
      handleResize() {
        this._mobile = innerWidth <= 999;
        this.setMobileField(this);
      }
    },
    destroyed() {
      // window.removeEventListener('resize', this.handleResize)
    },
    /* end Global resize*/
    store,
    router,
    FD, Net, SliderPlugin,
    render: h => h(App)
  }).$mount('#app')
})