<template>
  <div class="popup">
    <div class="overlay" @click="close"></div>
    <div class="popup__window block">
      <PopupDeleteProject v-if="type == 'deleteProject'" :close="close" :projectId="projectId" />
      <PopupDeleteCard v-else-if="type == 'deleteCard'" :close="close" />
      <PopupToStarter v-else-if="type == 'downToStarter'" :close="close" />
      <PopupPayRemote
        v-else-if="type == 'PopupPayRemote'"
        :close="close"
        :payAmount="payAmount"
        :collector="collector"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: ["close", "projectId", "type", "payAmount", "collector"]
};
</script> 
<style lang="scss">
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($color-dark-grey, 0.6);
  z-index: 100;
}
.popup {
  &__window.block {
    position: fixed;
    top: 39%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: $size-container;
    z-index: 101;
    @include for-mobile-only {
      width: calc(100% - 40px);
      padding: 20px;
    }
    @include for-mobile-up {
      width: auto;
      padding: 35px 40px;
    }
  }
  &__title {
    font-weight: 300;
    font-size: 24px;
    text-transform: uppercase;
    padding-bottom: 30px;
    border-bottom: 1px solid $color-mid-grey;
  }
  &__description {
    margin-top: 30px;
    font-weight: 500;
    font-size: 22px;
    letter-spacing: 0.05em;
  }
}
/*INNER popup*/
.popup {
  &__description {
    max-width: 570px;
  }
  &__delete__confirm {
    // margin-top: 30px;
    // padding-top: 25px;
    label {
      // font-size: 12px;
      // text-transform: uppercase;
      // font-weight: 800;
      > span {
        color: $color-black;
        font-weight: 600;
      }
    }
    // input {
    //   font-size: 17px;
    //   padding: 8px 0 3px;
    // }
  }
  .btn__grey {
    padding: 11px 45px;
  }
}
.popup__btns > div,
.popup__btns > a {
  @include for-mobile-only {
    display: block;
    margin: 30px 0 0 0;
  }
  &:not(:last-child) {
    @include for-mobile-up {
      margin: 30px 30px 0 0;
    }
  }
}
</style>