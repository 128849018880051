import Vue from 'vue'
import VueRouter from 'vue-router'
import Net from '../modules/net'

import guest from './middleware/guest'
import middlewarePipeline from './middlewarePipeline'

Vue.use(VueRouter)

const routes = [
    { path: '/', redirect: '/signin' },
    {
        path: '*',
        name: 'error',
        component: () =>
            import ('@/views/Error.vue')
    },
    /* authorization */
    {
        path: '/signin',
        name: 'signin',
        component: () =>
            import ('@/views/User/SignIn.vue'),
        meta: {
            middleware: [
                guest
            ]
        }
    },
    {
        path: '/registration',
        name: 'registration',
        component: () =>
            import ('@/views/User/Registration.vue'),
        meta: {
            middleware: [
                guest
            ]
        }
    },
    {
        path: '/forgotpass',
        name: 'forgotpass',
        component: () =>
            import ('@/views/User/ForgotPass.vue'),
        meta: {
            middleware: [
                guest
            ]
        }
    },
    {
        path: '/resetpass',
        name: 'resetpass',
        component: () =>
            import ('@/views/User/ResetPass.vue'),
        meta: {
            middleware: [
                guest
            ]
        }
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () =>
            import ('@/views/Dashboard.vue')
    },
    /* Project */
    { path: '/project', redirect: '/projects' },
    {
        path: '/projects',
        name: 'projects',
        component: () =>
            import ('@/views/Projects/AllProjects.vue')
    },
    /* SetUp Master*/
    { /*Device*/
        path: '/project-setup/step-1',
        name: 'project-setup-step-1',
        component: () =>
            import ('@/views/ProjectSetup/Step1.vue')
    },
    {/*TargetDevices*/
        path: '/project-setup/step-2',
        name: 'project-setup-step-2',
        component: () =>
            import ('@/views/ProjectSetup/Step2.vue')
    },
      {/*Metodology*/
        path: '/project-setup/step-3',
        name: 'project-setup-step-3',
        component: () =>
            import ('@/views/ProjectSetup/Step3.vue')
    },
      {/*ProjectSettings*/
        path: '/project-setup/step-4',
        name: 'project-setup-step-4',
        component: () =>
            import ('@/views/ProjectSetup/Step4.vue')
    },
      {/*QuestionSettings*/
        path: '/project-setup/step-5',
        name: 'project-setup-step-5',
        component: () =>
            import ('@/views/ProjectSetup/Step5.vue')
    },
    /* end SetUp Master*/
    {
        path: '/project/:projectid',
        name: 'project',
        component: () =>
            import ('@/views/Projects/ProjectsForRoute.vue'),
        children: [{
                path: 'set-up-project',
                name: 'set-up-project',
                component: () =>
                    import ('@/views/Projects/SetUpProject.vue')
            },
            {
                path: 'invite-testers',
                name: 'invite-testers',
                component: () =>
                    import ('@/views/InviteTesters/ChooseTesters.vue')
            },
            {
                path: 'collectors',
                name: 'collectors',
                component: () =>
                    import ('@/views/InviteTesters/CollectorsList.vue'),
            },
            {
                path: 'collector/:collectorid',
                name: 'collector-view',
                component: () =>
                    import ('@/views/InviteTesters/CollectorView.vue'),
            },
            {
                path: 'collector/:collectorid/analyze-results',
                name: 'analyze-results',
                component: () =>
                    import ('@/views/AnalyzeResults/AnalyzeResults.vue')
            }
        ]
    },
    /* Payment */
    {
        path: '/payments',
        name: 'payments',
        component: () =>
            import ('@/views/Payments/PaymentHistory.vue')
    },
    {
        path: '/subscription',
        name: 'payment-subscription',
        component: () =>
            import ('@/views/Payments/PaymentSubscription.vue'),
        children: [{
            path: ':subscription',
            name: 'payment-subscription-subscription',
            component: () =>
                import ('@/views/Payments/PaymentSubscription.vue')
        }]
    },
    {
        path: '/payment-methods',
        name: 'payment-methods',
        component: () =>
            import ('@/views/Payments/PaymentMethods.vue'),
        children: [{
            path: ':subscription',
            name: 'payment-methods-subscription',
            component: () =>
                import ('@/views/Payments/PaymentMethods.vue'),
        }]
    },
    {
        path: '/notifications',
        name: 'notifications',
        component: () =>
            import ('@/views/Notifications.vue')
    },
    {
        path: '/account',
        name: 'account',
        component: () =>
            import ('@/views/Account.vue')
    },
    {
        path: '/settings',
        name: 'settings',
        component: () =>
            import ('@/views/Settings.vue')
    },
    {
        path: '/contact',
        name: 'contact',
        component: () =>
            import ('@/views/Contact.vue')
    },
    {
        path: '/methodology',
        name: 'methodology',
        component: () =>
            import ('@/views/Methodology.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
      }
})

const subscribeAuthStateChange = (store) => {
    store.watch(state => state.authState, function(){
        if( !store.state.authState ){
            router.push({ name: 'signin'})
        } else {
            router.push({ name: 'projects'})
        }
    });
}

router.setupRouterInterceptors = (store) => {
    subscribeAuthStateChange(store);

    router.beforeEach((to, from, next) => {
        if (!to.meta.middleware) {
            // global check
            if (!store.state.authState) {
                return next({ name: 'signin'})
            }
            return next()
        }
        const middleware = to.meta.middleware
        const context = {
            to,
            from,
            next,
            store
        }
        return middleware[0]({
            ...context,
            nextMiddleware: middlewarePipeline(context, middleware, 1)
        })
    })
}

export default router