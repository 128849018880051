import Axios from "axios";
import env from "./env";
import FD from "./FData";
import store from "../store/index"

class Net {
    static __instance = null;
    access_token = localStorage.getItem('access_token');
    expires_in = localStorage.getItem('expires_in');
    token_type = localStorage.getItem('token_type');
    axios = null;

    constructor() {
        if (!Net.__instance) {
            this.axios = Axios.create({
              timeout: 20000 // 20 seconds for request
            });
            Net.__instance = this
          }
      
          return Net.__instance
    }

    saveAuth() {
        localStorage.setItem('access_token', this.access_token);
        localStorage.setItem('expires_in', this.expires_in);
        localStorage.setItem('token_type', this.token_type);
    }

    logOut() {
        this.post('/identity/connect/revocation', FD({
            client_id: "cooltool",
            client_secret: "cooltool_secret",
            token: this.access_token
        }, {
            "Content-type": "application/x-www-form-urlencoded"
        }));
        this.clearAuthCredentials()
    }

    request(method, point, data, headers) {
        return new Promise((res, rej) => {
            var arg = [env.INTERNAL_URL + point];
            if (data) {
                arg.push(data);
            }
            arg.push({
                headers: {
                    "Authorization": this.token_type + " " + this.access_token,
                    ...headers
                }
            })

            this.axios[method].apply(this, arg).then((e) => {
                if (e.data.access_token) {
                    this.access_token = e.data.access_token;
                    this.expires_in = e.data.expires_in;
                    this.token_type = e.data.token_type;
                    this.saveAuth();
                }

                res(e);
            }).catch((e) => {
                rej(e);
            })
        })
    }

    get(point, data, headers) {
        return this.request("get", point, data, headers);
    }

    post(point, data, headers) {
        return this.request("post", point, data, headers);
    }


    update(path, value, target) {
        var element = target || store.state;
        for (var p = 0; p < path.length - 1; p++) {
            element = element[path[p]];
        }

        if (element[path[path.length - 1]] !== value) {
            store.commit("update", {
                target: element,
                field: path[path.length - 1],
                value: value
            })

            var request = {};
            request[(element.Id || element.collectorId) + "___" + path[path.length - 1]] = value;

            this.post('/UpdateObjectCommand.cmd', FD(request));
        }
    }

    checkAuth() {
        return new Promise((res, rej) => {

            if (!this.access_token) {
                rej();
                return;
            }

            this.get("/api/user/TokenIntrospect?token=" + this.access_token).then((result) => {
                if (result.data) {
                    store.dispatch("setAuthState", true)
                    res();
                } else {
                    rej();
                }
            }).catch(() => {
                rej();
            })
        })
    }

    clearAuthCredentials() {
        this.access_token = this.expires_in = this.token_type = null;
        store.dispatch("setAuthState", false)
        this.saveAuth();
    }

    setupHTTPInterceptors({store, router}) {
        this.axios.interceptors.response.use(resp => resp, error => {
        // check valid token
            this.checkAuth()
            .catch(this.clearAuthCredentials.bind(this))
            return Promise.reject(error);
        });
    }
}

export default new Net;