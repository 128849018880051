<template>
  <div class="bottom__popup bottom__popup__mobile text__center" v-if="!mobileHideMessage">
    <div class="mt__20">UXReality platform is <b>not yet optimized</b> for mobile devices. For comfortable work use your PC or Mac.</div>
    <div class="btn__green btn__320" @click="toLocal">Ok</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      mobileHideMessage: false
    };
  },
  created() {
    if (localStorage.getItem("mobileMessage") == "true") {
      this.mobileHideMessage = true;
    } else {
      this.mobileHideMessage = false;
    }
  },
  methods: {
    toLocal() {
      localStorage.setItem("mobileMessage", true);
      this.mobileHideMessage = true;
    }
  }
};
</script>
<style lang="scss">
.bottom__popup.bottom__popup__mobile {
  z-index: 999;
}
</style>