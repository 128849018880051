<template>
  <div
    class="tab__content"
    v-if="$_project"
  >
    <div
      v-if="false"
      class="collected__gag text__center mt__50"
    >
      <img src="@/assets/img/icons/dashboard-blue.svg" alt="icon" />
      <div class="title__38 weight__900 mt__80">
        <b>Please wait until the required number of testers are recruited</b>
      </div>
      <div
        class="title__16 weight__700 grey__121 mt__60"
      >You can already see the individual video recordings on the dashboard, but note that for valid analytics data you need to collect at least 5 responses.</div>
    </div>
    <!-- completion rate -->
    <div class="flex__bs">
      <div
        class="fake__progress__full mr__40"
        :style="'width: 232px; height: 232px; border-color:'+report.All.SuccessRateColor+';'"
      >
        <div class="fake__progress__inner" :style="'background:'+report.All.SuccessRateColor+';'">
          <div class="fake__progress__title">{{report.All.SuccessRate}}%</div>
          <div class="fake__progress__description">success rate</div>
        </div>
      </div>

      <div style="width: calc(100% - 232px - 40px);">
        <div
          class="title__38 weight__900"
          :style="'color:'+report.All.SuccessRateColor+';'"
        >{{report.All.Title}}</div>
        <div class="title__18 grey__151 mt__5" style="line-height: 24px;">{{report.All.Description}}</div>
        <div class="mt__25 col__2">
          <div v-if="report.All.GoodCriterias && report.All.GoodCriterias.length">
            <div class="title__14 weight__700 blue">WHY USERS LIKE YOUR DESIGN:</div>
            <div class="title__16 mt__10">
              <div
                style="line-height: 24px;font-weight:500;"
                v-for="(good, index) in report.All.GoodCriterias"
                :key="index"
              >{{`- ${good}`}}</div>
            </div>
          </div>
          <div v-if="report.All.BadCriterias && report.All.BadCriterias.length">
            <div class="title__14 weight__700 red">PLEASE PAY ATTENTION TO:</div>
            <div class="title__16 mt__10">
              <div
                style="line-height: 24px;font-weight:500;"
                v-for="(bad, index) in report.All.BadCriterias"
                :key="index"
              >{{`- ${bad}`}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end completion rate -->
    <div class="tab mt__40">
      <!-- Tab nav  -->
      <div class="flex__sstr tab__nav4" v-if="report.All.Count.All !== 0">
        <div
          :class="['tab__nav blue', {tab__nav__active: selectedKey == 'All', inactive: report.All.Count.All == 0}]"
          @click="selectedKey = 'All'"
        >
          <div class="flex__bs">
            <div>
              <div class="title__16 weight__700 grey__121b">All</div>
              <div class="title__12 weight__500 grey__151 mt__5">Everyone who participated</div>
            </div>
            <div class="title__32 weight__700">{{report.All.Count.All}}</div>
          </div>
        </div>
        <div
          :class="['tab__nav red', {tab__nav__active: selectedKey == 'Unsuccess', inactive: report.All.Count.Unsuccess == 0}]"
          @click="selectedKey = 'Unsuccess'"
        >
          <div class="flex__bs">
            <div>
              <div class="title__16 weight__700 grey__121b">Unsuccess</div>
              <div class="title__12 weight__500 grey__151 mt__5">Testers that failed the task</div>
            </div>
            <div class="title__32 weight__700">{{report.All.Count.Unsuccess}}</div>
          </div>
        </div>
        <div
          :class="['tab__nav orange', {tab__nav__active: selectedKey == 'Difficult', inactive: report.All.Count.Difficult == 0}]"
          @click="selectedKey = 'Difficult'"
        >
          <div class="flex__bs">
            <div>
              <div class="title__16 weight__700 grey__121b">With difficulties</div>
              <div class="title__12 weight__500 grey__151 mt__5">Testers that completed the task with difficulties</div>
            </div>
            <div class="title__32 weight__700">{{report.All.Count.Difficult}}</div>
          </div>
        </div>
        <div
          :class="['tab__nav green', {tab__nav__active: selectedKey == 'Success', inactive: report.All.Count.Success == 0}]"
          @click="selectedKey = 'Success'"
        >
          <div class="flex__bs">
            <div>
              <div class="title__16 weight__700 grey__121b">Success</div>
              <div class="title__12 weight__500 grey__151 mt__5">Testers that completed the task without problems</div>
            </div>
            <div class="title__32 weight__700">{{report.All.Count.Success}}</div>
          </div>
        </div>
      </div>
      <!-- end Tab nav  -->
      <!-- Tab content  -->
      <div class="tab__content">
        <!-- QUANTITATIVE INDICATORS -->
        <div class="title__24  weight__700 text__up grey__121 mt__20">QUANTITATIVE INDICATORS</div>
        <div class="indicators flex__sstr mt__20">
          <!-- --------------  -->
          <div class="line__right">
            <img src="@/assets/img/icons/testers-icon.svg" alt="icon" />
            <div class="title__32 weight__700 mt__40">{{report[selectedKey].QuantitiveIndicators.NumberOfUsers}}</div>
            <div class="title__12 weight__700 grey__151 text__up mt__10">TOTAL TESTERS</div>
          </div>
          <!-- --------------  -->
          <div class="line__right">
            <img src="@/assets/img/icons/timer-icon.svg" alt="icon" />
            <div
              class="title__32 weight__700 mt__40"
            >{{report[selectedKey].QuantitiveIndicators.AverageDuration}}</div>
            <div class="title__12 weight__700 grey__151 text__up mt__10">AVG. DURATION</div>
          </div>
          <!-- --------------  -->
          <div
            class="line__right"
            v-if="report[selectedKey].QuantitiveIndicators.AverageClicksCount"
          >
            <IconClicks />
            <div
              class="title__32 weight__700 mt__40"
            >{{report[selectedKey].QuantitiveIndicators.AverageClicksCount}}</div>
            <div class="title__12 weight__700 grey__151 text__up mt__10">AVG. CLICKS</div>
          </div>
          <!-- --------------  -->
          <div
            class="line__right"
            :class="report[selectedKey].QuantitiveIndicators.AverageSuccessColor == null ? 'red' : report[selectedKey].QuantitiveIndicators.AverageSuccessColor"
          >
            <IconFlag />
            <div
              class="title__32 weight__700 mt__40"
            >{{report[selectedKey].QuantitiveIndicators.AverageSuccess}}%</div>
            <div class="title__12 weight__700 grey__151 text__up mt__10">COMPLETION RATE</div>
          </div>
          <!-- --------------  -->
          <div
            class="line__right"
            :class="report[selectedKey].QuantitiveIndicators.TaskClarityColor == null ? 'red' : report[selectedKey].QuantitiveIndicators.TaskClarityColor"
            v-if="!report[selectedKey].QuantitiveIndicators.HideTaskClarity"
          >
            <IconTaskBlank />
            <div class="title__32 weight__700 mt__40">{{report[selectedKey].QuantitiveIndicators.TaskClarity}}</div>
            <div class="title__12 weight__700 grey__151 text__up mt__10">TASK CLARITY</div>
          </div>
        </div>
        <!-- --------------Own custom Scores-------------- -->
        <Graphs :reports="report[selectedKey].Blocks" />
        <!-- -------------- end Own custom Scores-------------- -->
        <!-- --------------RAWDATA ANSWERES TABLE-------------- -->
        <template v-if="report[selectedKey].RawData">
          <div class="title__24  weight__700 text__up grey__121 mt__60">FEEDBACK & SESSIONS RECORDINGS</div>
          <RawData :selectData="report[selectedKey].RawData" />
        </template>
        <!-- --------------end RAWDATA ANSWERES TABLE-------------- -->
      </div>
      <!-- end Tab content  -->
    </div>
    <!-- END Analyze -->
  </div>
</template>

<script>
import FD from "../../modules/FData";
import Net from "../../modules/net";
import CurrentProjectInfo from "../../mixins/CurrentProjectInfo";
export default {
  props: ["report"],
  mixins: [CurrentProjectInfo],
  data() {
    return {
      selectedKey: "All"
    };
  },
  created() {
    this.$bus.$on("saveShowValue", name => {
      if (this.$_project.Id && this.selectedKey && name) {
        Net.post(
          "/UpdateDashboard.cmd",
          FD({
            project: this.$_project.Id,
            tab: this.selectedKey,
            blockname: name
          })
        )
          .then(() => {
            this.$bus.$emit("switchOfMiniLoader", true);
          })
          .catch(() => {
            debugger;
          });
      }
    });
  }
};
</script>

<style lang="scss" scoped>
.inactive {
  filter: grayscale(2);
  opacity: 0.5;
  pointer-events: none;
}
.indicators > div {
  width: 20%;

  img,
  svg {
    width: 32px;
    height: 32px;
  }
}
.line__right {
  padding-top: 20px;
  padding-bottom: 20px;
  &:not(:first-child) {
    margin-left: 40px;
  }
  &:not(:last-child) {
    padding-right: 40px;
    border-right: 1px solid $color-grey-210;
  }
}
.fake__progress {
  &__full {
    border: 10px solid $color-red;
    border-radius: 50%;
    &.orange {
      border-color: $color-orange;
      .fake__progress__inner {
        background: $color-orange;
      }
    }
    &.blue {
      border-color: $color-teal-blue;
      .fake__progress__inner {
        background: $color-teal-blue;
      }
    }
    &.green {
      border-color: $color-green;
      .fake__progress__inner {
        background: $color-green;
      }
    }
  }
  &__inner {
    width: 100%;
    height: 100%;
    border: 10px solid $color-white;
    color: $color-white;
    background: $color-red;
    border-radius: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__title {
    font-size: 72px;
    line-height: 60px;
    font-weight: 900;
  }
  &__description {
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
  }
}
</style>