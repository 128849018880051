<template>
  <div class="tooltip">
    <IconInfo />
    <div class="tooltip__text" style="width:346px;">
      <div class="title__12">
        <b>What is {{questionInfo.titleMini}} link?</b>
      </div>
      <div class="title__12 weight__500 grey__151">{{questionInfo.tooltipDescription}}</div>
      <img
        :src="require(`@/assets/img/new-project/tooltip-${questionInfo.title}.jpg`)"
        class="mt__10"
      /> 
      <div class="flex__bs mt__10">
        <div class="title__12 weight__500 grey__151">You can try our test {{questionInfo.titleMini}}:</div>
        <div v-if="!copied" class="title__12 link blue" @click="copyLink()">
          <strong>copy {{questionInfo.titleMini}} link</strong>
        </div>
        <div v-else class="title__12 blue">
          <strong>link copied</strong>
        </div>
        <input
          class="hide__el"
          ref="copyThis"
          :value="questionInfo.tooltipLink"
          @focus="selectCode"
        />
      </div>
    </div>
  </div>
</template>


<script>
export default {
  props: ["questionInfo"],
  data() {
    return {
      copied: false
    };
  },
  methods: {
    selectCode() {
      this.$refs.copyThis.select();
    },
    copyLink() {
      this.$refs.copyThis.select();
      document.execCommand("copy");
      this.copied = true;
      this.$refs.copyThis.blur();
    }
  }
};
</script>


<style lang="scss">
.tooltip__right {
  position: relative;
  .tooltip {
    position: absolute;
    bottom: 8px;
    left: -33px;
  }
}
.hide__el {
  position: absolute;
  z-index: -99;
  opacity: 0;
}
</style>