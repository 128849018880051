<template>
  <div class="table__analyze mt__20">
    <table>
      <thead>
        <tr class="text__center">
          <td>Questions</td>
          <td v-for="(data, index) in selectDataReverse" :key="index">{{ data.ProjectContact }}</td>
          <!-- <td v-for="(data, index) in selectDataReverse" :key="index">#{{ renderTitle(selectDataReverse.length - (index+1)) }}</td> -->
        </tr>
      </thead>
      <tbody>
        <!-- Metodology Quests -->
        <slot
          v-if="$_project.CreatedFromMethodology && (!selectDataReverse[0].OpenAnswers.OwnAnswers || Object.keys(selectDataReverse[0].OpenAnswers.OwnAnswers).length == 0)"
        >
          <tr>
            <td>
              <div class="mt__25">
                <b>Gender / Age</b>
              </div>
            </td>
            <td v-for="(data, index) in selectDataReverse" :key="index">
              <div class="flex__sc mt__20" v-if="data.Gender !== null">
                <div class="mr__10">
                  <img
                    v-if="data.Gender == 'Male'"
                    src="@/assets/img/icons/man-avatar.svg"
                    alt="man-icon"
                  />
                  <img
                    v-else-if="data.Gender == 'Female'"
                    src="@/assets/img/icons/woman-avatar.svg"
                    alt="woman-icon"
                  />
                  <img v-else src="@/assets/img/icons/testers-icon.svg" alt="icon" />
                </div>
                <div class="title__12">{{data.Age}} years</div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <b>What part of the task was the most difficult to complete for you?</b>
            </td>
            <td v-for="(data, index) in selectDataReverse" :key="index">
              <!-- TODO: made template for hover -->
              <div class="text__overflow2 question__full">
                <div
                  :class="['question__full__static', {'hide__on__hover':data.OpenAnswers.issues && data.OpenAnswers.issues.length > overflowLength}]"
                >{{data.OpenAnswers.issues}}</div>
                <div
                  class="block question__full__hover"
                  v-if="data.OpenAnswers.issues && data.OpenAnswers.issues.length > overflowLength"
                >{{data.OpenAnswers.issues}}</div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <b>Was there something you like? Specify what exactly</b>
            </td>
            <td v-for="(data, index) in selectDataReverse" :key="index">
              <div class="text__overflow2 question__full">
                <div
                  :class="['question__full__static', {'hide__on__hover':data.OpenAnswers.likes && data.OpenAnswers.likes.length > overflowLength}]"
                >{{data.OpenAnswers.likes}}</div>
                <div
                  class="block question__full__hover"
                  v-if="data.OpenAnswers.likes && data.OpenAnswers.likes.length > overflowLength"
                >{{data.OpenAnswers.likes}}</div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <b>Was there something you dislike? Specify what exactly</b>
            </td>
            <td v-for="(data, index) in selectDataReverse" :key="index">
              <div class="text__overflow2 question__full">
                <div
                  :class="['question__full__static', {'hide__on__hover':data.OpenAnswers.dislikes && data.OpenAnswers.dislikes.length > overflowLength}]"
                >{{data.OpenAnswers.dislikes}}</div>
                <div
                  class="block question__full__hover"
                  v-if="data.OpenAnswers.dislikes && data.OpenAnswers.dislikes.length > overflowLength"
                >{{data.OpenAnswers.dislikes}}</div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <b>Was there anything surprising or unexpected?</b>
            </td>
            <td v-for="(data, index) in selectDataReverse" :key="index">
              <div class="text__overflow2 question__full">
                <div
                  :class="['question__full__static', {'hide__on__hover':data.OpenAnswers.surprising && data.OpenAnswers.surprising.length > overflowLength}]"
                >{{data.OpenAnswers.surprising}}</div>
                <div
                  class="block question__full__hover"
                  v-if="data.OpenAnswers.surprising && data.OpenAnswers.surprising.length > overflowLength"
                >{{data.OpenAnswers.surprising}}</div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <b>Was there something (function, menu point) you guess should be added?</b>
            </td>
            <td v-for="(data, index) in selectDataReverse" :key="index">
              <div class="text__overflow2 question__full">
                <div
                  :class="['question__full__static', {'hide__on__hover':data.OpenAnswers.missing && data.OpenAnswers.missing.length > overflowLength}]"
                >{{data.OpenAnswers.missing}}</div>
                <div
                  class="block question__full__hover"
                  v-if="data.OpenAnswers.missing && data.OpenAnswers.missing.length > overflowLength"
                >{{data.OpenAnswers.missing}}</div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <b>What adjectives would you use to describe your experience with the product?</b>
            </td>
            <td v-for="(data, index) in selectDataReverse" :key="index">
              <div class="text__overflow2 question__full">
                <div
                  :class="['question__full__static', {'hide__on__hover':data.OpenAnswers.impression && data.OpenAnswers.impression.length > overflowLength}]"
                >{{data.OpenAnswers.impression}}</div>
                <div
                  class="block question__full__hover"
                  v-if="data.OpenAnswers.impression && data.OpenAnswers.impression.length > overflowLength"
                >{{data.OpenAnswers.impression}}</div>
              </div>
            </td>
          </tr>
        </slot>
        <!-- Metodology Quests -->
        <!-- OWN Quests -->
        <slot
          v-if="selectDataReverse[0].OpenAnswers.OwnAnswers && Object.keys(selectDataReverse[0].OpenAnswers.OwnAnswers).length != 0"
        >
          <tr v-for="(qs, qi) in selectDataReverse[0].OpenAnswers.OwnAnswers" :key="qi">
            <td>
              <b>{{qi}}</b>
            </td>
            <td v-for="(data, index) in selectDataReverse" :key="index">
              <div class="text__overflow2 question__full">
                <div
                  :class="['question__full__static', {'hide__on__hover':data.OpenAnswers.OwnAnswers[qi] && data.OpenAnswers.OwnAnswers[qi].length > overflowLength}]"
                >{{data.OpenAnswers.OwnAnswers[qi]}}</div>
                <div
                  class="block question__full__hover"
                  v-if="data.OpenAnswers.OwnAnswers[qi] && data.OpenAnswers.OwnAnswers[qi].length > overflowLength"
                >{{data.OpenAnswers.OwnAnswers[qi]}}</div>
              </div>
            </td>
          </tr>
        </slot>
        <!-- end OWN Quests -->
        <tr>
          <td>
            <div class="mt__25">
              <b>Play recordings</b>
            </div>
          </td>
          <td v-for="(data, index) in selectDataReverse" :key="index">
            <a
              v-if="data.Recording"
              class="flex__sc title__12 mt__20"
              :href="data.Recording"
              target="_blank"
            >
              <div class="mr__20">
                <b>Play recording</b>
              </div>
              <IconPlay class="icon__video__play" />
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import CurrentProjectInfo from "../../mixins/CurrentProjectInfo";

export default {
  mixins: [CurrentProjectInfo],
  props: ["selectData"],
  data() {
    return {
      overflowLength: 50
    };
  },
  computed: {
    selectDataReverse() {
      return this.selectData.slice().reverse();
    }
  },
  methods: {
    // renderTitle(index) {
    //   return (index < 9 ? "00" : index < 99 ? "0" : "") + (index + 1);
    // }
  }
};
</script>

<style lang="scss" scoped>
.table__analyze {
  position: relative;
  width: 100%;
  overflow-x: auto;
  padding-bottom: 20px;
  line-height: 20px;
  table {
    width: max-content;
    td {
      font-size: 12px;
      width: 200px;
      padding-top: 10px;
      padding-right: 20px;
      padding-bottom: 10px;
      vertical-align: top;
      &:not(:first-child) {
        padding-left: 20px;
      }
    }
    td:first-child {
      position: sticky;
      left: 0;
      z-index: 10;
      background: $color-white;
      &::after {
        @include pseudo;
        top: 0;
        right: 0;
        width: 2px;
        height: 100%;
        background: $color-dark-grey;
      }
    }
    td:nth-child(2) {
      margin-left: 200px;
    }
    // tr:not(:first-child):not(:nth-child(1))
    //   > td:not(:first-child):not(:last-child)
    td:not(:first-child):not(:last-child) {
      border-right: 1px solid $color-grey-229;
    }
    .text__overflow,
    .text__overflow2 {
      width: inherit;
    }
  }
  img {
    width: 20px;
    height: 20px;
  }
}

.question__full {
  .question__full__hover {
    display: none;
    position: absolute;
    top: -11px;
    left: -11px;
    z-index: 111;
    width: 320px;
    padding: 10px;
  }
  &:hover {
    position: relative;
    overflow: visible;
    > .hide__on__hover {
      color: transparent;
    }
    .question__full__hover {
      display: block;
    }
  }
}
</style>