<template>
  <div class="block mt__40">
    <div class="title__24 weight__700 mt__20 text__center">Project setup</div>
    <div class="title__16 text__center mt__10">What do you want to test now?</div>
    <div class="block__3 mt__80">
      <div class="block__hover" @click="$_setProjectType(30)">
        <div class="image__cover">
          <img src="../../assets/img/new-project/app.jpg" alt="application" />
        </div>
        <h2 class="title__up17 mt__20 text__center">application</h2>
        <div
          class="title__12 weight__500 grey__151 mt__20 text__center"
        >Test specific parts of the live apps (new features, sections, etc.) to identify and solve the issues that might affect user satisfaction.</div>
      </div>
      <div class="block__hover" @click="$_setProjectType(24)">
        <div class="image__cover">
          <img src="../../assets/img/new-project/web.jpg" alt="Website" />
        </div>
        <h2 class="title__up17 mt__20 text__center">Website</h2>
        <div
          class="title__12 weight__500 grey__151 mt__20 text__center"
        >Check the performance of live websites with analysis of gaze movements and facial expressions.</div>
      </div>
      <div class="block__hover" @click="$_setProjectType(31)">
        <div class="image__cover">
          <img src="../../assets/img/new-project/proto.jpg" alt="prototype" />
        </div>
        <h2 class="title__up17 mt__20 text__center">prototype</h2>
        <div
          class="title__12 weight__500 grey__151 mt__20 text__center"
        >Test your website/app prototypes designed in Figma, Sketch, or Invision.</div>
      </div>
    </div>
    <div class="text__center mt__40">
      <router-link to="/projects" class="btn__grey__full btn__200">Back</router-link>
    </div>
  </div>
</template>

<script>
import CreateProject from "../../mixins/CreateProject";
export default {
  mixins: [CreateProject]
};
</script>