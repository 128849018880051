<template>
  <div>
    <div class="title__16 text__center mt__10">Which device is the {{questionInfo.title}} designed for?</div>
    <div :class="['mt__80', chosenProjectType == 30 ? 'block__2': 'block__3']">
      <div class="block__hover" @click="$emit('setProjectTargetDevice', 1);">
        <div class="image__cover">
          <img src="../../assets/img/new-project/android.jpg" alt="android" />
        </div>
        <h2 class="title__up17 mt__20 text__center">android</h2>
        <div
          class="title__12 weight__500 grey__151 mt__20 text__center"
        >Use Android devices (smartphones and tablets) with version 7.0 and upper with an aspect ratio from 16:9 to 21:9 in the vertical and horizontal orientation of the screen.</div>
      </div>
      <!-- ios -->
      <div
        :class="[chosenProjectType == 31 ? 'block' : 'block']"
        @click="/*$emit('setProjectTargetDevice', 2);*/"
      >
        <div class="image__cover">
          <img src="../../assets/img/new-project/ios.jpg" alt="ios" />
          <div class="coming__soon">
            <span class="title__up17">Coming soon</span>
          </div>
        </div>
        <h2 class="title__up17 mt__20 text__center">ios</h2>
        <div
          class="title__12 weight__500 grey__151 mt__20 text__center"
        >Use Apple devices (smartphones from 4.7 inches, tablets from 8 inches) with iOS version 10 and upper in vertical and horizontal screen orientation.</div>
      </div>
      <!-- desktop -->
      <div
        v-if="chosenProjectType !== 30"
        class="block"
        @click="/*$emit('setProjectTargetDevice', 3)*/"
      >
        <div class="image__cover">
          <img src="../../assets/img/new-project/desktop.jpg" alt="desktop" />
          <div class="coming__soon">
            <span class="title__up17">Coming soon</span>
          </div>
        </div>
        <h2 class="title__up17 mt__20 text__center">desktop</h2>
        <div
          class="title__12 weight__500 grey__151 mt__20 text__center"
        >Run testing via Chrome browser extension on your desktop devices with Windows, Linux, and MacOS operating systems. Any device resolution is supported.</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["chosenProjectType"],
  computed: {
    questionInfo() {
      return config[this.questItem.QuestionType];
    }
  }
};
</script>