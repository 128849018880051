<template>
  <div class="block mt__100vh text__center">
    <!--Forgot pass -->
    <ValidationObserver v-slot="{ invalid, validated, passes }" class="sign center">
      <div class="sign__title">Forgot Password</div>
      <template v-if="!thanksMessage">
        <div class="title__20 weight__500 grey__151">
          Please enter your email address.
          <br />You will receive a link to create a new password.
        </div>
      </template>
      <template v-else>
        <div class="title__20 weight__500 grey__151">
          Check your inbox for an email with the password reset link.
          <br />The reset link will be valid for 15 minutes.
        </div>
      </template>

      <form
        class="sign__form forgot__form block__maxw center"
        @submit.prevent="passes(forgotSubmit)"
      >
        <div>
          <ValidationProvider
            name="Email"
            ref="userNone"
            rules="required|email"
            v-slot="{ errors }"
          >
            <div
              class="input__row"
              :class="{'error': errors[0], 'valid': !errors[0], 'non__edit': thanksMessage}"
            >
              <input
                v-model.lazy="forgot.email"
                type="email"
                :class="{ 'focused': forgot.email.length}"
                id="forgotEmail"
              />
              <label for="forgotEmail">Email{{ errors[0] }}</label>
              <IconUser class="input__icon" />
            </div>
          </ValidationProvider>
          <div class="mt__40">
            <button class="btn__blue__full btn__320" v-if="!thanksMessage">Confirm</button>
          </div>
        </div>
        <router-link class="mt__20" to="signin">
          <div class="link__black mt__20" v-if="!thanksMessage">Cancel</div>
          <button class="btn__blue__full btn__320" v-else>Go to Sign In</button>
        </router-link>
      </form>
    </ValidationObserver>
    <!--end Forgot pass -->
    <div class="mt__60"></div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import FD from "../../modules/FData";
import Net from "../../modules/net";

export default {
  data: () => ({
    thanksMessage: false,
    forgot: {
      email: ""
    }
  }),
  components: {
    ValidationObserver,
    ValidationProvider
  },
  methods: {
    forgotSubmit() {
      this.$store.commit("loaderAction", true);
      Net.post(
        "/RemindPasswordCommand.cmd",
        FD(
          {
            email: this.forgot.email
          },
          true
        ),
        {
          "Content-type": "application/x-www-form-urlencoded"
        }
      )
        .then(res => {
          if (res.data.res == 0) {
            this.$refs.userNone.applyResult({
              errors: [" is not registered in UXReality"]
            });
          } else {
            this.thanksMessage = true;
          }
          this.$store.commit("loaderAction", false);
        })
        .catch(() => {});
    }
  }
};
</script>

<style lang="scss">
.forgot__form .non__edit.input__row {
  opacity: 0.5;
  input {
    border-bottom: none;
  }
}
</style>