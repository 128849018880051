var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"title__16 text__center mt__10"},[_vm._v("Choose the type of methodology")]),_c('div',{staticClass:"block__2 mt__80"},[_c('div',{staticClass:"block__hover",staticStyle:{"width":"320px"},on:{"click":function($event){return _vm.$emit('setProjectMethodology', '/CreateMethodology.cmd');}}},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"title__12 weight__500 grey__151 mt__20 text__center"},[_vm._v("Analyze engagement, usability and perception scores automatically with our ready-to-use methodology. Questions are already built in; results are well-ordered on a dashboard. Conduct multiple tests; benchmark your results across iterations of product or other projects. Get insights quickly and easily.")]),_vm._m(2)]),_c('div',{staticClass:"block__hover",staticStyle:{"width":"320px"},on:{"click":function($event){return _vm.$emit('setProjectMethodology', '/CreateProject.cmd');}}},[_vm._m(3),_vm._m(4),_c('div',{staticClass:"title__12 weight__500 grey__151 mt__20 text__center"},[_vm._v("Create your own methodology to ask testers specific questions. Analyze answers and recordings manually to get the most out of project analytics.")]),_vm._m(5)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image__cover"},[_c('img',{attrs:{"src":require("../../assets/img/new-project/UXmetodology.jpg"),"alt":"UXReality methodology"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"title__up17 mt__20 text__center"},[_vm._v(" UXReality "),_c('br'),_vm._v("methodology ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"met-icon flex__bc mt__20 text__center"},[_c('div',{staticClass:"center"},[_c('img',{attrs:{"src":require("@/assets/img/icons/met-1.svg"),"alt":"Methodology"}}),_c('div',{staticClass:"title__10 weight__500 grey__210"},[_vm._v("Methodology")])]),_c('div',{staticClass:"center"},[_c('img',{attrs:{"src":require("@/assets/img/icons/met-2.svg"),"alt":"Benchmarks"}}),_c('div',{staticClass:"title__10 weight__500 grey__210"},[_vm._v("Benchmarks")])]),_c('div',{staticClass:"center"},[_c('img',{attrs:{"src":require("@/assets/img/icons/met-3.svg"),"alt":"Built-in quiz"}}),_c('div',{staticClass:"title__10 weight__500 grey__210"},[_vm._v("Built-in quiz")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image__cover"},[_c('img',{attrs:{"src":require("../../assets/img/new-project/OWNmetodology.jpg"),"alt":"own methodology"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"title__up17 mt__20 text__center"},[_vm._v(" own "),_c('br'),_vm._v("methodology ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"met-icon flex__bc mt__80 text__center"},[_c('div',{staticClass:"center"},[_c('img',{attrs:{"src":require("@/assets/img/icons/met-1-1.svg"),"alt":"Custom questions"}}),_c('div',{staticClass:"title__10 weight__500 grey__210"},[_vm._v("Custom questions")])])])
}]

export { render, staticRenderFns }