export default function (object, str) {
    var form = new FormData;
    var qs = "";
    for (var field in object) {
        if (object[field] !== undefined) {
            form.append(field, object[field]);
            if (qs.length) {
                qs += "&";
            }
            qs += field + "=" + object[field];
        }

    }
    return str ? qs : form;
}