<template>
  <table class="matrix__rating__table">
    <slot v-for="(array, index) in arrays">
      <tr :key="index + '-title'">
        <td colspan="7" class="title__10" style="text-align:left;padding-left: 0;padding-right: 0;"><b>{{array.Name}}</b></td>
      </tr>
      <tr :key="index + '-row'">
        <td class="title__14 weight__500 grey__151 lh__20">{{array.LeffLabel || array.Name}}</td>
        <td
          v-for="(range, index) in ranges"
          :key="index"
          :class="['default__blocky__td', { active: isActive(range, array.Percentage) }]"
        >
          <div class="default__blocky">
            <div
              class="default__blocky__title title__8 grey__151 text__center"
              v-if="isActive(range, array.Percentage)"
            >{{array.Percentage}}</div>
            <div
              class="line__circle"
              ref="circle"
              v-if="isActive(range, array.Percentage)"
              :style="{left: circleLeft(range, array.Percentage)}"
            >
              <div class="line__circle__line"></div>
            </div>
          </div>
        </td>
        <td class="title__14 weight__500 grey__151 lh__20">{{array.RightLabel}}</td>
      </tr>
    </slot>

    <tr>
      <td></td>
      <td v-for="index in 5" :key="index">
        <!-- <img :src="require(`@/assets/img/rating/${type}-${6 - index}.svg`)" /> REVERSED -->
        <img class="row__rating__img" :src="require(`@/assets/img/rating/${type}-${index}.svg`)" />
      </td>
      <td></td>
    </tr>
  </table>
</template>

<script>
export default {
  props: ["type", "arrays"],
  data() {
    return {
      ranges: [
        [0, 1],
        [1.001, 2],
        [2.001, 3],
        [3.001, 4],
        [4.001, 5]
      ]
    };
  },
  created() {
    this.$nextTick(() => {
      this.renderLines();
    });
  },
  watch: {
    arrays() {
      this.$nextTick(() => {
        this.renderLines();
      });
    }
  },
  methods: {
    circleLeft(range, value) {
      var offset = (value - range[0]) / (range[1] - range[0]);
      return "calc(" + (offset * 100 + "%") + " - " + offset * 8 + "px)";
    },
    isActive(range, value) {
      return range[0] <= value && value <= range[1];
    },
    renderLines() {
      var offsets = this.$refs.circle.map(e => {
        return {
          x: e.offsetLeft + e.offsetParent.offsetLeft,
          y: e.offsetTop + e.offsetParent.offsetTop,
          line: e.children[0],
          circle: e
        };
      });
      offsets.map((e, i) => {
        e.line.style.width = 0;
        if (i != 0) {
          e.dist =
            Math.hypot(offsets[i - 1].x - e.x, offsets[i - 1].y - e.y) - 5;
          e.line.style.width = e.dist + "px";
          e.angle =
            (Math.atan2(offsets[i - 1].x - e.x, e.y - offsets[i - 1].y) * 180) /
              Math.PI -
            90;
          e.circle.style.transform = "rotate(" + e.angle + "deg)";
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.matrix__rating__table {
  .default__blocky {
    position: relative;
    width: 36px;
    height: 8px;
    background: $color-light-grey;
    border-radius: $border-radius;
    margin: 0 auto;
    &__td {
      width: 46px;
    }
    &__title {
      position: absolute;
      top: -10px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  tr:not(:first-child) {
    td:first-child {
      padding-top: 7px;
    }
    .default__blocky__td {
      padding-top: 10px;
    }
    td:last-child {
      padding-top: 7px;
    }
  }
  td {
    padding-top: 2.5px;
    padding-bottom: 2.5px;
    line-height: 1;
    &:last-child {
      padding-left: 20px;
    }
    &:nth-child(2).active .default__blocky {
      background: $color-bloody;
    }
    &:nth-child(3).active .default__blocky {
      background: $color-red;
    }
    &:nth-child(4).active .default__blocky {
      background: $color-orange;
    }
    &:nth-child(5).active .default__blocky {
      background: $color-yellow;
    }
    &:nth-child(6).active .default__blocky {
      background: $color-green;
    }
    &:first-child {
      text-align: right;
      padding-right: 20px;
    }
  }
  .row__rating__img {
    margin: 10px 0 0 0;
  }
  .line__circle {
    position: relative;
    width: 8px;
    height: 8px;
    top: 0;
    left: 0%;
    margin: 0;
    background: $color-white;
    border: 2px solid $color-teal-blue;
    border-radius: 15px;
    &__line {
      position: absolute;
      top: 0px;
      left: 4px;
      height: 2px;
      background: $color-teal-blue;
    }
  }
}
</style>