<template>
  <div class="custom__select" :tabindex="tabindex" @blur="open = false">
    <div class="selected" :class="{open: open}" @click="open = !open">
      <label :class="{'label__up': selected.length }">{{label}}</label>
      <div
        ref="selected"
        class="selected_options text__overflow"
        :more="(selected.length > 1 && selected.length - visible !== 0) ? selected.length - visible : false"
      >
        <span
          ref="selected_element"
          v-for="(el, index) in selected"
          class="selected_element"
          :key="el"
        >{{ el }}{{selected.length -1 != index ? ', ' : ''}}</span>
      </div>
      <IconArrowRight />
    </div>
    <div class="items__wrap" :class="{selectHide: !open}">
      <div class="items">
        <CheckRadioDropdown
          :change="optionClick"
          :params="{checks: formatedOptions, type: 'checkbox', value: selected}"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Net from "../../modules/net";

export default {
  data() {
    return {
      selected: null,
      open: false,
      path: [],
      visible: 0
    };
  },
  props: {
    label: { type: String, required: true },
    options: {
      required: true
    },
    params: {
      default: function() {
        return {
          target: null,
          useKey: false
        };
      }
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0
    }
  },
  computed: {
    formatedOptions() {
      return (
        this.options &&
        this.options.map(e => {
          return { name: e, value: this.selected.indexOf(e.toString()) !== -1 };
        })
      );
    },
    width() {
      if (this.$refs.selected) {
        return this.$refs.selected.clientWidth;
      }
    }
  },
  mounted() {
    this.$emit("input", this.selected);
  },
  methods: {
    optionClick(name) {
      var index = this.selected.indexOf(name);
      if (index == -1) {
        this.selected.push(name);
      } else {
        this.selected.splice(index, 1);
      }
    }
  },
  watch: {
    selected() {
      if (this.params.target) {
        Net.update(this.path, this.selected.join(","));
      } else if (this.params.object && this.params.field) {
        Net.update(
          [this.params.field],
          JSON.stringify(this.selected),
          this.params.object
        );
      }

      this.$nextTick(() => {
        if (this.$refs.selected_element) {
          var sm = 0,
            w = this.width;
          for (var el = 0; el < this.$refs.selected_element.length; el++) {
            sm += this.$refs.selected_element[el].getBoundingClientRect().width;
            if (sm > w) {
              this.visible = el;
              return;
            }
          }
          this.visible = this.$refs.selected_element.length;
          return;
        }
      });
    }
  },
  created() {
    var element;
    if (this.params.target) {
      this.path = this.params.target.split(".");
      element = this.$store.state;
      for (var p = 0; p < this.path.length; p++) {
        element = element[this.path[p]];
      }
    } else if (this.params.object && this.params.field) {
      element = this.params.object[this.params.field];
    }
    try {
      this.selected = JSON.parse(
        element.replace(/&amp;/g, "&").replace(/&quot;/g, '"')
      ).filter(e => {
        return !!e;
      });
    } catch (e) {
      this.selected = [];
    }
  }
};
</script>

<style lang="scss">
.selected_options {
  width: calc(100% - 70px);
  &[more]::after {
    content: "+" attr(more) " more";
    position: absolute;
    right: 25px;
    bottom: 3px;
    color: $color-mid-grey;
    font-size: 12px;
  }
}
// For list checkbox
.custom__select {
  .check__row {
    margin-top: 4px;
    label {
      color: $color-black;
    }
  }
}
</style>