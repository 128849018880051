<template>
  <div class="block mt__100vh text__center">
    <!--Sign UP -->
    <ValidationObserver v-slot="{ invalid, validated, passes }" class="sign center">
      <router-link to="/signin" class="title__16 grey__151 flex__ec icon-arr-long">
        <img src="@/assets/img/icons/arr_long.svg" />Sign In
      </router-link>
      <div class="sign__title">Sign Up</div>
      <div class="title__20 weight__500 grey__151">Sign up now and switch to remote user testing empowered with behavioral data</div>

      <form id="sign_up_form" class="sign__form block__maxw center" @submit.prevent="passes(signUpSubmit)">
        <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
          <div class="input__row" :class="{'error': errors[0], 'valid': !errors[0]}">
            <input
              v-model.lazy="reg.name"
              type="text"
              :class="{ 'focused': reg.name.length}"
              id="reg-name"
            />
            <label for="reg-name">
              Name{{ errors[0] }}
            </label>
            <IconUser class="input__icon" />
          </div>
        </ValidationProvider>
        <ValidationProvider name="Job Title" rules="required" v-slot="{ errors }">
          <div class="input__row" :class="{'error': errors[0], 'valid': !errors[0]}">
            <input
              v-model.lazy="reg.job"
              type="text"
              :class="{ 'focused': reg.job.length}"
              id="reg-job"
            />
            <label for="reg-job">
              Job Title{{ errors[0] }}
            </label>
            <IconJob class="input__icon" />
          </div>
        </ValidationProvider>
        <ValidationProvider
          name="Email"
          ref="userDouble"
          rules="required|email"
          v-slot="{ errors }"
        >
          <div class="input__row" :class="{'error': errors[0], 'valid': !errors[0]}">
            <input
              v-model.lazy="reg.email"
              type="email"
              :class="{ 'focused': reg.email.length}"
              id="reg-email"
            />
            <label for="reg-email">
              Email{{ errors[0] }}
            </label>
            <IconMail class="input__icon" />
          </div>
        </ValidationProvider>

        <ValidationProvider name="Password" rules="required" v-slot="{ errors }">
          <div class="input__row" :class="{'error': errors[0], 'valid': !errors[0]}">
            <input
              v-model="reg.password"
              :type="passwordFieldType"
              :class="{ 'focused': reg.password.length}"
              id="reg-password"
            />
            <label for="reg-password">
              Password{{ errors[0] }}
            </label>
            <IconLocked class="input__icon" />
          </div>
        </ValidationProvider>

        <CheckRadio
          :change="switchType"
          :params="{checks: [{name: 'Show characters', value: 0}], type: 'checkbox'}"
        />
        <button class="btn__blue__full btn__320 mt__40" :v-show="!request">Sign Up</button>
      </form>
    </ValidationObserver>
    <!--end Sign UP -->
    <div class="mt__60"></div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import FD from "../../modules/FData";
import Net from "../../modules/net";

export default {
  data: () => ({
    passwordFieldType: "password",
    reg: {
      name: "",
      job: "",
      email: "",
      password: ""
    },
    request: false
  }),
  components: {
    ValidationObserver,
    ValidationProvider
  },
  methods: {
    switchType() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },

    signUpSubmit() {
      
      if(this.request){
        return;
      }

      this.request = true;
      Net.post(
        "/RegisterCommand.cmd",
        FD(
          {
            name: this.reg.name,
            job: this.reg.job,
            login: this.reg.email,
            password: this.reg.password
          },
          true
        ),
        {
          "Content-type": "application/x-www-form-urlencoded"
        }
      )
        .then(res => {
          if (res.data.errormessage) {
            this.$refs.userDouble.applyResult({
              errors: [res.data.errormessage]  // "пробел is already in use"
            });
          } else {
            if (this.ismobile) {
              this.$router.push({ path: "payments" });
            } else {
              this.$router.push({ path: "projects" });
            }

            this.$store.dispatch("setAuthState", true);
            this.$store.dispatch("loadProjects");
          }
        })
        .catch(error => {
          console.log(error.response);
        });
    }
  }
};
</script>