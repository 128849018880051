<template>
  <div>
    <div v-if="cardError" class="title__16 mt__10 text__center red">
      {{ cardValidation.error.message }}
    </div>
    <div v-else class="title__16 mt__10 text__center">
      Please fill in all fields
    </div>
    <div class="card-form flex__cc mt__40">
      <div class="card-list mr__60" v-if="!mobile">
        <div class="card-item" :class="{ '-active': isCardFlipped }">
          <div class="card-item__side -front image__card">
            <div
              class="card-item__focus"
              :class="{ '-active': focusElementStyle }"
              :style="focusElementStyle"
              ref="focusElement"
            ></div>
            <div class="card-item__wrapper">
              <div class="card-item__top">
                <div class="card-item__type">
                  <transition name="slide-fade-up">
                    <img
                      :src="require(`@/assets/img/card/${getCardType}.png`)"
                      v-if="getCardType"
                      :key="getCardType"
                      :alt="getCardType"
                      class="card-item__typeImg"
                    />
                  </transition>
                </div>
              </div>
              <label
                for="cardNumber"
                class="card-item__number"
                ref="cardNumber"
              >
                <template v-if="getCardType === 'amex'">
                  <span v-for="(n, $index) in amexCardMask" :key="$index">
                    <transition name="slide-fade-up">
                      <div
                        class="card-item__numberItem"
                        v-if="
                          $index > 4 &&
                          $index < 14 &&
                          cardNumber.length > $index &&
                          n.trim() !== ''
                        "
                      >
                        *
                      </div>
                      <div
                        class="card-item__numberItem"
                        :class="{ '-active': n.trim() === '' }"
                        :key="$index"
                        v-else-if="cardNumber.length > $index"
                      >
                        {{ cardNumber[$index] }}
                      </div>
                      <div
                        class="card-item__numberItem"
                        :class="{ '-active': n.trim() === '' }"
                        v-else
                        :key="$index + 1"
                      >
                        {{ n }}
                      </div>
                    </transition>
                  </span>
                </template>

                <template v-else>
                  <span v-for="(n, $index) in otherCardMask" :key="$index">
                    <transition name="slide-fade-up">
                      <div
                        class="card-item__numberItem"
                        v-if="
                          $index > 4 &&
                          $index < 15 &&
                          cardNumber.length > $index &&
                          n.trim() !== ''
                        "
                      >
                        *
                      </div>
                      <div
                        class="card-item__numberItem"
                        :class="{ '-active': n.trim() === '' }"
                        :key="$index"
                        v-else-if="cardNumber.length > $index"
                      >
                        {{ cardNumber[$index] }}
                      </div>
                      <div
                        class="card-item__numberItem"
                        :class="{ '-active': n.trim() === '' }"
                        v-else
                        :key="$index + 1"
                      >
                        {{ n }}
                      </div>
                    </transition>
                  </span>
                </template>
              </label>
              <div class="card-item__content">
                <label for="cardName" class="card-item__info" ref="cardName">
                  <div class="card-item__holder">Card Holder</div>
                  <transition name="slide-fade-up">
                    <div class="card-item__name" v-if="cardName.length" key="1">
                      <transition-group name="slide-fade-right">
                        <span
                          class="card-item__nameItem"
                          v-for="(n, $index) in cardName.replace(/\s\s+/g, ' ')"
                          :key="$index + 1"
                          >{{ n }}</span
                        >
                      </transition-group>
                    </div>
                    <div class="card-item__name" v-else key="2">Full Name</div>
                  </transition>
                </label>
                <div class="card-item__date" ref="cardDate">
                  <label class="card-item__dateTitle">Expires</label>
                  <label for="month" class="card-item__dateItem">
                    <transition name="slide-fade-up">
                      <span v-if="cardMonth" :key="cardMonth">{{
                        cardMonth
                      }}</span>
                      <span v-else key="2">MM</span>
                    </transition>
                  </label>
                  <span>/</span>
                  <label for="cardYear" class="card-item__dateItem">
                    <transition name="slide-fade-up">
                      <span v-if="cardYear" :key="cardYear">{{
                        cardYear
                      }}</span>
                      <span v-else key="2">YY</span>
                    </transition>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="card-item__side -back image__card" ref="cardCvv">
            <div class="card-item__band"></div>
            <div class="card-item__cvv">
              <div class="card-item__cvvTitle">CVV</div>
              <div class="card-item__cvvBand">
                <span v-for="(n, $index) in cardCvv" :key="$index">*</span>
              </div>
              <div class="card-item__type">
                <img
                  :src="require(`@/assets/img/card/${getCardType}.png`)"
                  v-if="getCardType"
                  class="card-item__typeImg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-form__inner">
        <div class="input__row mt__0">
          <input
            type="text"
            id="cardNumber"
            :class="{ focused: cardNumber.length }"
            v-mask="generateCardNumberMask"
            v-model="cardNumber"
            v-on:focus="focusInput"
            v-on:blur="blurInput"
            data-ref="cardNumber"
            autocomplete="off"
          />
          <label for="cardNumber">Card Number</label>
        </div>
        <div class="input__row">
          <input
            type="text"
            id="cardName"
            :class="{ focused: cardName.length }"
            v-model="cardName"
            maxlength="25"
            v-on:focus="focusInput"
            v-on:blur="blurInput"
            data-ref="cardName"
            autocomplete="off"
          />
          <label for="cardName">Card Holders</label>
        </div>
        <div class="flex__bc">
          <div class="flex__se mr__30 mob__flex">
            <div class="input__row mr__30">
              <input
                id="month"
                type="text"
                v-model="cardMonth"
                :class="{ focused: cardMonth.length }"
                maxlength="2"
                v-on:focus="focusInput"
                v-on:blur="blurInput"
                data-ref="cardDate"
                autocomplete="off"
              />
              <label for="month">Month</label>
            </div>
            <div class="mr__30">/</div>
            <div class="input__row">
              <input
                id="cardYear"
                type="text"
                v-model="cardYear"
                :class="{ focused: cardYear.length }"
                maxlength="2"
                v-on:focus="focusInput"
                v-on:blur="blurInput"
                data-ref="cardDate"
                autocomplete="off"
              />
              <label for="cardYear">Year</label>
            </div>
          </div>
          <div class="input__row mob__full">
            <input
              id="cardCvv"
              type="password"
              v-model="cardCvv"
              :class="{ focused: cardCvv.length }"
              maxlength="4"
              v-on:focus="flipCard(true)"
              v-on:blur="flipCard(false)"
              data-ref="cardCvv"
              autocomplete="off"
            />
            <label for="cardCvv">CVV</label>
          </div>
        </div>
        <div class="mt__40 flex__bc">
          <button v-if="cancelBtn" class="btn__grey btn__120" @click="cardBack">
            Cancel
          </button>
          <button class="btn__green__full btn__120" @click="sendCard">
            Apply
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import Net from "../../modules/net";
import StripeHelper from "../../modules/stripe";

export default {
  data() {
    return {
      mobile: this.ismobile,
      // cancelBtn: true,
      cardName: "",
      cardNumber: "",
      cardMonth: "",
      cardYear: "",
      cardCvv: "",
      amexCardMask: "#### ###### #####",
      otherCardMask: "#### #### #### ####",
      cardNumberTemp: "",
      isCardFlipped: false,
      focusElementStyle: null,
      isInputFocused: false,
      cardError: false,
      cardValidation: null,
      request: false,
    };
  },
  props: ["amount", "cancelBtn"],
  directives: { mask },
  mounted() {
    this.cardNumberTemp = this.otherCardMask;
    document.getElementById("cardNumber").focus();
  },
  computed: {
    subscription() {
      return (
        this.$store.state.userInfo && this.$store.state.userInfo.Subscription
      );
    },
    getCardType() {
      let number = this.cardNumber;
      let re = new RegExp("^4");
      if (number.match(re) != null) return "visa";

      re = new RegExp("^(34|37)");
      if (number.match(re) != null) return "amex";

      re = new RegExp("^5[1-5]");
      if (number.match(re) != null) return "mastercard";

      re = new RegExp("^6011");
      if (number.match(re) != null) return "discover";

      return "visa"; // default type
    },
    generateCardNumberMask() {
      return this.getCardType === "amex"
        ? this.amexCardMask
        : this.otherCardMask;
    },
  },
  methods: {
    async sendCard() {
      this.cardValidation = await StripeHelper.validateCard({
        number: this.cardNumber,
        cvc: this.cardCvv,
        exp_month: this.cardMonth,
        exp_year: "20" + this.cardYear,
      });

      if (this.cardValidation.error) {
        this.cardError = true;
      } else {
        this.$store.commit("loaderAction", true);
        if (this.request) {
          return;
        }

        this.request = true;
        var data = {
          cardtoken: this.cardValidation.id,
          cardinfo: this.cardNumber.slice(-4),
          cardtype: this.getCardType,
          exp: this.cardMonth + "/" + this.cardYear,
          amount: this.amount,
          // promocode: localStorage.getItem("promo_code")
        };

        var result = (await Net.post("/UpdateUserCreditCardSettings.cmd", data))
          .data;

        if (result.res == 0) {
          this.cardError = true;
          this.cardValidation.error = { message: result.errormessage };
        } else {
          if (this.amount !== "undefined" && this.$route.params.subscription) {
            this.$store.commit("update", {
              target: this.subscription,
              field: "Name",
              value: this.$route.params.subscription,
            });
            this.cancelBtn = true;
            this.$router.push({
              path: "/subscription/success",
            });
            this.$store.dispatch("loadUserData");
          } else {
            this.$parent.$data.pageCard = false;
            this.$store.commit("addCreditCard", data);
          }
          this.cardError = false;
        }
      }

      this.$store.commit("loaderAction", false);
    },
    flipCard(status) {
      this.isCardFlipped = status;
    },
    focusInput(e) {
      this.isInputFocused = true;
      let targetRef = e.target.dataset.ref;
      let target = this.$refs[targetRef];
      if (!this.mobile) {
        this.focusElementStyle = {
          width: `${target.offsetWidth}px`,
          height: `${target.offsetHeight}px`,
          transform: `translateX(${target.offsetLeft}px) translateY(${target.offsetTop}px)`,
        };
      }
    },
    blurInput() {
      let vm = this;
      setTimeout(() => {
        if (!vm.isInputFocused) {
          vm.focusElementStyle = null;
        }
      }, 300);
      vm.isInputFocused = false;
    },
    cardBack() {
      this.$parent.$data.pageCard = false;
    },
  },
};
</script>

<style lang="scss">
@include for-mobile-up {
  .card-list {
    max-width: 430px;
  }
  .card-form {
    &__inner {
      max-width: 430px;
    }
  }
}
@include for-mobile-up {
  .card-item {
    width: 430px;
    min-width: 430px;
    height: 270px;
    position: relative;
    z-index: 2;

    &.-active {
      .card-item__side {
        &.-front {
          transform: perspective(1000px) rotateY(180deg) rotateX(0deg)
            rotateZ(0deg);
        }
        &.-back {
          transform: perspective(1000px) rotateY(0) rotateX(0deg) rotateZ(0deg);
        }
      }
    }

    &__focus {
      position: absolute;
      z-index: 3;
      border-radius: 5px;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      transition: all 0.35s cubic-bezier(0.71, 0.03, 0.56, 0.85);
      opacity: 0;
      pointer-events: none;
      overflow: hidden;
      border: 2px solid rgba(255, 255, 255, 0.65);

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background: rgb(8, 20, 47);
        height: 100%;
        border-radius: 5px;
        filter: blur(25px);
        opacity: 0.5;
      }

      &.-active {
        opacity: 1;
      }
    }

    &__side {
      border-radius: 15px;
      overflow: hidden;
      transform: perspective(2000px) rotateY(0deg) rotateX(0deg) rotate(0deg);
      transform-style: preserve-3d;
      transition: all 0.8s cubic-bezier(0.71, 0.03, 0.56, 0.85);
      backface-visibility: hidden;
      height: 100%;

      &.-back {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        transform: perspective(2000px) rotateY(-180deg) rotateX(0deg)
          rotate(0deg);
        z-index: 2;
        padding: 0;
        height: 100%;

        .card-item__cover {
          transform: rotateY(-180deg);
        }
      }
    }

    &__top {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 40px;
      padding: 0 10px;
    }

    &__type {
      display: flex;
      justify-content: flex-end;
      margin-left: auto;
    }

    &__typeImg {
      width: 100px;
      height: 45px;
      object-position: top right;
    }

    &__info {
      color: $color-white;
      width: 100%;
      max-width: calc(100% - 85px);
      padding: 10px 15px;
      font-weight: 500;
      display: block;
      cursor: pointer;
    }

    &__holder {
      opacity: 0.7;
      font-size: 13px;
      margin-bottom: 6px;
    }

    &__wrapper {
      font-family: "Source Code Pro", monospace;
      padding: 25px 15px;
      position: relative;
      z-index: 4;
      height: 100%;
      text-shadow: 7px 6px 10px rgba(14, 42, 90, 0.8);
      user-select: none;
    }

    &__name {
      font-size: 18px;
      line-height: 1;
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: uppercase;
    }
    &__nameItem {
      display: inline-block;
      min-width: 8px;
      position: relative;
    }

    &__number {
      font-weight: 500;
      line-height: 1;
      color: $color-white;
      font-size: 27px;
      margin-bottom: 35px;
      display: inline-block;
      padding: 10px 15px;
      cursor: pointer;
      @include for-mobile-only {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    &__numberItem {
      width: 16px;
      display: inline-block;

      &.-active {
        width: 30px;
        @include for-mobile-only {
          width: 0;
        }
      }
    }

    &__content {
      color: $color-white;
      display: flex;
      align-items: flex-start;
    }

    &__date {
      flex-wrap: wrap;
      font-size: 18px;
      margin-left: auto;
      padding: 10px;
      display: inline-flex;
      width: 80px;
      white-space: nowrap;
      flex-shrink: 0;
    }

    &__dateItem {
      position: relative;
      span {
        width: 22px;
        display: inline-block;
        cursor: pointer;
      }
    }

    &__dateTitle {
      opacity: 0.7;
      font-size: 13px;
      padding-bottom: 6px;
      width: 100%;
    }
    &__band {
      background: rgba(0, 0, 19, 0.8);
      width: 100%;
      height: 50px;
      margin-top: 30px;
      position: relative;
      z-index: 2;
    }

    &__cvv {
      text-align: right;
      position: relative;
      z-index: 2;
      padding: 15px;
      .card-item__type {
        opacity: 0.7;
      }
    }
    &__cvvTitle {
      padding-right: 10px;
      font-size: 15px;
      font-weight: 500;
      color: $color-white;
      margin-bottom: 5px;
    }
    &__cvvBand {
      height: 45px;
      background: $color-white;
      margin-bottom: 30px;
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 10px;
      color: #1a3b5d;
      font-size: 18px;
      border-radius: 4px;
      box-shadow: 0px 10px 20px -7px rgba(32, 56, 117, 0.35);
    }
  }
  .slide-fade-up-enter-active {
    transition: all 0.25s ease-in-out;
    transition-delay: 0.1s;
    position: relative;
  }
  .slide-fade-up-leave-active {
    transition: all 0.25s ease-in-out;
    position: absolute;
  }
  .slide-fade-up-enter {
    opacity: 0;
    transform: translateY(15px);
    pointer-events: none;
  }
  .slide-fade-up-leave-to {
    opacity: 0;
    transform: translateY(-15px);
    pointer-events: none;
  }

  .slide-fade-right-enter-active {
    transition: all 0.25s ease-in-out;
    transition-delay: 0.1s;
    position: relative;
  }
  .slide-fade-right-leave-active {
    transition: all 0.25s ease-in-out;
    position: absolute;
  }
  .slide-fade-right-enter {
    opacity: 0;
    transform: translateX(10px) rotate(45deg);
    pointer-events: none;
  }
  .slide-fade-right-leave-to {
    opacity: 0;
    transform: translateX(-10px) rotate(45deg);
    pointer-events: none;
  }
}
</style>