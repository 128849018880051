<template>
  <RemotePanel v-if="$_collector && $_collector.type == 24" />
  <OwnPanel v-else-if="$_collector" />
</template>
<script>
import CurrentProjectInfo from "../../mixins/CurrentProjectInfo";
import OwnPanel from "./OwnPanel";
import RemotePanel from "./RemotePanel";

export default {
  mixins: [CurrentProjectInfo],
  components: {
    OwnPanel,
    RemotePanel
  }
};
</script>