<template>
  <div class="cards">
    <div class="flex__bc mob__rev" v-if="!pageCard">
      <h1 class="title__line__blue mt__20">payment methods</h1>
      <router-link class="btn__grey btn__120" to="/payments">Back</router-link>
    </div>

    <div v-if="!pageCard" :class="{ block: !mobile }" class="block__minh">
      <div class="blocks__wrap__three">
        <!-- New card block -->
        <div @click="pageCard = true" class="block__hover block__new">
          <Plus />
          <h2 class="title__up17 text__center">NEW PAYMENT METHOD</h2>
          <div class="title__12 weight__500 grey__151 mt__5 text__center">
            You can add debit card
          </div>
        </div>
        <!-- end New card block -->
        <!-- All card blocks -->
        <template v-if="cards && cards.Account.Cards">
          <div
            class="block__project image__card"
            :class="$route.params.subscription ? 'block__hover' : 'block'"
            v-for="(card, index) in cards.Account.Cards"
            :key="index"
            @click="payCard"
          >
            <div class="icons__bg__grey" @click="popup = !popup">
              <IconBin />
            </div>
            <div class="title__20 weight__500 text__center mt__40">
              •••• •••• •••• {{ card.cardinfo }}
            </div>
            <div class="flex__bc mt__40">
              <div class="card-item__date">
                <div>EXP: {{ card.exp }}</div>
              </div>
              <img
                :src="require(`@/assets/img/card/${card.cardtype}.png`)"
                class="card-item__typeImg"
              />
            </div>
          </div>
        </template>
        <!-- end All card blocks -->
      </div>
      <div class="mt__100"></div>
    </div>
    <!-- card page -->
    <template v-else>
      <h1 class="title__line__green mt__20">payment methods</h1>
      <div class="block">
        <div class="title__24 weight__700 mt__20 text__center">
          Payment Details
        </div>
        <Card :amount="amount" />
        <div class="mt__100"></div>
      </div>
    </template>
    <!-- end card page -->
    <Popup
      v-if="popup"
      :type="'deleteCard'"
      :close="
        () => {
          popup = false;
        }
      "
    />
  </div>
</template>

<script>
import Net from "../../modules/net";

export default {
  data() {
    return {
      mobile: this.ismobile,
      pageCard: false,
      popup: false,
      amount: 0,
      request: false,
    };
  },
  created() {
    if (
      this.$route.params.subscription &&
      this.cards.Account.Cards.length == 0
    ) {
      this.pageCard = true;
    }
    switch (this.$route.params.subscription) {
      case "expert":
        this.amount = 99;
        break;
      case "pro":
        this.amount = 249;
        break;
      default:
        this.amount = 0;
        break;
    }
  },
  computed: {
    cards() {
      return this.$store.state.userInfo;
    },
    isCurrentSubscription() {
      return (
        this.cards &&
        this.cards.Subscription.Name.toLowerCase() ==
          this.$route.params.subscription.toLowerCase()
      );
    },
  },
  methods: {
    async payCard() {
      if (this.isCurrentSubscription) return;
      this.$store.commit("loaderAction", true);
      if (this.$route.params.subscription) {
        if (this.request) {
          return;
        }

        this.request = true;
        var data = {
          usedefaultcard: true,
          amount: this.amount,
          // promocode: localStorage.getItem("promo_code")
        };
        var result = await Net.post("/UpdateUserCreditCardSettings.cmd", data);

        //Subscription Pay
        if (this.amount !== "undefined") {
          this.$store.commit("update", {
            target: this.subscription,
            field: "Name",
            value: this.$route.params.subscription,
            // value: this.amount == 99 ? "Expert" : "Pro"
          });
          this.$router.push({
            path: "/subscription/success",
          });
          this.$store.dispatch("loadUserData");
        } else {
          this.$parent.$data.pageCard = false;
          this.$store.commit("addCreditCard", data);
        }
      }
      this.$store.commit("loaderAction", false);
    },
  },
};
</script>

<style lang="scss">
@include for-mobile-up {
  .cards {
    .block.block__minh {
      padding: 40px 39px;
    }
  }
}
</style>