<template>
  <div class="dropdown">
    <div class="dropdown__current">Website testing</div>
    <div class="dropdown__hide" v-for="question in questions" :key="question.index">
      <router-link :to="question.link" class="mt__10">{{question.name}}</router-link>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      questions
    };
  }
};
const questions = [
  { name: "Website testing", link: "/projects/set-up-project" },
  { name: "App testing", link: "/projects/set-up-project" },
  { name: "Multiple answers", link: "/projects/set-up-project" }
];
</script>
<style lang="scss" scoped>
.dropdown {
  font-size: 14px;
  border-radius: $border-radius-sm;
  background: $color-white;
  padding: 6px 16px 6px 8px;
  text-align: right;
  @include transitionAll;
  &__hide {
    opacity: 0;
    height: 0;
    @include transitionAll;
    a {
      display: block;
    }
  }
  &::after { 
    position: absolute;
    right: 5px;
    top: 12px;
    @include transitionAll;
  }
  @include for-mobile-up {
    &:hover {
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
      .dropdown__hide {
        opacity: 1;
        height: 100%;
      }
      &::after {
        transform: rotate(180deg);
      }
    }
  }
}
</style>