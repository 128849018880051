var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"projectPreview",class:['project__preview', {no__select: _vm.dragTargetItem}],on:{"mousemove":_vm.containerMouseMove,"mouseup":_vm.containerMouseUp,"mouseleave":_vm.containerMouseLeave}},[_vm._l(((_vm.reorderedArray || _vm.questItems)),function(questItem,index){return [(_vm.canAddQuest)?_c('div',{key:index,class:['block__hover flex__sc', 
      {selected: _vm.selectedQuestion == questItem, 
      drag__item: questItem == _vm.dragTargetItem, 
      can__drag: !_vm.collectorStart && !_vm.canAddQuest.CreatedFromMethodology}],on:{"mouseup":() => { _vm.itemMouseUp(questItem)},"mousedown":() => { if(!_vm.collectorStart) {_vm.setClickTarget(questItem)}},"mousemove":(e) => {if(!_vm.collectorStart && !_vm.canAddQuest.CreatedFromMethodology) {_vm.clickTargetItem && _vm.setDragItem(questItem, e) }}}},[_c('div',{class:['icon__quest', {'icon__quest__hover': !_vm.canAddQuest.CreatedFromMethodology && questItem.QuestItem.QuestionType !== 24 && questItem.QuestItem.QuestionType !== 30 && questItem.QuestItem.QuestionType !== 31 && !questItem.QuestItem.ReadOnly && !_vm.collectorStart}]},[(questItem.QuestItem.QuestionType == 24 || questItem.QuestItem.QuestionType == 30 || questItem.QuestItem.QuestionType == 31)?_c('img',{attrs:{"src":require("@/assets/img/icons/IconFlag.svg"),"alt":""}}):_c('img',{attrs:{"src":require("@/assets/img/icons/IconQuestion.svg"),"alt":""}}),(_vm.canAddQuest && !_vm.canAddQuest.CreatedFromMethodology 
          && questItem.QuestItem.QuestionType !== 24 
          && questItem.QuestItem.QuestionType !== 30 
          && questItem.QuestItem.QuestionType !== 31 
          && !questItem.QuestItem.ReadOnly
          && !_vm.collectorStart)?_c('div',{staticClass:"icon__quest__del",attrs:{"title":"Delete Question"},on:{"click":function($event){return _vm.deleteQuest(questItem.QuestItem.Id)},"mousedown":function($event){$event.stopPropagation();},"mouseup":function($event){$event.stopPropagation();},"mousemove":function($event){$event.stopPropagation();}}},[_c('IconBin')],1):_vm._e()]),_c('div',{staticClass:"quest__maxW"},[_c('div',{staticClass:"title__16 text__overflow2"},[(questItem.QuestItem.Name)?[_vm._v(_vm._s(questItem.QuestItem.Name))]:(questItem.QuestItem.QuestionType == 24 || questItem.QuestItem.QuestionType == 30 || questItem.QuestItem.QuestionType == 31)?[_vm._v("Test name")]:[_vm._v("Question name")]],2),_c('div',{staticClass:"title__12 grey__210 mt__10"},[(questItem.QuestItem.QuestionType == 24 || questItem.QuestItem.QuestionType == 30 || questItem.QuestItem.QuestionType == 31)?[_vm._v(_vm._s(_vm.getQuestionInfo(questItem).title)+" testing")]:[_vm._v("q"+_vm._s(_vm.nonNeuroQuestItems.indexOf(questItem) + 1)+". "+_vm._s(_vm.getQuestionInfo(questItem).title))]],2)])]):_vm._e()]}),(!_vm.collectorStart && _vm.canAddQuest && !_vm.canAddQuest.CreatedFromMethodology)?_c('div',{class:[
      'mr__20 project__preview__add block__hover flex__sc', 
      {selected: _vm.addAfter == _vm.questItems[_vm.questItems.length - 1]}, 
      ],on:{"click":function($event){return _vm.addQuest(_vm.questItems[_vm.questItems.length - 1])}}},[_c('Plus',{staticClass:"mr__20"}),_c('div',{staticClass:"title__16"},[_vm._v("Add question")])],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }