<template>
  <div>
    <!-- Width 2col = 473px;  3col = 307px; -->

    <slot v-for="(row, index) in reports">
      <!-- --------------Main title-------------- -->
      <div class="title__24  weight__700 text__up grey__121 mt__40" :key="index + '_row'">
        {{row.Name}}
        <span
          class="blue"
          :style="{ color: row.Color }"
          v-if="row.Percentage > 0"
        >{{row.Percentage}}%</span>
        <span class="blue" :style="{ color: row.Color }" v-else-if="row.Value > 0">{{row.Value}}</span>
      </div>
      <!-- -------------- endMain title-------------- -->
      <div
        class="flex__ss"
        :key="index + '_data'"
        ref="report_table"
        style="flex-wrap:wrap; width: calc(100% + 50px);"
      >
        <slot v-for="(item, iindex) in row.Items">
          <!-- --------------Circle-------------- -->
          <div
            style="width:160px;"
            :class="[(item.Description ? 'mt__30' : 'mt__20'), {mr__50: iindex < row.Items.length - 1}]"
            v-if="item.ItemType == 'Circle' || item.ItemType == 'CircleClicked'"
            :key="iindex + '_chart'"
          >
            <div
              class="title__14 text__center text__overflow3"
              :style="item.Description && row.Name == 'Own custom Scores' ? 'height: 48px;':''"
            >{{item.ItemType == 'CircleClicked' ? item.Name : item.Description}}</div>
            <ProgressCircle
              :class="[row.Name !== 'Own custom Scores' ? 'mt__10' : {'mt__20' : item.Description}]"
              :type="item.ItemType"
              :showVal="item.ShowValue"
              :name="item.ItemType == 'CircleClicked' ? item.Name : ''"
              :description="item.ItemType == 'CircleClicked' ? item.Description : item.Name"
              :value="item.Percentage || item.Value || 0"
              :numeric="item.Value !== undefined"
              :onlyMax="true"
              :legends="item.Values && row.Name == 'Own custom Scores'"
              :values="item.Values ? item.Values.filter(e => { return true }).map(e => ({ 
                    label: e.Name,
                    value: e.Percentage,
                    color: e.Color
                   })) : item.Percentage || item.Value ? [{
                    label: '',
                    value: item.Percentage || item.Value,
                    color: item.Color
                  }] : []"
            />
          </div>
          <!-- --------------Rating-------------- -->
          <div
            v-if="item.ItemType == 'Smile' || item.ItemType == 'Rating'"
            :key="iindex + '_rating'"
            style="min-width:307px; max-width: 50%; flex-grow:1;"
            class="mt__30 mr__50"
          >
            <ProgressRatingLines
              :type="item.ItemType == 'Smile' ? 'smile' : 'star'"
              :arrays="item.Values"
              :label="item.Description"
              :average="item.AverageRating"
              :color="item.Color"
            />
          </div>
          <!-- --------------Matrix rating-------------- -->
          <div
            v-if="item.ItemType == 'SmileMultiple' || item.ItemType == 'RatingMultiple'"
            :key="iindex + '_matrix'"
            style="min-width:307px; max-width: 50%;flex-grow:1;"
            class="mt__30 mr__50"
          >
            <div
              class="title__14 text__overflow3 text__center"
              :style="item.Description ? 'height: 48px;':''"
            >{{item.Description}}</div>
            <ProgressRatingMatrix
              class="mt__20"
              :type="item.ItemType == 'SmileMultiple' ? 'smile' : 'star'"
              :arrays="item.Values"
            />
          </div>
          <!-- --------------Lines-------------- -->
          <div
            v-if="item.ItemType == 'HorrizontalBars'"
            :key="iindex + '_line'"
            style="min-width:307px; max-width: 100%;flex-grow:1;"
            :class="['mr__50',  {'mt__30':item.Description}]"
          >
            <div
              class="title__14 text__overflow3 text__center"
              :style="item.Description && row.Name == 'Own custom Scores' ? 'height: 48px;':''"
            >{{item.Description}}</div>
            <ProgressLines
              :arrays="item.Values"
              :class="[row.Name !== 'Own custom Scores' ? 'mt__10' : {mt__20:item.Description}]"
            />
          </div>
        </slot>
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  props: ["reports"],
  data() {
    return {
    };
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.recalcHeaders();
    // });
  },
  methods: {
    // recalcHeaders() {
    //   for (var i = 0; i < this.$refs.report_table.length; i++) {
    //     (els => {
    //       for (var el = 0; el < els.length; el++) {
    //         els[el].style.minHeight = 0;
    //       }
    //       this.$nextTick(() => {
    //         var maxHeight = Math.max.apply(
    //           null,
    //           els.map(e => {
    //             return e.clientHeight;
    //           })
    //         );
    //         for (var el = 0; el < els.length; el++) {
    //           els[el].style.minHeight = maxHeight + "px";
    //         }
    //       });
    //     })(
    //       [].slice.call(
    //         this.$refs.report_table[i].querySelectorAll(
    //           ".title__14.text__center"
    //         )
    //       )
    //     );
    //   }
    // }
  }
};
</script>