<template>
  <div class="bottom__popup text__center" v-if="!hideMessage">
    <div>
      We use cookies to improve our services for you. By using our website you agree to our use of cookies in accordance with our
      <a
        class="link"
        href="https://uxreality.com/privacy-policy"
        target="_blank"
      >Privacy Policy</a>.
    </div>
    <div class="btn__green btn__160" @click="toLocal">Ok</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      hideMessage: false
    };
  },
  created() {
    if (localStorage.getItem("CookiesMessage") == "true") {
      this.hideMessage = true;
    } else {
      this.hideMessage = false;
    }
  },
  methods: {
    toLocal() {
      localStorage.setItem("CookiesMessage", true);
      this.hideMessage = true;
    }
  }
};
</script>

<style lang="scss">
.bottom__popup {
  position: fixed;
  top: 0; // FIXME mob-desc bottom: 0;
  left: 0;
  width: 100%;
  padding: 12px;
  background: $color-custom-grey;
  box-shadow: 0px -6px 24px rgba(0, 0, 0, 0.15);
  z-index: 666;
  @include for-mobile-up {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn__green {
    padding: 19px 68px;
    border-radius: 63px;
    background: $color-white;
    @include for-mobile-only {
      margin-top: 20px;
    }
    @include for-mobile-up {
      margin-left: 60px;
    }
    &:hover {
      background: $color-green;
    }
  }
}
</style>