import FD from "../modules/FData";
import Net from "../modules/net";
import router from '../router';
import Store from "../store/index";

const data = {
  _chosenProjectType: null,
  _chosenProjectMetodology: null,
  _chosenProjectTargetDevice: 1,
  _projectTitle: "",
  _projectDescription: "",
  _projectMethodology: "/CreateMethodology.cmd",
  _projectLang: "en",
  _project: null
};

export default {
  data() {
    return { tempProjectTitle: '' };
  },
  computed: {
    _chosenProjectType() {
      return data._chosenProjectType;
    },
    _chosenProjectMetodology() {
      return data._chosenProjectMetodology;
    },
    _chosenProjectTargetDevice() {
      return data._chosenProjectTargetDevice;
    },
    _projectTitle() {
      return data._projectTitle;
    },
    _projectDescription() {
      return data._projectDescription;
    },
    _projectMethodology() {
      return data._projectMethodology;
    },
    _projectLang() {
      return data._projectLang;
    },
    _project() {
      return data._project;
    }
  },
  methods: {
    //Step 1
    $_setProjectType(type) {
      data._projectTitle = "";
      data._projectDescription = "";
      data._chosenProjectType = type;
      router.push({
        path: '/project-setup/step-2'
      });
    },
    //Step 2
    $_setProjectTargetDevice(type) {
      data._chosenProjectTargetDevice = type;
      router.push({
        path: '/project-setup/step-3'
      });
    },
    //Step 3
    $_setProjectMethodology(value) {
      data._projectMethodology = value;
      router.push({
        path: '/project-setup/step-4'
      });
    },
    //Step 4
    $_setProjectTitle(value) {
      data._projectTitle = value;
      this.tempProjectTitle = value;
    },
    $_setProjectDescription(value) {
      data._projectDescription = value;
    },
    $_setProjectLang(value) {
      data._projectLang = value;
    },

    $_createNewProject() {
      Store.commit("loaderAction", true);
      Net.post(
        data._projectMethodology,
        FD({
          projectType: 4,
          projectName: data._projectTitle,
          projectDesc: data._projectDescription,
          questionType: data._chosenProjectType,
          targetPlatform: data._chosenProjectTargetDevice,
          deviceType: data._chosenProjectTargetDevice, //это нужно для прототипа, дубляж
          language: data._projectLang
        })
      )
        .then(e => {
          Store.commit("addProject", e.data.ProjectData);
          data._project = e.data.ProjectData;
          router.push({
            path: '/project-setup/step-5'
          });
          Store.commit("loaderAction", false);
        })
        .catch(error => {
          debugger;
        });
    }
    //Step 5
  }
}