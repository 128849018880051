<template>
  <div>
    <div class="title__14 weight__500 grey__151 lh__20">Instruction</div>
    <Input
      :params="{label: 'Enter the text of your instruction here', target: 'currentQuestItem.QuestItem.Name', textarea: 'true'}"
    />
  </div>
</template>

<script>
import ReloadProxy from "../../mixins/ReloadProxy"

export default {
  props: ["alternatives"],
  mixins: [ReloadProxy]
};
</script>