<template>
  <div class="chart__linearea">
    <div class="chart__linearea__label">
      <span class="blue">emotion</span> &nbsp;vs
      <span class="purple">KPD</span>
    </div>
    <div class="chart__linearea__bg">
      <!-- del that  -->
      <!-- <svg viewBox="0 0 554 120"> -->
      <svg viewBox="0 0 300 75">
        <!-- Area  -->
        <linearGradient id="areaGradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" stop-color="#CF81FD" />
          <stop offset="100%" stop-color="#fff" />
        </linearGradient>

        <path
        class="area"
          fill="url(#areaGradient)"
          stroke="none"
          d="M8 69 L8 66.99999L19.641748005622645 60.22810570181641S28.285714285714285 55.2 35.82067775094163 48.62547906097417L41.03646510620122 44.07452093902583S48.57142857142857 37.5 55.08992110605938 29.91651431886613L62.33865032251205 21.483485681133867S68.85714285714286 13.899999999999999 75.37563539177367 21.48348568113387L82.62436460822633 29.916514318866128S89.14285714285714 37.5 94.80899207622157 29.260162931410164L103.76243649520698 16.239837068589836S109.42857142857142 8 113.83725918091662 16.975715698612643L125.30559796194052 40.32428430138735S129.71428571428572 49.3 138.35825014238043 44.271891114361814L141.3560355719053 42.52810888563818S150 37.5 155.66613493336445 45.739837068589836L164.61957935234983 58.760162931410164S170.28571428571428 67 180.28571428571428 67L180.57142857142856 67S190.57142857142856 67 200.1735463986356 64.20727136434049L201.25502502993578 63.892728635659516S210.85714285714283 61.1 215.26583060948803 52.124284301387355L226.73416939051194 28.775715698612643S231.14285714285714 19.799999999999997 236.11480083183224 28.476391874141044L246.4566277395963 46.523608125858956S251.42857142857142 55.2 255.83725918091662 46.22428430138736L267.3055979619405 22.875715698612645S271.7142857142857 13.899999999999999 275.2830155813406 23.241529164756194L292 67.00001L292 69 Z"
        />
        <!-- end Area  -->
        <!-- Line -->
        <defs>
          <linearGradient id="lineGradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stop-color="#6EC700" />
            <stop offset="30%" stop-color="#6EC700" />
            <stop offset="31%" stop-color="#00BCEB" />
            <stop offset="70%" stop-color="#00BCEB" />
            <stop offset="71%" stop-color="#F30642" />
            <stop offset="100%" stop-color="#F30642" />
          </linearGradient>
        </defs>

        <polyline
          fill="none"
          stroke="url(#lineGradient)"
          stroke-width="2"
          points="-30 6 0 5 30 50 60 40 90 30 120 66 150 17 180 13 210 50 240 72 270 32 300 2 330 9"
        />
        <!-- end Line -->
        
      </svg>
      <!-- del that  -->
    </div>
  </div>
</template>

<script>
export default {
  props: ["scenarios"]
};
</script>

<style lang="scss" scoped>
.area {
  opacity: 0.5;
}
.chart__linearea {
  position: relative;
  width: 100%;
  &__label {
    font-weight: 700;
    text-align: right;
  }
  &__bg {
    height: 120px;
    background-image: linear-gradient(
      90deg,
      $color-grey-229,
      $color-grey-229 1%,
      transparent 5%,
      transparent 100%
    );
    background-size: 35px 1px;
  }
}
</style>