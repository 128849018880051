<template>
  <div class="popup__delete">
    <div class="popup__title">Are you sure?</div>
    <div
      class="popup__description"
    >By running this process, you'll permanently remove project content. You won't be able to retrieve the deleted data.</div>
    <div class="popup__delete__confirm input__row">
      <!-- TODO: autofocus not work -->
      <input
        type="text"
        v-model="text"
        autofocus="true"
        class="focused"
        autocomplete="off"
      />
      <label>
        Type
        <span>DELETE</span> to confirm
      </label>
    </div>
    <div class="popup__btns">
      <div class="btn__black" :disabled="text.trim() != 'DELETE'" @click="deleteProject">Delete</div>
      <div class="btn__grey" @click="close">Cancel</div>
    </div>
  </div>
</template>

<script>
import FD from "../../modules/FData";
import Net from "../../modules/net";

export default {
  data() {
    return {
      text: ""
    };
  },
  props: ["close", "projectId"],
  methods: {
    handleKeyDown(e) {
      //for enter
      if (e.code === "Enter" && this.text.trim() == "DELETE") {
        this.deleteProject();
      }
    },
    deleteProject() {
      this.$store.commit("loaderAction", true);
      Net.post("/DeleteProject.cmd", FD({ project: this.projectId }))
        .then(() => {
          this.$store.commit("deleteProjectById", this.projectId);
          this.close();
          this.$store.commit("loaderAction", false);
        })
        .catch(() => {
          debugger;
        });
    }
  },
  created() {
    //for enter
    window.addEventListener("keydown", this.handleKeyDown);
  },
  destroyed() {
    //for enter
    window.removeEventListener("keydown", this.handleKeyDown);
  }
};
</script>