<template>
  <div>
    <div class="title__14 weight__500 grey__151 lh__20">Smiley Rating</div>
    <Input
      :params="{label: 'Question', target: 'currentQuestItem.QuestItem.Name', textarea: 'true'}"
    />
    <slot v-for="(alt, index) in alternatives">
      <div class="row" :key="alt.Id">
        <div>
          <Input
            :params="{label: 'Option ' + (index + 1), target: 'currentQuestItem.Alternatives.'+ index +'.Name'}"
          />

          <div
            class="icons__bg__grey input__icon"
            v-if="alternatives.length > 1"
            @click="deleteOption(alt.Id)"
          >
            <IconBin />
          </div>
        </div>

        <Input
          class="rating_label"
          :params="{label: 'rating label MIN', target: 'currentQuestItem.Alternatives.'+ index +'.LeftLabel'}"
        />
        <img class="rating_label_image" src="../../assets/img/smiles.png" />
        <Input
          class="rating_label rating_label_reverse"
          :params="{label: 'rating label MAX', target: 'currentQuestItem.Alternatives.'+ index +'.RightLabel'}"
        />
      </div>
    </slot>

    <div class="row row__add">
      <span class="add__option" @click="addOption">Add new option</span>
    </div>
  </div>
</template>

<script>
import AlternativeActions from "../../mixins/AlternativeActions";
import ReloadProxy from "../../mixins/ReloadProxy";

export default {
  props: ["alternatives"],
  mixins: [AlternativeActions, ReloadProxy]
};
</script>

<style lang="scss">
.row {
  position: relative;
  margin-top: 20px;
  align-items: center;

  .icons__bg__grey {
    opacity: 0;
    position: absolute;
    right: 0;
    top: 5px;
  }

  &:focus,
  &:hover {
    .icons__bg__grey {
      opacity: 1;
    }
  }

  &__add {
    margin-top: 46px;
    .add__option {
      position: relative;
      @include transitionAll;
      cursor: pointer;
      &::after {
        @include pseudo;
        left: 0;
        bottom: -2px;
        width: 100%;
        height: 1px;
        background: $color-dark-grey;
        @include transitionAll;
      }
      &:hover {
        color: $color-teal-blue;
        &::after {
          background: $color-teal-blue;
        }
      }
    }
  }
}

.rating_label {
  display: inline-block;
  width: calc(50% - 73px);
  margin-top: 30px;
  input {
    font-size: 14px;
  }
  &_image {
    display: inline-block;
    width: 125px;
    height: 20px;
    margin: 0 10px -12px 10px;
  }
  &_reverse input,
  &_reverse label {
    text-align: right;
    width: 100%;
  }
}
</style>