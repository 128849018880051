 <template>
  <div class="projects">
    <!-- <div v-if="!newProject"> -->
      <h1 class="title__line__blue mt__20">My Projects</h1>
      <div class="blocks__wrap__four">
        <!-- New project block -->
        <router-link to="/project-setup/step-1" class="block__hover block__new">
          <Plus />
          <h2 class="title__up17 text__center">New Project</h2>
          <div class="title__12 weight__500 grey__151 mt__10 text__center">Click to create a new project</div>
          <div class="mt__60"></div>
        </router-link>
        <!-- end New project block -->
        <!-- All projects blocks -->
        <div
          class="block__hover block__project"
          v-for="project in projects"
          :key="project.Id"
          @mouseover="hoverBlock(project.Id, true)"
          @mouseleave="hoverBlock(project.Id, false)"
        >
          <router-link :to="linkRoute(project)">
            <!-- <router-link :to="'/project/' + project.Id + '/set-up-project'"> -->
            <div class="image__cover">
              <img :src="project.AvatarData.PreviewUrl" :alt="project.Name" />
            </div>
            <div class="flex__bc title__10 weight__500 grey__210 mt__10">
              <div style="width:20px;height:20px;">
                <!-- <img v-if="project.TargetPlatform == 1" src="@/assets/img/icons/android.svg" alt="android" />
                <img v-else-if="project.TargetPlatform == 2" src="@/assets/img/icons/ios.svg" alt="ios" />
                <img v-else src="@/assets/img/icons/desktop.svg" alt="desktop" />-->
              </div>
              <div class="text__rigth">
                <b>created:</b>
                <br />
                {{getCreatedText(project)}}
              </div>
            </div>
            <h2 class="title__up17 mt__25 text__center text__overflow">{{project.Name}}</h2>
          </router-link>
          <div class="block__hover__change">
            <!-- <div class="title__12 weight__500 grey__151 text__center text__overflow2">{{project.Description}}</div> -->
            <div
              class="title__12 weight__500 grey__151 text__center text__overflow2"
              v-show="!isHover(project.Id)"
            >{{project.Description}}</div>
            <div class="flex__cc" v-show="isHover(project.Id)">
              <!-- <div class="icons__bg__grey">
                <IconCopy />
              </div>
              <div class="icons__bg__grey">
                <IconShare />
              </div>-->
              <!-- <router-link
                v-if="project.Collectors[0] && 
                (project.Collectors[0].completes > 0 || 
                project.Collectors[0].verified == true)"
                :to="'/project/' + project.Id + '/collector/' + project.Collectors[0].Id + '/analyze-results'"
                class="icons__bg__grey mr__10"
              >
                <IconPie />
              </router-link>-->
              <div class="icons__bg__grey" @click="projectId = project.Id; popup = !popup ">
                <IconBin />
              </div>
            </div>
          </div>
          <ProgressLineFORProject
            :progress="project.totalCollected"
            :progressOf="project.totalSampleSize"
            :progressPercent="project.averagePercentage"
            :collectorType="project.Collectors[0] && project.Collectors[0].type"
            :finished="project.Collectors[0] && project.Collectors[0].status"
          />
        </div>
        <!-- end All projects blocks -->
      </div>
      <LoaderMini v-if="miniLoader" class="mt__20" />
    <!-- </div> -->
    <!-- <NewProject class="mt__40 block__minh" v-else @statusNewProject="statusNewProject" /> -->
    <Popup
      v-if="popup"
      :type="'deleteProject'"
      :close="() => {popup = false}"
      :projectId="projectId"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {
      hover: false,
      hoverID: null,
      projectId: null,
      // newProject: false,
      popup: false,
      miniLoader: false
    };
  },
  created() {
    window.addEventListener("scroll", this.onScroll);
    this.$store.commit("cleanup");
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScroll);
  },
  computed: {
    projects() {
      return this.$store.state.projects;
    }
  },

  methods: {
    getCreatedText(project) {
      var pDate = new Date(project.CreateDate);
      var diff = Date.now() - project.CreateDate;
      if (diff > 864000000) {
        //10 days
        return pDate.toLocaleDateString();
      } else {
        var minutes = diff / 1000 / 60;
        var hours = minutes / 60;
        var days = hours / 24;
        if (days > 1) return (days ^ 0) + "d. ago";
        if (hours > 1) return (hours ^ 0) + "h. ago";
        return (minutes ^ 0) + "m. ago";
      }
    },
    linkRoute(project) {
      if (
        project.Collectors[0] &&
        project.Collectors[0].type == 34 &&
        (project.Collectors[0].completes > 5 ||
          project.Collectors[0].verified == true)
      ) {
        return "/project/" + project.Id + "/collector/" + project.Collectors[0].Id + "/analyze-results";
      } else {
        return "/project/" + project.Id + "/set-up-project";
      }
    },
    onScroll(e) {
      if (!this.$store.state.allProjectsLoaded && this.projects.length) {
        if (
          document.body.scrollHeight - (window.scrollY + window.innerHeight) <
            300 &&
          !this.loading
        ) {
          this.loading = true;
          this.loadMore();
        }
      }
      // TODO: http://prntscr.com/sxqmnj
    },
    loadMore() {
      this.miniLoader = true;
      this.$store.dispatch("loadProjects", () => {
        this.miniLoader = false;
      });
    },
    hoverBlock(project, status) {
      this.hoverID = status ? project : null;
    },
    isHover(id) {
      return this.hoverID === id;
    },
    statusNewProject(value) {
      this.newProject = value;
    }
  }
};
</script>

<style lang="scss">
.block__new {
  cursor: pointer;
}
</style>