<template>
  <div class="block mt__100vh text__center">
    <!--Sign in -->
    <div class="flex__bs">
      <a href="https://uxreality.com/" target="_blank" class="title__16 grey__151 flex__ec icon-arr-long">
        <img src="@/assets/img/logo_reality_blue.svg" style="width:115px;height:40px;" />
      </a>
      <router-link to="/registration" class="title__16 grey__151 flex__ec icon-arr-long">
        <img src="@/assets/img/icons/arr_long.svg" />
        Sign Up
      </router-link>
    </div>
    <ValidationObserver v-slot="{ invalid, validated, passes }" class="sign center">
      <div class="sign__title">Sign In</div>
      <div class="title__20 weight__500 grey__151">Hello there! Sign In and start managing your research projects</div>

      <form
        id="sign_in_form"
        class="sign__form block__maxw center"
        @submit.prevent="passes(signSubmit)"
      >
        <ValidationProvider
          name="Email"
          rules="required|email"
          v-slot="{ errors }"
          ref="userNotFound"
        >
          <div class="input__row" :class="{'error': errors[0], 'valid': !errors[0]}">
            <input
              v-model.lazy="sign.email"
              type="email"
              class="focused"
              :class="{ 'focused': sign.email.length}"
              id="Email"
            />
            <label for="email">Email{{ errors[0] }}</label>
            <IconUser class="input__icon" />
          </div>
        </ValidationProvider>

        <ValidationProvider
          ref="invalidPassword"
          name="Password"
          rules="required"
          v-slot="{ errors }"
        >
          <div class="input__row" :class="{'error': errors[0], 'valid': !errors[0]}">
            <input
              v-model="sign.password"
              :type="passwordFieldType"
              class="focused"
              :class="{ 'focused': sign.password.length}"
              id="password"
            />
            <label for="password">Password{{ errors[0] }}</label>
            <IconLocked class="input__icon" />
          </div>
        </ValidationProvider>

        <CheckRadio
          :change="switchType"
          :params="{checks: [{name: 'Show characters', value: 0}], type: 'checkbox'}"
        />
        <div class="mt__40">
          <button class="btn__blue__full btn__320">Sign In</button>
        </div>
        <router-link class="link__black mt__20" to="forgotpass">Forgot password</router-link>
      </form>
    </ValidationObserver>
    <!--end Sign in -->
    <div class="mt__60"></div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import FD from "../../modules/FData";
import Net from "../../modules/net";

export default {
  data: () => ({
    passwordFieldType: "password",
    sign: {
      email: "",
      password: ""
    }
  }),
  components: {
    ValidationObserver,
    ValidationProvider
  },
  methods: {
    switchType() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    signSubmit() {
      Net.post(
        "/identity/connect/token",
        FD(
          {
            username: this.sign.email,
            password: this.sign.password,
            grant_type: "password",
            client_id: "cooltool",
            client_secret: "cooltool_secret",
            scope: "api"
          },
          true
        ),
        {
          "Content-type": "application/x-www-form-urlencoded"
        }
      )
        .then(res => {
          if (this.ismobile) {
            this.$router.push({ path: "subscription" });
          } else {
            this.$router.push({ path: "projects" });
          }

          this.$store.dispatch("setAuthState", true);
          this.$store.dispatch("loadProjects");
        })
        .catch(error => {
          if (error.response.status == 400) {
            this.$refs.userNotFound.applyResult({
              errors: [" or Password is incorrect"]
            });
            this.sign.password = "";
            this.$refs.invalidPassword.reset();
          }
        });
    }
  }
};
</script>