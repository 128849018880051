import { extend } from "../node_modules/vee-validate";
import { min, required, email, confirmed } from "../node_modules/vee-validate/dist/rules";

// Install rules
extend('required', {
    ...required,
    message: ' is required'
  });
extend("min", min);
extend("email", {
    ...email,
    message: ' has a wrong format'
});
extend("confirmed", {
  ...confirmed,
  message: 'does not match'
});