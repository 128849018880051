<template>
  <div class="block__padding" v-if="$_collector">
    <div class="flex__bs">
      <div class="title__20 weight__500 text__up">INVITE YOUR OWN TESTERS</div>
      <div class="flex__ec own__progress" v-if="$_collector.status !== 0">
        <ProgressCircle
          class="mr__20"
          style="width: 90px;"
          :value="completed"
          :values="[{value: completed, color: '#00BCEB'}]"
          description="completed"
        />
        <ProgressCircle
          class="mr__20"
          style="width: 90px;"
          :value="$_collector.completes"
          :values="[{value: ($_collector.completes / $_collector.SelectedNumberOfResponses * 100), color: '#6EC700'}]"
          :numeric="true"
          description="finished"
        />
        <ProgressCircle
          style="width: 90px;"
          :value="$_collector.partial"
          :values="[{value: ($_collector.partial / $_collector.SelectedNumberOfResponses * 100), color: '#FF8300'}]"
          :numeric="true"
          description="now testing"
        />
      </div>
    </div>
    <div class="title__18 weight__500" :class="{'mt__40' : $_collector.status == 0}">
      <b>Sample Size</b>
    </div>
    <div
      class="title mt__5"
      v-if="$_collector.status == 2"
    >If you want to continue testing, move the slider to the right and increase the sample size.</div>
    <div class="title mt__5" v-else>Please select expected number of participants.</div>

    <BlockRange
      :rangeArray="[10,20,30,40,50,60,70,80,90,100]"
      :valuesArray="[10,20,30,40,50,60,70,80,90,100]"
      :locked="Math.ceil(($_collector.completes + $_collector.partial) / 10) - 1"
      :object="$_collector"
      :class="{'trial__version' : $_trial}"
      field="SelectedNumberOfResponses"
      :disabled="$_project.ReadOnly"
    />
    <div>
      <div :disabled="disableParts" class="block__links">
        <div class="title__18 weight__500 mt__40">
          <b>Invite participants to collect responses</b>
        </div>
        <div class="title mt__10">
          <template v-if="$_project.TargetPlatform == 3">
            Share the link below with your
            <b>testers</b> to let them start the survey. Testers should install the
            <a
              href="https://chrome.google.com/webstore/detail/uxreality-for-chrome/ibpbamgbgdeaiabiedppnlpiegijnbdp?utm_source=chrome-ntp-icon"
              target="_blank"
              class="link blue"
            >
              <b>UXReality Extention</b>
            </a> for
            <b>Chrome browser.</b>
          </template>
          <template v-else>
            Share the link below with your
            <b>testers</b> to let them start the survey. Testers should install the
            <a
              href="https://play.google.com/store/apps/details?id=com.uxreality"
              target="_blank"
              class="link blue"
            >
              <b>UXReality app.</b>
            </a>
            <br />Please don't forget this test is intended
            <b>for Android only.</b>
          </template>
        </div>
        <CopyCode
          class="mt__20"
          :code="$_project.ReadOnly ? '***************************************************************' : $_collector.invitationUrl + ($_collector.status == 0 ? '&preview=1' : '')"
          :label="$_collector.status == 0 ? 'Preview link' : 'Link to share'"
          :disabled="$_project.ReadOnly"
        />
        <template v-if="$_project.TargetPlatform !== 3 && !disableParts">
          <div class="title mt__40">
            Or send this code to your testers. To start the survey, testers should install the
            <a
              href="https://play.google.com/store/apps/details?id=com.uxreality"
              target="_blank"
              class="link blue"
            >
              <b>UXReality app</b>
            </a> and insert the code in the app when asked.
          </div>
          <CopyCode
            class="mt__20"
            :code="$_project.ReadOnly ? '*******' : $_collector.invitationCode"
            :label="'Study ID'"
            :disabled="$_project.ReadOnly"
          />
        </template>
      </div>

      <div v-if="$_project.ReadOnly" class="flex__bs mt__40">
        <div class="mr__40">
          Note, this is a demo project.
          We've already collected insights to demonstrate how the completed project looks.
        </div>
        <router-link
          class="btn__green__full btn__210"
          :to="'/project/'+ $_project.Id +'/invite-testers'"
        >Show All Methods</router-link>
      </div>

      <!-- Start buttons -->
      <div class="text__center mt__30" v-if="$_collector.status == 0">
        <template v-if="$_trial">
          <div>
            You have 7 days or 20 interviews
            <b>for free.</b>
          </div>
          <div class="mt__10">
            Press
            <b>'Try it for free'</b> button to collect insights without any payments.
          </div>
        </template>
        <template v-else>
          <div>
            Press the
            <b>Start Now</b> button to get a link for testers!
          </div>
        </template>
        <div class="flex__cc mt__40">
          <div class="btn__grey btn__160 mr__40" @click="$_deleteCollector">Back</div>
          <div
            class="btn__green__full btn__160"
            @click="$_startCollector"
          >{{$_trial ? 'Try it for free' : 'Start Now'}}</div>
        </div>
      </div>
      <!-- end Start buttons -->

      <div class="mt__40" v-show="openBlock">
        <div class="title__14 mt__10" style="line-height: 16px;">
          <b>Backlinks to track collector's progress with own panel</b>
        </div>
        <div class="col__2">
          <div>
            <Input
              :params="{label: 'Link for complete interviews', object: $_collector.BacklinkSettings, field: 'LinkForComplete'}"
              :disabled="$_project.ReadOnly"
            />
            <Input
              :params="{label: 'Link for disqualified interviews', object: $_collector.BacklinkSettings, field: 'LinkForDisqualified'}"
              :disabled="$_project.ReadOnly"
            />
          </div>
          <div>
            <Input
              :params="{label: 'Link for over-quota interviews', object: $_collector.BacklinkSettings, field: 'LinqForOverquota'}"
              :disabled="$_project.ReadOnly"
            />
          </div>
        </div>
      </div>
      <div :class="[openBlock ? 'mt__30' : 'mt__60']"></div>

      <div
        :class="['showinfo__button flex__cc', {'opened': openBlock}]"
        @click="openBlock = !openBlock"
      >
        <div class="showinfo__button__icon">
          <IconArrowRight />
        </div>
        <div class="title__16 grey__151">
          <b>Set up backlinks for panel providers</b>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CurrentProjectInfo from "../../mixins/CurrentProjectInfo";

export default {
  mixins: [CurrentProjectInfo],
  data() {
    return {
      openBlock: false
    };
  },
  computed: {
    completed() {
      return (
        ((this.$_collector.completes /
          this.$_collector.SelectedNumberOfResponses) *
          100) ^
        0
      );
    },
    disableParts() {
      return (
        (this.$_collector.status == 2 &&
          this.$_collector.completes >
            this.$_collector.SelectedNumberOfResponses - 1)
      );
    }
  },
  methods: {
    showAllCollectors() {}
  }
};
</script>

<style lang="scss">
.own__progress {
  .progress__circle {
    &__abs {
      transform: translate(-50%, -16px);
    }
    &__title {
      font-size: 20px;
    }
    &__description {
      font-size: 11px;
      letter-spacing: 0;
    }
  }
}
.block__links[disabled] .code__input {
  color: transparent;
}
.showinfo__button {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  background: $color-white;
  box-shadow: $shadow;
  border: 1px solid transparent;
  border-radius: $border-radius;
  padding: 5px 15px;
  cursor: pointer;
  @include transitionAll;
  @include for-mobile-only {
    width: calc(100% - #{$padding-main * 2});
  }
  @include for-mobile-up {
    white-space: nowrap;
  }
  &:hover {
    background: $color-mid-grey;
    .title__16 grey__151 {
      color: $color-white;
    }
    svg path {
      fill: $color-white;
    }
  }
  &.opened {
    .showinfo__button__icon {
      transform: rotate(270deg);
    }
  }
  .title__16 grey__151 {
    @include transitionAll;
  }
  &__icon {
    margin: 0 10px -1px 0;
    transform: rotate(90deg);
    // @include transitionAll;
    svg path {
      fill: $color-mid-grey;
      @include transitionAll;
    }
  }
}
</style>