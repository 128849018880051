<template>
  <div class="progress__flower text__center">
    <!-- Chart -->
   
    <svg class="flower" width="500" height="500" viewBox="0 0 500 500">
      <path
        :class="['flower__segment', {'flower__segment__active': index == selected}]"
        v-for="(segment, index) in segments"
        :key="index"
        fill="none"
        :stroke="segment.Color"
        :stroke-width="170 + (segment.Percentage * 3)"
        :d="describeArc(250, 250, 100, segment.From, segment.To )"
        @click="$emit('selectedChart', index)"
      />
    </svg>

    <!-- Chart -->

    <!-- Inner info -->
    <div class="progress__flower__inner flex__cc center">
      <div
        class="title__24 weight__700"
        :style="'color: ' + scenarios[selected].Color"
      >{{scenarios[selected].Percentage}}%</div>
      <div class="title__12 weight__500 grey__151">
        <b>{{scenarios[selected].Name}}</b>
      </div>
    </div>
    <!-- Inner info -->
  </div>
</template>
<script>
export default {
  props: ["scenarios", "selected"],
  data() {
    return {};
  },
  computed: {
    segments() {
      var prev = 0;
      return this.scenarios.map(e => {
        prev += e.Percentage;
        return {
          Color: e.Color,
          From: (prev - e.Percentage) * 3.6,
          To: prev * 3.6,
          Percentage: e.Percentage,
          Name: e.Name
        };
      });
    }
  },
  methods: {
    describeArc(x, y, radius, startAngle, endAngle) {
      var start = this.polarToCartesian(x, y, radius, endAngle);
      var end = this.polarToCartesian(x, y, radius, startAngle);
      var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
      var d = [
        "M",
        start.x,
        start.y,
        "A",
        radius,
        radius,
        0,
        largeArcFlag,
        0,
        end.x,
        end.y
      ].join(" ");
      return d;
    },
    polarToCartesian(centerX, centerY, radius, angleInDegrees) {
      var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
      return {
        x: centerX + radius * Math.cos(angleInRadians),
        y: centerY + radius * Math.sin(angleInRadians)
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.flower__segment:not(.flower__segment__active) {
  opacity: 0.4;
  cursor: pointer;
}
$size-inner: 117px;
.progress__flower {
  width: 100%;
  position: relative;
  &__inner {
    position: absolute;
    top: calc(50% - #{$size-inner/2});
    left: calc(50% - #{$size-inner/2});
    flex-direction: column;
    width: $size-inner;
    height: $size-inner;
    background: $color-white;
    border: 12px solid $color-black;
    border-radius: 50%;
  }
}
</style>