<template>
  <div class="success-arow">
    <svg
      class="success-arow-icon"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40 0C17.9446 0 0 17.9431 0 40C0 62.0569 17.9446 80 40 80C62.0554 80 80 62.0569 80 40C80 17.9431 62.0554 0 40 0ZM40 76.9231C19.6415 76.9231 3.07692 60.3585 3.07692 40C3.07692 19.6415 19.6415 3.07692 40 3.07692C60.3585 3.07692 76.9231 19.6415 76.9231 40C76.9231 60.3585 60.3585 76.9231 40 76.9231Z"
        fill="#6EC700"
      />
      <path
        d="M58.8498 23.5938L35.2052 50.1938L20.9606 38.7984C20.2991 38.2677 19.3283 38.3769 18.7991 39.0384C18.2683 39.7031 18.376 40.6707 19.0391 41.2015L34.4237 53.5092C34.7068 53.7354 35.0468 53.8461 35.3852 53.8461C35.8098 53.8461 36.2329 53.6708 36.536 53.3292L61.1514 25.6369C61.716 25.0031 61.6591 24.0292 61.0237 23.4661C60.3868 22.9 59.416 22.9569 58.8498 23.5938Z"
        fill="#6EC700"
      />
    </svg>
  </div>
</template>
<script>
export default {
  name: "IconCurrentSubscription"
};
</script>

<style lang="scss">
.success-arow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($color-black, 0.5);
  &-icon {
    position: absolute;
    width: 80px;
    height: 80px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>