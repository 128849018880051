<template>
  <div class="range__multy mt__20">
    <label>{{params.label}}</label>
    <div
      class="range__multy__slider"
      :style="`background: linear-gradient(to right, #E8E8E8 0%, #E8E8E8 ${x1}%, rgb(0, 188, 235) ${(+x1 + 0.1)}%, rgb(0, 188, 235) ${x2}%, #E8E8E8 ${(+x2 + 0.1)}%, #E8E8E8 100%);`"
      ref="capture"
    >
      <div
        class="range__multy__slider__thumb"
        :style="`left: calc(${x1}% - 8px)`"
        @mousedown.stop="(e) => { if(!params.disable) startDrag(1) }"
      >
        <span class="range__multy__slider__thumb__point"></span>
        <span class="range__multy__slider__thumb__field">{{value1}}</span>
      </div>
      <div
        class="range__multy__slider__thumb"
        :style="`left: calc(${x2}% - 8px)`"
        @mousedown.stop="(e) => { if(!params.disable) startDrag(2) }"
      >
        <span class="range__multy__slider__thumb__point"></span>
        <span class="range__multy__slider__thumb__field">{{value2}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Net from "../../modules/net";

export default {
  data: function() {
    return {
      x1: 0,
      x2: 100,
      point: null,
      zero: 0
    };
  },
  props: {
    min: {
      default: function() {
        return 18;
      }
    },
    max: {
      default: function() {
        return 80;
      }
    },

    v1: {
      default: function() {
        return 18;
      }
    },

    v2: {
      default: function() {
        return 80;
      }
    },
    format: {
      default: function() {
        return value => {
          return value;
        };
      }
    },
    params: {
      default: function() {
        return {
          single: false,
          multy: false,
          both: false,
          label: "Label",
          disable: false,
          target: null,
          initialValue: null,
          change: null
        };
      }
    }
  },
  computed: {
    value1() {
      return Math.round(this.min + (this.max - this.min) * (this.x1 / 100));
    },
    value2() {
      return Math.round(this.min + (this.max - this.min) * (this.x2 / 100));
    }
  },
  methods: {
    startDrag(point) {
      this.point = point;
      window.addEventListener("mousemove", this.doDrag);
    },
    doDrag(event) {
      const captureRect = this.$refs.capture.getBoundingClientRect();
      this[this.point == 1 ? "x1" : "x2"] = Math.max(
        Math.min(
          (((event.x - captureRect.left) /
            (captureRect.right - captureRect.left)) *
            100 *
            100) /
            100,
          this.point == 2 ? 100 : this.x2 - 1.61
        ),
        this.point == 1 ? 0 : this.x1 + 1.61
      );
    },
    stopDrag() {
      this.$emit("change", [this.value1, this.value2]);
      window.removeEventListener("mousemove", this.doDrag);
    },
    absoluteValueToRelative(value) {
      return ((value - this.min) / (this.max - this.min)) * 100;
    }
  },
  mounted() {
    window.addEventListener("mouseup", this.stopDrag);
  },
  created() {
    this.x1 = ((this.v1 - this.min) / (this.max - this.min)) * 100;
    this.x2 = ((this.v2 - this.min) / (this.max - this.min)) * 100;

    if (this.params.target) {
      this.path = this.params.target.split(".");
      var element = this.$store.state;
      for (var p = 0; p < this.path.length; p++) {
        element = element[this.path[p]];
      }
      this.oldX = this.x = this.absoluteValueToRelative(element);
    } else if (this.params.initialValue) {
      this.oldX = this.x = this.absoluteValueToRelative(
        this.params.initialValue
      );
    }
  },
  destroyed() {
    window.removeEventListener("mouseup", this.stopDrag);
  }
};
</script>
 
<style lang="scss">
.range__multy {
  position: relative;
  label {
    display: block;
    font-size: 12px;
    font-weight: 400;
    color: $color-mid-grey;
  }
  &__slider {
    position: relative;
    width: calc(100% - 18px);
    height: 4px;
    background: $color-light-grey;
    border-radius: $border-radius;
    margin: 19px 7px 15px;
    &__thumb {
      position: absolute;
      top: -6px;
      &__point {
        display: block;
        width: 16px;
        height: 16px;
        margin: 0 auto;
        background: $color-teal-blue;
        box-shadow: 8px 8px 12px rgba(68, 68, 68, 0.16);
        transform: rotate(45deg);
        cursor: grab;
      }
      &__field {
        position: absolute;
        display: block;
        top: 20px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        color: $color-teal-blue;
      }
    }
  }
  .disable {
    .range__slider__thumb__point {
      opacity: 0;
      pointer-events: none;
    }
  }
}
</style>