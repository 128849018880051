<template>
  <div class="range mt__20">
    <!-- Single Range
    <div class="range__single" :class="{'disable': params.disable}" v-if="params.single">
      <label>{{params.label}}</label>
      <div
        ref="capture"
        class="range__slider"
        :style="`background: linear-gradient(to right, #0092B7 ${(xx)}%, #00BCEB ${(+xx + .1)}%, #00BCEB ${x}%, #E8E8E8 ${(+x + .1)}%, #E8E8E8 100%);`"
        @mousedown="(e) => { if(!params.disable) { startDrag(); doDrag(e); }}"
      >
        <div
          class="range__slider__thumb"
          @mousedown.stop="(e) => { if(!params.disable) startDrag() }"
          :style="`left: calc(${x}% - 8px)`"
        >
          <span class="range__slider__thumb__point"></span>
          <span class="range__slider__thumb__field">{{format(value) ^ 0}}</span>
        </div>
      </div>
    </div> -->
    <!-- Multy Range
    <div class="range__multy" v-if="params.multy">
      <label>Multy Range</label>
      <div
        class="range__slider"
        style="background: linear-gradient(to right, #E8E8E8 0%, #E8E8E8 19%, rgb(0, 188, 235) 20%, rgb(0, 188, 235) 70%, #E8E8E8 71%, #E8E8E8 100%);"
      >
        <div class="range__slider__thumb" style="left: calc(20% - 8px)">
          <span class="range__slider__thumb__point"></span>
          <span class="range__slider__thumb__field">20</span>
        </div>
        <div class="range__slider__thumb" style="left: calc(70% - 8px)">
          <span class="range__slider__thumb__point"></span>
          <span class="range__slider__thumb__field">70</span>
        </div>
      </div>
    </div> -->
    <!-- Both Range
    <div class="range__both" v-if="params.both">
      <label>Both Range</label>
      <div class="range__slider">
        <div class="range__slider__thumb" style="left: calc(50% - 8px)">
          <span class="range__slider__thumb__point"></span>
          <span class="range__slider__thumb__field">Both</span>
        </div>
      </div>

      <div class="flex__bc title__12 grey__210">
        <div class="title__12 grey__210">Male</div>
        <div class="title__12 grey__210">Female</div>
      </div>
    </div> -->
  </div>
</template>

<script>
import Net from "../../modules/net";

export default {
  data: function() {
    return {
      x: 0,
      oldX: 0
    };
  },
  props: {
    min: {
      default: function() {
        return 0;
      }
    },
    xx: {
      default(){
        return 0
      }
    },
    max: {
      default: function() {
        return 100;
      }
    },
    format: {
      default: function() {
        return value => {
          return value;
        };
      }
    },
    params: {
      default: function() {
        return {
          single: false,
          multy: false,
          both: false,
          label: "Label",
          disable: false,
          target: null,
          initialValue: null,
          change: null
        };
      }
    }
  },
  computed: {
    value() {
      return this.min + (this.max - this.min) * (this.x / 100);
    }
  },
  methods: {
    startDrag() {
      window.addEventListener("mousemove", this.doDrag);
    },
    doDrag(event) {
      const captureRect = this.$refs.capture.getBoundingClientRect();
      this.x = Math.max(Math.max(
        Math.min(
          (((event.x - captureRect.left) /
            (captureRect.right - captureRect.left)) *
            100 *
            100) /
            100,
          100
        ),
        0
      ), this.xx);
    },
    stopDrag() {
      if (this.params.change) {
        this.params.change(this.format ? this.format(this.value) : this.value);
      }
      window.removeEventListener("mousemove", this.doDrag);
      if (this.params.target && this.x != this.oldX) {
        Net.update(this.path, this.value ^ 0);
        this.oldX = this.x;
      }
    },
    absoluteValueToRelative(value) {
      return ((value - this.min) / (this.max - this.min)) * 100;
    }
  },
  mounted() {
    window.addEventListener("mouseup", this.stopDrag);
  },
  created() {
    if (this.params.target) {
      this.path = this.params.target.split(".");
      var element = this.$store.state;
      for (var p = 0; p < this.path.length; p++) {
        element = element[this.path[p]];
      }
      this.oldX = this.x = this.absoluteValueToRelative(element);
    } else if (this.params.initialValue) {
      this.oldX = this.x = this.absoluteValueToRelative(this.params.initialValue);
    }
  },
  destroyed() {
    window.removeEventListener("mouseup", this.stopDrag);
  }
};
</script>
 
<style lang="scss">
// .range {
//   position: relative;
//   label {
//     display: block;
//     font-size: 12px;
//     font-weight: 400;
//     color: $color-mid-grey;
//   }
//   &__slider {
//     position: relative;
//     width: 100%;
//     height: 4px;
//     background: $color-light-grey;
//     border-radius: $border-radius;
//     margin: 15px 0;
//     &__thumb {
//       position: absolute;
//       top: -6px;
//       &__point {
//         display: block;
//         width: 16px;
//         height: 16px;
//         margin: 0 auto;
//         background: $color-teal-blue;
//         box-shadow: 8px 8px 12px rgba(68, 68, 68, 0.16);
//         transform: rotate(45deg);
//         cursor: grab;
//       }
//       &__field {
//         position: absolute;
//         display: block;
//         top: 20px;
//         left: 50%;
//         transform: translateX(-50%);
//         font-size: 16px;
//         font-weight: 700;
//         text-align: center;
//         color: $color-teal-blue;
//       }
//     }
//   }
//   .disable {
//     .range__slider__thumb__point {
//       opacity: 0;
//       pointer-events: none;
//     }
//   }
// }
</style>