<template>
  <div class="scenario">
    <!-- screens top -->
    <div class="flex__sc tabs__overflow" style="display:-webkit-box;">
      <div
        :class="['screen', {'active':screensPage == index }]"
        @click="screensPage = index"
        v-for="(screen, index) in screens"
        :key="index"
      >
        <div class="screen__name">{{screen.Name}}</div>
        <div class="block__hover mt__15">
          <div class="image__cover">
            <img src="@/assets/img/new-project/web.jpg" />
          </div>
        </div>
      </div>
    </div>

    <!-- end screens top -->
    <slot v-for="(screen, index) in screens">
      <template v-if="index == screensPage">
        <!-- Screen info + Progress -->
        <div :key="index+'-info'" class="mt__50">
          <div class="flex__bc">
            <div>
              <span class="title__60">
                <b>{{screen.testers}}</b>/90
              </span>
              <span class="title__60 grey__151">(100%)</span>
              <span class="title__12">TESTERS</span>

              <div class="flex__bs mt__20">
                <div>
                  <div class="title__12 weight__500 grey__151">AVG. DURATION</div>
                  <div class="title__12">
                    <b>{{screen.duration}}</b>
                  </div>
                </div>
                <div>
                  <div class="title__12 weight__500 grey__151">AVG. CLICKS</div>
                  <div class="title__12">
                    <b>{{screen.clicks}}</b>
                  </div>
                </div>
                <div>
                  <div class="title__12 weight__500 grey__151">AVG. MISCLICKS</div>
                  <div class="title__12">
                    <b>{{screen.Avgmisclicks}}</b>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex__ec">
              <ProgressCircle
                class="mr__20"
                style="width: 130px;"
                :value="screen.walkthrough"
                :values="[{value: screen.walkthrough, color: 'pink'}]"
                description="facial expressions"
              />
              <ProgressCircle
                class="mr__20"
                style="width: 130px;"
                :value="screen.speed"
                :values="[{value: screen.speed, color: '#00BCEB'}]"
                description="visual attention"
              />
            </div>
          </div>
        </div>
        <!-- end Screen info + Progress -->

        <!-- down spot info -->
        <div class="col__3 mt__50" :key="index+'-num'">
          <!-- 1  -->
          <div style="width:220px;">
            <div class="title__16 weight__600 text__up text__right blue">visitors</div>
            <div class="title__14 grey__151 text__right mt__5">from such pages</div>
            <div class="flex__bs mt__15">
              <div>
                <div class="title__44 weight__600 lh__30 blue">
                  <b>11.2</b>
                  <span>%</span>
                </div>
                <div class="title__10 weight__500">12 testers</div>
              </div>
              <div class="text__right">
                <div class="title__10">
                  <span class="grey__151">from screen 1 -&nbsp;</span>
                  <b>76%</b>
                </div>
                <div class="title__10 mt__10">
                  <span class="grey__151">from screen 2 -&nbsp;</span>
                  <b>24%</b>
                </div>
              </div>
            </div>
          </div>
          <!-- 2  -->
          <div style="width:280px;">
            <div class="title__16 weight__600 text__up text__center red">drop-off</div>
            <div
              class="title__14 grey__151 text__center mt__5"
            >Testers who left or gave up the mission</div>
            <div class="flex__bs mt__15">
              <div>
                <div class="title__44 weight__600 lh__30">
                  <b>8.2</b>
                  <span>%</span>
                </div>
                <div class="title__10 weight__500">8 testers</div>
              </div>
              <div>
                <div class="title__44 weight__600 lh__30">
                  <b>3.5</b>
                  <span>s</span>
                </div>
                <div class="title__10 weight__500">after</div>
              </div>
            </div>
          </div>
          <!-- 3 -->
          <div style="width:220px;">
            <div class="title__16 weight__600 text__up green">walktrough</div>
            <div class="title__14 grey__151 mt__5">to such pages</div>
            <div class="flex__bs mt__15">
              <div>
                <div class="title__44 weight__600 lh__30 green">
                  <b>91.2</b>
                  <span>%</span>
                </div>
                <div class="title__10 weight__500">92 testers</div>
              </div>
              <div>
                <div :class="['title__10', {'mt__10' : index !== 0}]" v-for="(n, index) of 5" :key="index">
                  <span class="grey__151">On screen {{index + 1}} -&nbsp;</span>
                  <b>92%</b>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- end down spot info -->

        <div :key="index+'-tab'" class="tab mt__50">
          <!-- Tab nav  -->
          <div class="flex__sstr tab__nav4" v-if="screen">
            <!-- :class="['tab__nav blue', {tab__nav__active: selectedTab == 'Visual', inactive: report.All.Count.All == 0}]" -->
            <div
              :class="['tab__nav blue', {tab__nav__active: selectedTab == 'Visual'}]"
              @click="selectedTab = 'Visual'"
            >
              <div class="title__16 weight__700 grey__121b">Visual Attention</div>
              <div
                class="title__12 weight__500 grey__151 mt__5"
              >Heatmap and blind spot of testers views</div>
            </div>
            <div
              :class="['tab__nav red', {tab__nav__active: selectedTab == 'Facial'}]"
              @click="selectedTab = 'Facial'"
            >
              <div class="title__16 weight__700 grey__121b">Facial expressions</div>
              <div
                class="title__12 weight__500 grey__151 mt__5"
              >The emotions that testers showed on this screen</div>
            </div>
            <div
              :class="['tab__nav orange', {tab__nav__active: selectedTab == 'Interactions'}]"
              @click="selectedTab = 'Interactions'"
            >
              <div class="title__16 weight__700 grey__121b">Interactions</div>
              <div class="title__12 weight__500 grey__151 mt__5">Taps and clicks of testers</div>
            </div>
            <div
              :class="['tab__nav green', {tab__nav__active: selectedTab == 'Areas'}]"
              @click="selectedTab = 'Areas'"
            >
              <div class="title__16 weight__700 grey__121b">Areas of Interest</div>
              <div
                class="title__12 weight__500 grey__151 mt__5"
              >Set-up important areas of interest for this screen</div>
            </div>
          </div>
          <!-- end Tab nav  -->
          <!-- Tab content  -->
          <div class="tab__content">
            <div class="title__14 weight__700 mt__40 text__center">VISUALIZATION TYPE</div>
            <div class="switch center mt__20">
              <div :class="{active: switchMap}" @click="switchMap = true">Heatmap</div>
              <div :class="{active: !switchMap}" @click="switchMap = false">Blind Spots</div>
            </div>
          </div>
          <!-- end Tab content  -->
        </div>
      </template>
    </slot>
  </div>
</template>

<script>
export default {
  props: [],
  data() {
    return {
      screensPage: 0,
      selectedTab: "Visual",
      switchMap: true,
      screens
    };
  }
};

const screens = [
  {
    Name: "Screen 1",
    img: "../assets/img/new-project/web.jpg",
    testers: 90,
    duration: "2.6s",
    clicks: 10,
    Avgmisclicks: 5,
    walkthrough: 90,
    speed: 68,
    misclicks: 52
  },
  {
    Name: "Screen 2",
    img: "@/assets/img/new-project/web.jpg",
    testers: 80,
    duration: "3.6s",
    clicks: 4,
    Avgmisclicks: 5,
    walkthrough: 90,
    speed: 24,
    misclicks: 2
  },
  {
    Name: "Screen 3",
    img: "@/assets/img/new-project/web.jpg",
    testers: 35,
    duration: "0.6s",
    clicks: 1,
    Avgmisclicks: 5,
    walkthrough: 90,
    speed: 68,
    misclicks: 52
  },
  {
    Name: "Screen 4",
    img: "@/assets/img/new-project/web.jpg",
    testers: 45,
    duration: "2.1s",
    clicks: 10,
    Avgmisclicks: 5,
    walkthrough: 90,
    speed: 68,
    misclicks: 52
  },
  {
    Name: "Screen 5",
    img: "@/assets/img/new-project/web.jpg",
    testers: 90,
    duration: "2.6s",
    clicks: 10,
    Avgmisclicks: 5,
    walkthrough: 90,
    speed: 68,
    misclicks: 52
  },
  {
    Name: "Screen 6",
    img: "@/assets/img/new-project/web.jpg",
    testers: 90,
    duration: "2.6s",
    clicks: 10,
    Avgmisclicks: 5,
    walkthrough: 90,
    speed: 68,
    misclicks: 52
  }
];
</script>

<style lang="scss" scoped>
.screen {
  width: 200px;
  .block__hover {
    padding-bottom: 0;
    .flex__bc {
      width: calc(100% + 24px);
      margin-left: -12px;
    }
  }
  &__name {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    @include transitionAll;
  }
  &:not(:first-child) {
    position: relative;
    margin-left: 32px;
    // &::before {
    //   content: "⟶";
    //   font-size: 28px;
    //   color: $color-mid-grey;
    //   position: absolute;
    //   left: -32px;
    //   top: calc(50% - 16px);
    // }
  }
  &.active &__name {
    color: $color-teal-blue;
  }
}
</style>