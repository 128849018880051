<template>
  <div>
    <!-- PAYMENT HISTORY -->
    <div class="block flex__bc mt__20">
      <div class="flex__sc" v-if="card">
        <CardPlastic class="card__mini mr__20" />
        <div>
          <div
            class="title__24 weight__700 text__up"
          >{{card[0] ? card[0].cardtype + ' •••• ' + card[0].cardinfo : 'NO CARD'}}</div>
          <div class="title__14 weight__500 grey__151 lh__20 mt__20">
            Status:
            <b>{{card[0] ? ' Active' : ' Not active'}}</b>
          </div>
        </div>
      </div>
      <router-link class="btn__blue btn__200" to="/payment-methods">CHANGE</router-link>
    </div>
    <div class="flex__bc mob__rev">
      <h1 class="title__line__green" :class="mobile ? 'mt__20' : 'mt__40'">payment history</h1>
      <!-- <router-link
        to="/payment-methods"
        class="btn__green__full btn__200"
        :class="mobile ? 'mt__40' : 'mt__10'"
      >Payment Methods</router-link>-->
    </div>
    <div class="block block__minh" v-if="payments.length">
      <table class="table__bordered">
        <thead>
          <tr>
            <td>Title</td>
            <td v-if="!mobile">Transaction ID</td>
            <td v-if="!mobile">Transaction Date</td>
            <td v-if="!mobile">Type</td>
            <td>Amount</td>
            <td>Status</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(transaction, index) in transactions" :key="index">
            <td>
              <div>{{transaction.title}}</div>
              <div class="title__12 grey__210 mt__5">Added from credit card •••• {{transaction.card}}</div>
            </td>
            <td v-if="!mobile">{{transaction.transactionId}}</td>
            <td v-if="!mobile">
              <div>{{transaction.date}}</div>
              <div class="title__12 grey__210 mt__5">{{transaction.time}}</div>
            </td>
            <td v-if="!mobile">{{transaction.type}}</td>
            <td>{{transaction.amount}} $</td>
            <td
              :class="{'red': transaction.status == 'Failed', 'grey': transaction.status == 'Pending'}"
            >{{transaction.status}}</td>
          </tr>
        </tbody>
      </table>
      <div v-if="payments.length > 10" class="tfoot pagination flex__sc">
        <div class="mr__30 flex__sc">
          Rows per page
          <select v-model="pageSize" class="ml__6">
            <option value="10" selected>10</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
          <img src="@/assets/img/icons/arr-triangle.svg" alt="icon" />
        </div>
        <div class="pagination__of mr__30">
          <b>{{(currentPage - 1) * pageSize + 1}}-{{Math.min(currentPage * pageSize, payments.length)}}</b>
          of {{payments.length}}
        </div>
        <div class="pagination__arrows flex__sc">
          <button
            class="pagination__arrows__back flex__sc mr__30"
            @click="currentPage = 1"
            :disabled="currentPage < 2"
          >
            <IconArrowRight />|
          </button>
          <button
            class="pagination__arrows__back flex__sc mr__30"
            @click="prevPage"
            :disabled="currentPage < 2"
          >
            <IconArrowRight />
          </button>
          <button
            class="flex__sc mr__30"
            @click="nextPage"
            :disabled="currentPage * pageSize > payments.length"
          >
            <IconArrowRight />
          </button>
          <button
            class="flex__sc"
            @click="currentPage = ((payments.length / pageSize) ^ 0) + 1"
            :disabled="currentPage * pageSize > payments.length"
          >
            <IconArrowRight />|
          </button>
        </div>
      </div>
    </div>
    <div class="block block__minh flex__cc" v-else>
      <p>A list of your transactions will be displayed here.</p>
    </div>
    <!--end PAYMENT HISTORY -->
  </div>
</template>

<script>
import Net from "../../modules/net";

export default {
  data() {
    return {
      mobile: this.ismobile,
      payments: [],
      currentPage: 1,
      pageSize: 10
    };
  },
  watch: {
    pageSize() {
      this.currentPage = 1;
    }
  },
  created() {
    Net.post("/GetTransactionsCommand.cmd", {
      take: 100000000,
      skip: 0
    }).then(e => {
      this.payments = e.data;
    });
  },
  computed: {
    card() {
      return (
        this.$store.state.userInfo && this.$store.state.userInfo.Account.Cards
      );
    },
    transactions() {
      return this.payments.filter((row, index) => {
        let start = (this.currentPage - 1) * this.pageSize;
        let end = this.currentPage * this.pageSize;
        if (index >= start && index < end) return true;
      });
    }
  },
  methods: {
    nextPage: function() {
      if (this.currentPage * this.pageSize < this.payments.length) {
        this.currentPage++;
      }
    },
    prevPage: function() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    }
  }
};
</script>
