<template>
  <el-dropdown
    placement="bottom"
    trigger="click"
    @command="toggleFontSize"
  >
    test

    <el-dropdown-menu
      slot="dropdown"
      class="el-tiptap-dropdown-menu"
    >
      <!-- default size -->
      <el-dropdown-item
        :command="defaultSize"
        :class="{
          'el-tiptap-dropdown-menu__item--active': activeFontSize === defaultSize,
        }"
        class="el-tiptap-dropdown-menu__item"
      >
        <span data-font-size="default">test</span>
      </el-dropdown-item>

      <el-dropdown-item
        v-for="fontSize in fontSizes"
        :key="fontSize"
        :command="fontSize"
        :class="{
          'el-tiptap-dropdown-menu__item--active': fontSize === activeFontSize,
        }"
        class="el-tiptap-dropdown-menu__item"
      >
        <span :data-font-size="fontSize">{{ fontSize }}</span>
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  name: "BoldMenuButton",

  props: ["editorContext"],

  computed: {
    defaultSize() {
      return 'default'
    },
    activeFontSize() {
      return 'default'
    },
    editor() {
      return this.editorContext.editor;
    },
    fontSizes () {
      return this.editor.extensions.options.font_size.fontSizes;
    }
  },

  methods: {
    onClick(e) {
      this.$emit("click");
    },
    toggleFontSize (size) {
      if (size === this.activeFontSize) {
        this.editorContext.commands.font_size(DEFAULT_FONT_SIZE);
      } else {
        this.editorContext.commands.font_size(size);
      }
    }
  }
};
</script>
