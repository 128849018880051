<template>
  <div class="project__progress">
    <div class="project__progress__line">
      <div class="project__progress__line__value" :class="{green : finished == 2}" :style="`width: ${finished == 2 ? 100 : (collectorType == 24 ? 0 : progressPercent)}%`"></div>
    </div>
    <div class="flex__bc">
      <div class="project__progress__text">{{finished == 2 ? 'finished' : 'progress'}}</div>
      <div class="project__progress__count">{{collectorType == 24 ? progressOf : progress + " of " + progressOf}}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["progress", "progressOf", "progressPercent", "collectorType", "finished"]
};
</script>
<style lang="scss">
.project__progress {
  font-size: 10px;
  font-weight: 500;
  color: $color-mid-grey;
  &__line {
    position: relative;
    height: 10px;
    border-radius: $border-radius-sm;
    background: $color-light-grey;
    &__value {
      position: absolute;
      top: 0;
      left: 0;
      max-width: 100%;
      height: 100%;
      border-radius: $border-radius-sm;
      background: $color-teal-blue;
      &.green {
        background: $color-green;
      }
    }
  }
  &__text {
    margin-top: 2px;
  }
  &__count {
    text-align: right;
    margin-top: 2px;
  }
}
</style>