<template>
  <div :class="['progress__bloks flex__bc', 'progress__' + params.state]">
    <div v-for="(n, index) of blocks" :key="index"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      blocks: 4
    };
  },
  props: {
    params: {
      default: function() {
        return {
          state: 'none' // low / normal / high
        };
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.progress__bloks {
  width: 100px;
  > div {
    width: calc(25% - 4px);
    height: 10px;
    background: $color-light-grey; 
  }
  &.progress__low {
    div:nth-child(1) {
      background: $color-red;
    }
  }
  &.progress__normal {
    div:nth-child(1),
    div:nth-child(2),
    div:nth-child(3) {
      background: $color-orange;
    }
  }
  &.progress__high {
    div {
      background: $color-green;
    }
  }
}
</style>