import { render, staticRenderFns } from "./IconArrowRight.vue?vue&type=template&id=7035a0a0&scoped=true"
var script = {}
import style0 from "./IconArrowRight.vue?vue&type=style&index=0&id=7035a0a0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7035a0a0",
  null
  
)

export default component.exports