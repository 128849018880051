<template>
  <div class="popup__delete">
    <div class="popup__title">Are you sure?</div>
    <div
      class="popup__description"
    >Your subscription will be changed to <b>PAY AS YOU GO</b> when ongoing plan expires</div>
    <div class="popup__delete__confirm input__row"> 
      <input
        type="text"
        v-model="text"
        autofocus="true"
        class="focused"
        autocomplete="off"
      />
      <label>
        Type
        <span>CHANGE</span> to confirm
      </label>
    </div>
    <div class="popup__btns">
      <div class="btn__black" :disabled="text.trim() != 'CHANGE'" @click="CancelSubscription">Confirm</div>
      <div class="btn__grey" @click="close">Cancel</div>
    </div>
  </div>
</template>

<script>
import FD from "../../modules/FData"; 
import Net from "../../modules/net";

export default {
  data() {
    return {
      text: "" 
    };
  },
  props: ["close"],
  methods: {
    CancelSubscription() {
      Net.post("/CancelSubscriptionCommand.cmd", FD({}))
        .then(() => {
          this.close(); 
          this.$store.dispatch("loadUserData");
        })
        .catch(() => {
          debugger;
        });
    }
  }
};
</script>